import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Category.module.css';

function Editcategory() {

  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    image: null,
    is_active: '',
  });

  const handleInputChange = (e) => {
    if (e.target.name === 'image') {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        image: file || prevData.image, // Set to null only if no new image is selected
        imagePath: file ? URL.createObjectURL(file) : '', // If file is present, use its URL
      }));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/categoryedit/${categoryId}`);
        if (response.data.ResponseCode === 1) {
          const { category_name, order, image, is_active } = response.data.ResponseData;
          setFormData({
            category_name: category_name || '',
            order: order || '',
            is_active: is_active || '',
            image: image || null,
          });
        } else {
          // toast.error(response.data.msg);
        }
        console.log('Category Data Fetched Successfully:', response.data);
      } catch (error) {
        console.error('Error Fetching Data: ', error);
        navigate('/viewcategory');
      }
    };
    fetchData();
  }, [categoryId, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('category_name', formData.category_name);
    formDataToSend.append('image', formData.image);
    formDataToSend.append('is_active', formData.is_active);
    axios
      .post(
        `${process.env.REACT_APP_API}/category-update/${categoryId}`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        toast.success('Category Updated Successfully');
        navigate('/viewcategory');
      })
      .catch((error) => {
        console.error('Error updating Category:', error);
      });

  };


  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Category Form</h2>
                <ul className="breadcrumb">

                </ul>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewcategory">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Vertical Layout */}
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">

                  <div className="body  py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">Category Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" name='category_name' id="category_name" className="form-control" value={formData.category_name} placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="password" className="text-nowrap ">Image :-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="file" name="image" id="image" accept="image/*" className="form-control" placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} />
                              {formData.image &&
                                <img src={formData.image} alt="Selected Image" style={{ maxWidth: '120px', marginTop: '10px' }} />
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active" className="text-nowrap ">Status :-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" value={formData.is_active} required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Editcategory