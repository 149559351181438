import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Subcategory.module.css';

function Editsubcategory() {

  const { subcategoryId } = useParams();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    image: null,
    is_active: "",
    category_id: "",
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/categorylist`);
        setCategoryData(response.data.ResponseData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();

    axios.get(`${process.env.REACT_APP_API}/subcategoryedit/${subcategoryId}`)
      .then((response) => {
        if (response.data.ResponseCode == 1) {
          const { subcategory_name, category_id, is_active, image } = response.data.ResponseData;
          setFormData({
            subcategory_name: subcategory_name || '',
            category_id: category_id || '',
            is_active: is_active || '',
            image: image || null
          });
        } else {
          toast.error(response.data.ResponseText);
        }
      })
      .catch((error) => {
        console.error('Error Fetching Data: ', error);
      });
  }, [subcategoryId]);

  const handleInputChange = (e) => {
    if (e.target.name === 'image') {
      setFormData({ ...formData, image: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataForSubmit = new FormData();
    formDataForSubmit.append("subcategory_name", formData.subcategory_name);
    formDataForSubmit.append("category_id", formData.category_id);
    formDataForSubmit.append('is_active', formData.is_active);
    formDataForSubmit.append("image", formData.image);
    axios
      .post(
        `${process.env.REACT_APP_API}/subcategory-update/${subcategoryId}`,
        formDataForSubmit,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        toast.success('Subcategory Updated Successfully');
        navigate('/viewsubcategory');
      })
      .catch((error) => {
        console.error('Error updating Subcategory:', error);
      });
  }


  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Sub-Category Form</h2>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewsubcategory">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Vertical Layout */}
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="body py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">Subcategory Name:- </label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" name='subcategory_name' id="subcategory_name" className="form-control" value={formData.subcategory_name} placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="password" className="text-nowrap ">Image:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="file" name='image' className="form-control" id="image" accept="image/*" placeholder='Home-Banner Image' onChange={(e) => handleInputChange(e)} />
                              {formData.image &&
                                <img src={formData.image} alt="Selected Image" style={{ maxWidth: '120px', marginTop: '10px' }} />
                              }
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address">Category Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select name="category_id" id="templateId" required value={formData.category_id} class="form-control" onChange={(e) => handleInputChange(e)} >
                                <option value="" >
                                  --select category--
                                </option>
                                {categoryData &&
                                  categoryData.map((v) => {
                                    return (
                                      <option value={v.id} key={v.id}>
                                        {v.category_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active">Status:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" value={formData.is_active} onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Editsubcategory