import React, { useState } from 'react'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";

function AddHomeBanner() {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    bannder_link: "",
    image: null,
    from_date: "",
    to_date: "",
    is_active: ""
  })

  const handleInputChange = (e) => {
    if (e.target.name === 'image') {
      const imageFile = e.target.files[0];
      setFormData({ ...formData, image: imageFile || null });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API}/add-homebanner`;
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("image", formData.image);
      formDataToSend.append("bannder_link", formData.bannder_link);
      formDataToSend.append("from_date", formData.from_date);
      formDataToSend.append("to_date", formData.to_date);
      formDataToSend.append("is_active", formData.is_active);
      const response = await axios.post(apiUrl, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.data.ResponseCode) {
        toast.success("Homebanner added successfully");
        navigate("/viewhomebanner");
      }
    } catch (error) {
      console.error("Error adding Homebanner", error);

    }

  }

  return (
    <>

      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Home Banner Form</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/viewbanner"><i className="zmdi zmdi-home" /> Add Banner</a></li>
                </ul>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewbanner">
                  <button className="btn btn-primary btn-icon float-right right_icon_toggle_btn" type="button"><i className="zmdi zmdi-arrow-right" /></button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Vertical Layout */}
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="header">
                    <h2><strong>Add</strong> New Home Banner</h2>

                  </div>
                  <div className="body w-50">
                    <form method='post' onSubmit={(e) => handleSubmit(e)}>
                      <label htmlFor="email_address">Banner Name</label>
                      <div className="form-group">
                        <input type="text" name='name' id="name" className="form-control " placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                      </div>
                      <label htmlFor="password">Banner Image</label>
                      <div className="form-group">
                        <input type="file" name="image" id="image" className="form-control " placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} required />
                      </div>
                      <label htmlFor="email_address">Slider URL</label>
                      <div className="form-group">
                        <input type="text" name='bannder_link' id="url" className="form-control " placeholder="Enter your Product URL " onChange={(e) => handleInputChange(e)} required />
                      </div>
                      <label htmlFor="email_address">From Date</label>
                      <div className="form-group">
                        <input type="date" name='from_date' id="from_date" className="form-control " onChange={(e) => handleInputChange(e)} required />
                      </div>
                      <label htmlFor="email_address">To Date</label>
                      <div className="form-group">
                        <input type="date" name='to_date' id="to_date" className="form-control " onChange={(e) => handleInputChange(e)} required />
                      </div>
                      <div class="form-group">
                        <label htmlFor="password">Status</label>
                        <select class="form-control show-tick ms select2" name="is_active" onChange={handleInputChange} data-placeholder="Select" required>
                          <option>-- Select Status --</option>
                          <option value={1}>
                            Active
                          </option>
                          <option value={2}>
                            Deactive
                          </option>
                        </select>
                      </div>


                      <button type="submit" className="btn btn-raised btn-primary btn-round waves-effect">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ToastContainer /> */}
    </>
  )
}

export default AddHomeBanner