import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';

function Viewcontact() {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/contact-list`);
            // setBanners(response.data.data);
            const ResponseData = response.data.ResponseData.map(item => {
                return {
                    ...item,
                    status: parseInt(item.status)
                }
            })
            setBanners(ResponseData);
        } catch (error) {
            console.error("Error fetching banners: ", error);
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    }
    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Contact List</h2>
                                <ul className="breadcrumb">
                                </ul>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                               
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Basic Examples */}
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">NO</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope='col'>Description</th>
                                                        <th scope="col">Date</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {banners.map((banners, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{banners.name}</td>
                                                            <td>{banners.email_id}</td>
                                                            <td>{banners.contact_number}</td>
                                                            <td>{banners.description}</td>
                                                           <td>{formatDate(banners.created_at)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Viewcontact