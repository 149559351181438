import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, ToastContainer, toast } from "react-toastify";
import axios from 'axios';

function ViewPrivacyPolicy() {

  const [editorData, setEditorData] = useState('');
  const [privacypolicy, setPrivacypolicy] = useState({ description: '' });

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/privacypolicy`);
      setPrivacypolicy(response.data.ResponseData[0]);
      setEditorData(response.data.ResponseData[0].description);
    } catch (error) {
      console.error("Error fetching Privacy Policy:", error);
    }
  };

  const handleCKEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleUpdatePrivacyPolicy = (id) => {
    axios
      .post(`${process.env.REACT_APP_API}/privacypolicyupdate/${id}`, {
        description: editorData,
      })
      .then((response) => {
        // alert('Privacy Policy updated successfully');
        toast.success('Privacy Policy updated successfully');
      })
      .catch((error) => {
        console.error('Error updating Privacy Policy:', error);
      });
  };

  return (
    <>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Privacy-Policy</h2>
                
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>

            </div>
          </div>
          <div>
            <div className='col-sm-8 me-5 mt-4'>
              <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleCKEditorChange}
              />
            </div>
            <div className='m-3'>
              <button
                type="button" className="btn btn-primary ps-4 pe-4" style={{ backgroundColor: "#014c8d", borderColor: "#014c8d", }}
                onClick={() => handleUpdatePrivacyPolicy(privacypolicy.id)}
              >
                Update Privacy Policy
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  )
}

export default ViewPrivacyPolicy