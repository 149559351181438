import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from '../Addsubcat/subcat.module.css';

function Editsubcat() {
    const { subcatfeedid } = useParams();
    const navigate = useNavigate();
    const [homefeed, setHomeFeed] = useState({
        fieldname: "",
        orderby: "",
        is_active: "",
        feed_type: "",
        location: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setHomeFeed({
            ...homefeed,
            [name]: value,
        });
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/subcatfeededit/${subcatfeedid}`)
            .then(response => {
                const data = response.data.ResponseData;
                setHomeFeed({
                    fieldname: data.fieldname,
                    orderby: data.orderby,
                    is_active: data.is_active,
                    feed_type: data.feed_type,
                    location: data.location,
                });
                
            })
            .catch((error) => {
                console.error("Error fetching homefeed: ", error);
            });


    }, [subcatfeedid]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/subcatfeed-update/${subcatfeedid}`;
        try {
            const response = await axios.post(apiUrl, homefeed);
            if (response.data.ResponseCode === 1) {
                toast.success("Homefeed Updated successfully");
                navigate("/subcatfeedlist");
            }
        } catch (error) {
            console.error("Error updating homefeed", error);
        }
    };

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Update Subcategory Feed</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/subcatfeedlist">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center align-items-center">
                                <div className="card">
                                    <div className="body py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap">Feed name:</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Add your feed Name" id="Name" value={homefeed.fieldname} name="fieldname" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap">Feed type:</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Add your feed type" id="Name" value={homefeed.feed_type} name="feed_type" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address">Order by:</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Add your order" id="Name" value={homefeed.orderby} name="orderby" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap">Location:</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select name="location" id="location" required value={homefeed.location} className="form-control" onChange={(e) => handleInputChange(e)}>
                                                                <option value="">-- Select location --</option>
                                                                <option value="1">Home</option>
                                                                <option value="2">Sub category</option>
                                                                <option value="3">Offer Zone</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="is_active">Status:</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select className="form-control show-tick ms select2" id="is_active" required name="is_active" value={homefeed.is_active} onChange={(e) => handleInputChange(e)} data-placeholder="Select">
                                                                <option value="">-- Select Status --</option>
                                                                <option value="1">Active</option>
                                                                <option value="2">Deactive</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Editsubcat