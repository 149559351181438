import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './membership.module.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Addmembership() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Membership');
    const [key, setKey] = useState('Membership');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);

    const [homecategory, setHomecategory] = useState({
        membership_name: "",
        membership_amount: "",
        discount_amoumt: "",
        gst: "",
        is_active: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setHomecategory({
            ...homecategory,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/add-membership`;
        try {
            const response = await axios.post(apiUrl, homecategory);
            if (response.data.ResponseCode) {
                toast.success("membership added successfully");
                localStorage.setItem("membershipId", response.data.ResponseData.id);
                setActiveTab('MembershipDetail');
            }
        } catch (error) {
            console.error("Error adding membership", error);
        }
    };


    //membership detail
    const [Membershipdetail, setMembershipdetail] = useState([]);

    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/membershipdetsdetails-list/${localStorage.getItem("membershipId")}`);
            setMembershipdetail(response.data.ResponseData);
        }
        catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    useEffect(() => {
        fetchdata();
    }, [])

    const handlesubDelete = (id) => {
        axios
            .post(`${process.env.REACT_APP_API}/membershipdetsdetails-delete/${id}`)
            .then((response) => {
                toast.success('Subimage deleted successfully');
                setMembershipdetail(Membershipdetail.filter(item => item.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting Subimage: ', error);
                toast.error('Error deleting Subimage');
            });
    };
    const [description, setDescription] = useState("");
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleUpload = async () => {
        const apiUrl = `${process.env.REACT_APP_API}/add-membershipdetsdetails`;
        try {
            const response = await axios.post(apiUrl, {
                membership_id: localStorage.getItem("membershipId"),
                description: description
            });
            if (response.data.ResponseCode) {
                toast.success("Description uploaded successfully");
                fetchdata();
                setDescription(""); 
            }
        } catch (error) {
            console.error("Error uploading description", error);
        }
    };

    const handleModalClose = () => {
        setDescription(""); 
    };

    const handleSaveAndNavigate = () => {
        localStorage.removeItem('membershipId');
        window.location.href = "/membershiplist";
    };


    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Add Membership</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/membershiplist">
                                    <button className="btn btn-primary float-right" type="button" onClick={handleSaveAndNavigate}>Save</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Tabs activeKey={activeTab} onSelect={(k) => setKey(k)} id="uncontrolled-tab-example">
                        <Tab eventKey="Membership" title="Membership" onSelect={() => setGeneralTabFilled(false)}>
                            <div className="container-fluid">
                                <div className="row clearfix ">
                                    <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center">
                                        <div className="card">
                                            <div className="header">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-6 col-sm-12">
                                                        <h2>Add New Membership </h2>
                                                        <button className="btn btn-primary btn-icon mobile_menu" type="button"><i
                                                            className="zmdi zmdi-sort-amount-desc" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body d-flex justify-content-center w-xl-75 w-lg-100 w-xxl-75 mt-4">
                                                <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>


                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="email_address">Membership Name:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" placeholder="add your Membership name" id="Name"
                                                                    name="membership_name" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="email_address" className="text-nowrap "> Amount:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" placeholder="add your membership amount" id="Name"
                                                                    name="membership_amount" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="email_address" className="text-nowrap "> Discount:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" placeholder="add your discount amoumt" id="Name"
                                                                    name="discount_amoumt" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="email_address" className="text-nowrap "> GST:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" placeholder="add your gst" id="Name" name="gst"
                                                                    onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="is_active">Status</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <select className="form-control show-tick ms select2" id="is_active" required name="is_active"
                                                                    onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                    <option value="">-- Select Status --</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <button type="submit" className="btn btn-raised btn-primary  waves-effect">Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="MembershipDetail" title="MembershipDetail">
                            <div className="container-fluid">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="header">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-6 col-sm-12">
                                                        <h2>Membership Detail</h2>
                                                        <button className="btn btn-primary btn-icon mobile_menu" type="button"><i
                                                            className="zmdi zmdi-sort-amount-desc" /></button>
                                                    </div>
                                                    <div className="col-lg-5 col-md-6 col-sm-12">
                                                        <button className="btn btn-primary float-right " data-toggle="modal"
                                                            data-target="#EmploymentEditModal" type="button">Add Membership Detail</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover  dataTable">
                                                        <thead>
                                                            <tr>
                                                                <th>No</th>
                                                                <th>Description</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {Membershipdetail.map((Membershipdetail, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        {Membershipdetail.description}
                                                                    </td>

                                                                    <td>
                                                                        <button className="btn btn-danger ms-2" onClick={() => handlesubDelete(Membershipdetail.id)}>
                                                                            <i class="bi bi-trash-fill me-1"></i>
                                                                            Delete
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade " id="EmploymentEditModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                                aria-hidden="true" onClose={handleModalClose}> {/* Add onClose event */}
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title " id="EmploymentModal">Add Membership Details</h5>
                                        </div>
                                        <div class="modal-body ">
                                            <form className='d-flex flex-column gap-3'>

                                                <label htmlFor="email_address" className="text-nowrap "> Description:-</label>

                                                <div className="form-group">
                                                    <textarea name="description" className="form-control" onChange={(e) => handleDescriptionChange(e)} value={description}></textarea> {/* Added value attribute */}
                                                </div>

                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="submit" class="btn btn-primary" data-dismiss="modal" style={{
                                                borderRadius: "30px"
                                            }} onClick={(e) => handleUpload(e)}>Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>
        </>
    )
}

export default Addmembership;
