import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';


function ViewHomeBanner() {

  const [banners, setBanners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);


  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/homeaddbanner-list`);
      const ResponseData = response.data.ResponseData.map(item => {
        return {
          ...item,
          status: parseInt(item.status)
        }
      })
      setBanners(ResponseData);
    } catch (error) {
      console.error("Error fetching Home-Banner: ", error);
    }
  };

  const handleDelete = (id) => {
    axios
      .post(`${process.env.REACT_APP_API}/homebanner-delete/${id}`)
      .then((response) => {
        toast.success('Home-Banner deleted');
        setBanners((prevData) => prevData.filter((data) => data.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting Home-Banner: ", error);
        toast.error("Error deleting Home-Banner");
      })
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBanners = banners.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
  }



  return (
    <>

      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Home-Banner</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/dashboard"><i className="zmdi zmdi-home" /> Dashboard</a></li>
                </ul>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/addhomebanner">
                  <button className="btn btn-primary float-right " type="button">Add</button>
                </Link>
              </div>

            </div>
          </div>
          <div className="container-fluid">
            {/* Basic Examples */}
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="header">
                    <h2><strong>Banner</strong> List </h2>
                  </div>

                  <div className="body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <th scope="col">NO</th>
                            <th scope="col">Title</th>
                            <th scope="col">Image</th>
                            <th scope="col">FromDate</th>
                            <th scope="col">ToDate</th>
                            <th scope="col">status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentBanners.map((banner, index) => (
                            <tr key={index}>

                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td>{banner.name}</td>
                              <td>
                                <img src={banner.image} alt="Banner" style={{ width: "200px", height: "120px" }} />
                              </td>
                              <td>{formatDate(banner.from_date)}</td>
                              <td>{formatDate(banner.to_date)}</td>

                              <td>
                                {banner.is_active === 1 ? (
                                  <span className='text-success fw-bolder'>Active</span>
                                ) : (
                                  <span className='text-danger fw-bolder'>Deactive</span>
                                )}
                              </td>
                              <td>
                                {/* <button className="btn btn-primary">Edit</button> */}
                                <Link
                                  to={`/edithomebanner/${banner.id}`}
                                  className="btn btn-primary m-2"
                                >
                                  <i class="bi bi-pencil-square me-1"></i>
                                  Edit
                                </Link>
                                <button className="btn btn-danger ms-2 " onClick={() => handleDelete(banner.id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav aria-label="Page navigation example ">
          <ul className="pagination justify-content-end">
            {Array.from({ length: Math.ceil(banners.length / itemsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
      <ToastContainer />
    </>
  )
}

export default ViewHomeBanner