import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = () => {
    if (username === 'demo' && password === '123') {
      toast.success('Login Successful');
      setAuthenticated(true);
      navigate('/dashboard');
    } else {
      alert('Invalid username or password');
    }
  };

  if (authenticated) {
    navigate('/dashboard');
    return null; 
  }

  return (
    <>
      <div className="authentication">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <form className="card auth_form">
                <div className="header">
                  <img className="logo" src="assets/images/logo.svg" alt />
                  <h5>Log in</h5>
                </div>
                <div className="body">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="zmdi zmdi-account-circle" />
                      </span>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="zmdi zmdi-lock" />
                      </span>
                    </div>
                  </div>
                  <div className="checkbox">
                    <input id="remember_me" type="checkbox" />
                    <label htmlFor="remember_me">Remember Me</label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-block waves-effect waves-light"
                    onClick={handleSignIn}
                  >
                    SIGN IN
                  </button>
                </div>
              </form>
              <div className="copyright text-center">
                ©,
                <span>
                  <a >Templates Point</a>
                </span>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <img src="assets/images/signin.svg" alt="Sign In" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default SignIn;
