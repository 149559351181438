import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Dashboard() {
    const [dashboardData, setDashboardData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.pgclick.in/api/dashboard');
                setDashboardData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();
    }, []);

    // Function to determine progress bar color gradient based on percentage
    const getProgressGradient = (percentage) => {
        if (percentage >= 80) {
            return 'linear-gradient(to right, #28a745, #28a745)'; // Green gradient for 80% or above
        } else if (percentage >= 60) {
            return 'linear-gradient(to right, #17a2b8, #17a2b8)'; // Blue gradient for 60-79%
        } else if (percentage >= 40) {
            return 'linear-gradient(to right, #007bff, #007bff)'; // Primary gradient for 40-59%
        } else if (percentage >= 20) {
            return 'linear-gradient(to right, #ffc107, #ffc107)'; // Yellow gradient for 20-39%
        } else {
            return 'linear-gradient(to right, #dc3545, #dc3545)'; // Red gradient for below 20%
        }
    };

    return (
        <>
            {dashboardData ? (
                <section className="content">
                    <div>
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-12">
                                    <h2>Dashboard</h2>
                                    <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12">
                                    <button className="btn btn-primary btn-icon float-right right_icon_toggle_btn" type="button"><i className="zmdi zmdi-arrow-right" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row clearfix">
                                {Object.entries(dashboardData).map(([key, value]) => {
                                    const percentage = (value / 100) * 100; // Calculate percentage
                                    const progressGradient = getProgressGradient(percentage); // Get progress bar gradient

                                    return (
                                        <div key={key} className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="card widget_2 big_icon traffic">
                                                <div className="body">
                                                    <h6>{key}</h6>
                                                    <h3>{value}</h3>
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={{ background: progressGradient, width: `${percentage}%` }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <p>Loading...</p>
            )}
        </>
    );
}

export default Dashboard;
