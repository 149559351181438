import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from '../Addsubcatimage/Addsubfeed.module.css';

function Editsubcatimage() {
    const { subcatimageId } = useParams();
    const navigate = useNavigate();
    const [feedimagedata, setFeedimagedata] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        image: null,
        link_id: "1",
        subcatefeed_id: "",
        from_date: "",
        to_date: "",
        is_active: "",
    })

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/subcatfeed-list`)
            .then((response) => {
                setFeedimagedata(response.data.ResponseData);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                toast.error("Error fetching data");
            });
    }, setFeedimagedata);

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            setFormData({ ...formData, image: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/subcatimagefeededit/${subcatimageId}`);
                if (response.data.ResponseCode === 1) {
                    const { name, subcatefeed_id, link_id, from_date, to_date, is_active, image } = response.data.ResponseData;
                    setFormData({
                        name: name || '',
                        subcatefeed_id: subcatefeed_id || '',
                        link_id: link_id || '',
                        from_date: from_date || '',
                        to_date: to_date || '',
                        is_active: is_active || '',
                        image: image || '',
                    });
                } else {
                    toast.error(response.data.ResponseText);
                }
            } catch (error) {
                console.error('Error Fetching Data: ', error);
            }
        };
        fetchData();
    }, [subcatimageId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/subcatimage-update/${subcatimageId}`;
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("name", formData.name);
            formDataToSend.append("subcatefeed_id", formData.subcatefeed_id);
            formDataToSend.append('link_id', formData.link_id);
            formDataToSend.append("image", formData.image);
            formDataToSend.append("from_date", formData.from_date);
            formDataToSend.append("to_date", formData.to_date);
            formDataToSend.append("is_active", formData.is_active);
            const response = await axios.post(apiUrl, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data.ResponseCode) {
                toast.success("feedimage added successfully");
                navigate("/subcatfeedimage");
            }
        } catch (error) {
            console.error("Error adding feedimage", error);

        }
    }

    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Update FeedImage Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/subcatfeedimage">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >
                                <div className="card">
                                    <div className="body py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap "> Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='name' id="subcategory_name" className="form-control " placeholder="Enter name " onChange={(e) => handleInputChange(e)} value={formData.name} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap "> Link Id:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='link_id' id="subcategory_name" value={formData.link_id} className="form-control " placeholder="Enter link id " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">From Date:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="date" name='to_date' id="subcategory_name" value={formData.to_date} className="form-control " placeholder="Enter link id " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">To Date:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="date" name='from_date' id="subcategory_name" value={formData.from_date} className="form-control " placeholder="Enter link id " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password">Image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name="image" id="image" placeholder="Upload Image" onChange={(e) => handleInputChange(e)}  />
                                                            <img src={formData.image} alt="feedimage" className='mt-2' width={150} height={100} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" >Feed :-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select name="subcatefeed_id" id="templateId" value={formData.subcatefeed_id} required class="form-control show-tick ms select2" onChange={(e) => handleInputChange(e)}>
                                                                <option value="">
                                                                    --select Feedname--
                                                                </option>
                                                                {feedimagedata &&
                                                                    feedimagedata.map((v) => {
                                                                        return (
                                                                            <option value={v.id} key={v.id}>
                                                                                {v.fieldname}
                                                                            </option>
                                                                        );
                                                                    })}

                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>


                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="is_active" className="text-nowrap ">Status:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} value={formData.is_active} data-placeholder="Select" >
                                                                <option value="">-- Select Status --</option>
                                                                <option value="1">Active</option>
                                                                <option value="2">Deactive</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Editsubcatimage