import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './metting.module.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Addmetting() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('metting');
    const [key, setKey] = useState('metting');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [city, setCity] = useState([]);
    const [formData, setFormData] = useState({
        meeting_name: '',
        meeting_data: '',
        image_url: null,
        sdate: '',
        edate: '',
        register_amount: '',
        register_damount: '',
        gst: '',
        city_id: '',
    });

    // State variable to track whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleInputChange = (e) => {
        if (e.target.name === 'image_url') {
            const imageFile = e.target.files[0];
            setFormData({ ...formData, image_url: imageFile || null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('meeting_name', formData.meeting_name);
        formDataToSend.append('image_url', formData.image_url);
        formDataToSend.append('meeting_data', formData.meeting_data);
        formDataToSend.append('sdate', formData.sdate);
        formDataToSend.append('edate', formData.edate);
        formDataToSend.append('register_amount', formData.register_amount);
        formDataToSend.append('register_damount', formData.register_damount);
        formDataToSend.append('gst', formData.gst);
        formDataToSend.append('city_id', formData.city_id);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/add-meeting`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.ResponseCode) {
                toast.success('metting added successfully');
                localStorage.setItem("mettingId", response.data.ResponseData.id);
                setActiveTab('mettingimage');
            }
        } catch (error) {
            console.error('Error adding metting', error);
            toast.error('Error adding metting');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/city-list`);
                setCity(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchData();
    }, [])


    const [mettingimage, setMettingimage] = useState([]);
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/meetingwiseimagelist/${localStorage.getItem("mettingId")}`);
            setMettingimage(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };
    useEffect(() => {
        fetchdata();
    }, [])

    const handleUpload = async () => {
        const apiUrl = `${process.env.REACT_APP_API}/add-meetingimage`;

        try {
            const formData = new FormData();
            formData.append('meeting_id', localStorage.getItem("mettingId"));
            formData.append('image_url', mettingimage[0]);

            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.ResponseCode) {
                toast.success("Description uploaded successfully");
                setMettingimage("");
                fetchdata();
            }
        } catch (error) {
            console.error("Error uploading description", error);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setMettingimage([file]); // Update mettingimage state with the selected file
    };


    const handlesubDelete = (id) => {
        axios
            .post(`${process.env.REACT_APP_API}/meetingimage-delete/${id}`)
            .then((response) => {
                toast.success('Subimage deleted successfully');
                setMettingimage(mettingimage.filter(item => item.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting Subimage: ', error);
                toast.error('Error deleting Subimage');
            });
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Set modal state to closed
        setMettingimage(""); // Clear description when modal is closed
    };


    const handleSaveAndNavigate = () => {
        localStorage.removeItem('mettingId');
        window.location.href = "/mettinglist";
    };

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Metting Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/mettinglist">
                                    <button className="btn btn-primary float-right justify-content-end" type="button" onClick={handleSaveAndNavigate}>Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Tabs id="uncontrolled-tab-example" activeKey={activeTab} onSelect={(k) => setKey(k)} >
                        <Tab eventKey="metting" title="Metting" onSelect={() => setGeneralTabFilled(false)}>
                            <div className="container-fluid">
                                <div className="row clearfix ">
                                    <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center">
                                        <div className="card">
                                            <div className="body d-flex justify-content-center w-xl-75 w-lg-100 w-xxl-75 mt-4">
                                                <form method="post" className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="name" className="text-nowrap ">Metting Name:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="text" name="meeting_name" id="name" className="form-control" placeholder="Enter your metting name" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}  >
                                                            <label htmlFor="image_url" className="text-nowrap">Metting image:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group pt-2">
                                                                <input type="file" name="image_url" id="image_url" accept="image/*" placeholder="Enter your metting image" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="metting_link" className="text-nowrap ">Meeting data:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="date" name="meeting_data" id="meeting_data" className="form-control" placeholder="Enter your meeting data" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="metting_link" className="text-nowrap ">Meeting sdata:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="date" name="sdate" id="sdate" className="form-control" placeholder="Enter your meeting data" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="metting_link" className="text-nowrap ">Meeting edata:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="date" name="edate" id="edate" className="form-control" placeholder="Enter your meeting data" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="metting_link">Amount:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="number" name="register_amount" id="register_amount" className="form-control" placeholder="Enter your register amount" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="metting_link" > Disc.amount:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="number" name="register_damount" id="register_damount" className="form-control" placeholder="Enter your register damount" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="metting_link" > Gst:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <input type="number" name="gst" id="gst" className="form-control" placeholder="Enter your gst" onChange={(e) => handleInputChange(e)} required />
                                                            </div>
                                                        </Col>
                                                    </Row>


                                                    <Row className="d-flex align-items-center">
                                                        <Col lg={2} xl={2} xxl={2}>
                                                            <label htmlFor="is_active" className="text-nowrap ">City:-</label>
                                                        </Col>
                                                        <Col lg={9} xl={9} xxl={9}>
                                                            <div className="form-group">
                                                                <select className="form-control show-tick ms select2" id="city_id" required name="city_id" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                    <option value="">-- Select city --</option>
                                                                    {city.map((v) => (
                                                                        <option value={v.id} key={v.id}>{v.cityname}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className='d-flex justify-content-end mt-3'>
                                                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="mettingimage" title="Metting Image">
                            <div className="container-fluid">
                                <div className="row clearfix">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="header">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-6 col-sm-12">
                                                    </div>
                                                    <div className="col-lg-5 col-md-6 col-sm-12">
                                                        <button className="btn btn-primary float-right " data-toggle="modal"
                                                            data-target="#EmploymentEditModal" type="button">Add Metting Image</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover  dataTable">
                                                        <thead>
                                                            <tr>
                                                                <th className='w-10'>No</th>
                                                                <th>Image</th>
                                                                <th className='w-250'>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {mettingimage.length > 0 && mettingimage.map((mettingimage, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <img src={mettingimage.image_url} alt="image" width={"150px"} />
                                                                    </td>

                                                                    <td>
                                                                        <button className="btn btn-danger ms-2" onClick={() => handlesubDelete(mettingimage.id)}>
                                                                            <i className="bi bi-trash-fill me-1"></i>
                                                                            Delete
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade " id="EmploymentEditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                                aria-hidden="true" onClose={handleModalClose} >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title " id="EmploymentModal">Add Metting Image</h5>
                                        </div>
                                        <div className="modal-body ">
                                            <form className='d-flex flex-column gap-3'>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap "> Image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name="image_url" id="image" className="form-control" onChange={(e) => handleFileChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-primary" data-dismiss="modal" style={{
                                                borderRadius: "30px"
                                            }} onClick={(e) => handleUpload(e)}>Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section >
        </>
    );
}

export default Addmetting;
