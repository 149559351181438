import React, { useEffect, useRef } from 'react'
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { useState } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Pagination } from 'react-bootstrap';


function Addclient() {
    const navigate = useNavigate();
    const [key, setKey] = useState('details');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [membershipData, setMembershipData] = useState([]);
    const [status, setStatus] = useState([]);
    const [activeTab, setActiveTab] = useState('details');
    const handleTabSelect = (key) => {
        setActiveTab(key);
    };
    const isTabValid = () => {
        const formDataFields = Object.keys(formData);
        let requiredFields;

        if (activeTab === 'details') {
            requiredFields = ['user_id', 'company_name', 'contact1', 'address', 'state_id', 'city_id', 'clients_type', 'company_type', 'subcate_id', 'membershipp_id'];
        } else if (activeTab === 'image') {
            requiredFields = ['aadharcard_number', 'aadharcardimage', 'pancard_number', 'pancard_image'];
        } else if (activeTab === 'specification') {
            requiredFields = ['logo', 'selfie_image', 'signature_url'];
        }

        // Find the missing fields in the current tab
        const missingFields = requiredFields.filter((field) => {
            const fieldValue = formData[field];
            // Check if fieldValue is a string and not empty
            return typeof fieldValue === 'string' && fieldValue.trim() === '';
        });

        if (missingFields.length > 0) {
            const firstMissingField = missingFields[0];
            toast.error(`Please fill in ${getFieldName(firstMissingField)} before proceeding.`);
            return false;
        }
        return true;
    };

    const getFieldName = (fieldName) => {
        const fieldNamesMap = {
            user_id: 'User',
            company_name: 'Company Name',
            contact1: 'Contact Number',
            contact2: 'Contact Number 2',
            address: 'Address',
            state_id: 'State',
            city_id: 'City',
            description: 'Description',
            clients_type: 'Clients Type',
            company_type: 'Company Type',
            subcate_id: 'Sub Category',
            aadharcard_number: 'Adhar Number',
            // gst_number: 'GST Number',
            pancard_number: 'PAN Number',
            aadharcardimage: 'Aadhar Card Image',
            pancard_image: 'PAN Card Image',
            // gst_image: 'GST Image',
            logo: 'Logo',
            selfie_image: 'Selfie Image',
            signature_url: 'Signature Image',
            membershipp_id: 'Membershipp_id',
        };
        return fieldNamesMap[fieldName] || fieldName;
    };

    const handleSaveAndNext = () => {
        if (isTabValid()) {
            let nextTab;

            switch (activeTab) {
                case 'details':
                    nextTab = 'image';
                    break;
                case 'image':
                    nextTab = 'specification';
                    break;
                default:
                    nextTab = 'details';
            }
            setActiveTab(nextTab);
        }
    };
    const handleBack = () => {
        let previousTab;

        switch (activeTab) {
            case 'image':
                previousTab = 'details';
                break;
            case 'specification':
                previousTab = 'image';
                break;
            case 'keyword':
                previousTab = 'specification';
                break;
            default:
                previousTab = 'details';
        }

        setActiveTab(previousTab);
    };
    useEffect(() => {
        // const fetchuser = async () => {
        //     try {
        //         const response = await axios.get(`${process.env.REACT_APP_API}/userlist`);
        //         setUser(response.data.ResponseData);
        //     } catch (error) {
        //         console.error('Error fetching categories: ', error);
        //     }
        // };       

        const fetchCountry = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/categorylist`);
                setCountryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching categories: ', error);
            }
        };
        const fetchState = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/state-list`);
                setStateData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching subcategories: ', error);
            }
        };
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/categorylist`);
                setCategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching categories: ', error);
            }
        };

        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/subcategory-list`);
                setSubcategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching subcategories: ', error);
            }
        };

        const fetchmembership = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/membership`);
                setMembershipData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching subcategories: ', error);
            }
        };
        fetchmembership();
        fetchCountry();
        fetchState();
        fetchCategories();
        fetchSubcategories();
    }, []);
    const userDataString = localStorage.getItem('selectedUserData');
    const userData = JSON.parse(userDataString);
    const userId = userData ? userData.user_id : null;
    const [formData, setFormData] = useState({
        status: '3',
        user_id: userId,
        company_name: '',
        country_id: '1',
        subcate_id: '',
        address: '',
        state_id: '',
        city_id: '',
        contact1: '',
        company_type: '',
        pancard_number: '',
        aadharcard_number: '',
        gst_number: '',
        description: '',
        contact2: '',
        gst_comment: '',
        signature_url: null,
        selfie_image: '',
        logo: '',
        gst_image: null,
        aadharcardimage: '',
        pancard_image: '',
        clients_type: '',
        membershipp_id: '',
    });
    console.log('userid', userId);
    const handleInputChange = (e) => {

        const { name, value } = e.target;

        if (name === "state_id") {
            axios
                .post(`${process.env.REACT_APP_API}/city-list-statewise`, {
                    state_id: value,
                })
                .then((response) => {
                    setCityData(response.data.ResponseData);
                })
                .catch((error) => {
                    console.error("Error fetching city data:", error);
                });
        }
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleFileInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0],
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (activeTab === 'specification') {
            if (!formData.logo) {
                toast.error('Please upload the business logo.');
                return;
            }
            if (!formData.selfie_image) {
                toast.error('Please upload the selfie image.');
                return;
            }
            if (!formData.signature_url) {
                toast.error('Please upload the signature image.');
                return;
            }
        }
        try {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });
            Object.entries(formData).forEach(([key, value]) => {
                if (value instanceof File) {
                    formDataToSend.append(key, value);
                }
            });
            const response = await fetch(`${process.env.REACT_APP_API}/addclients`, {
                method: 'POST',
                body: formDataToSend,
            });
            const data = await response.json();
            if (data.ResponseCode === 1) {
                localStorage.setItem('clientId', data.ResponseData.id);
                console.log('Client added successfully:', data);
                toast.success('Client added successfully');
                navigate('/vendorrequest');
            } else {
                console.error('Error adding client:', data.ResponseText);
            }
        } catch (error) {
            // console.error('Error:', error);
        }
    };
    const [selectedUser, setSelectedUser] = useState({ id: '', full_name: '' });
    const [showModal, setShowModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(5);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/userlist`);
                setUserList(response.data.ResponseData);
                setFilteredUserList(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching user list:', error);
            }
        };
        fetchUsers();
    }, []);

    const handleAddUserClick = () => {
        setShowModal(true);
        setCurrentPage(1); // Reset current page when modal is opened
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSearchQuery('');
        setCurrentPage(1); // Reset current page when modal is closed
    };

    const handleUserSelect = (user) => {
        localStorage.setItem('selectedUserData', JSON.stringify({ user_id: user.id, full_name: user.full_name }));
        setSelectedUser(user);
        handleCloseModal();
    };

    // Set selected user from localStorage on component mount
    useEffect(() => {
        const storedUserData = JSON.parse(localStorage.getItem('selectedUserData')) || { id: '', full_name: '' };
        setSelectedUser(storedUserData);
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset current page when search query changes
        filterUsers(e.target.value);
    };

    const filterUsers = (searchText) => {
        const filteredUsers = userList.filter((user) =>
            user.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
            user.phone.includes(searchText)
        );
        setFilteredUserList(filteredUsers);
    };

    // Pagination Logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUserList.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <>
            <section className="content ">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Client Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                        </div>
                    </div>
                    <Tabs id="controlled-tab-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)} >
                        <Tab eventKey="details" title="Details" onSelect={() => setGeneralTabFilled(false)}>
                            <div className="container-fluid">
                                <div className="row clearfix ">
                                    <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >
                                        <div className="card">
                                            <div className="body clientbody w-lg-100 mt-4" >
                                                <form method='post' onSubmit={(e) => handleSubmit(e)}>
                                                    <Row className='d-flex align-items-center'>
                                                        <div className='col-2'>
                                                            <label className="form-label">User Name :-</label>
                                                        </div>
                                                        <div className="form-group input-group col-10">
                                                            <InputGroup>
                                                                <Form.Control
                                                                    value={selectedUser.full_name}
                                                                />
                                                                <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#e4e4e4', color: 'black' }} onClick={handleAddUserClick}>
                                                                    Add User
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            {showModal && (
                                                                <Modal show={showModal} onHide={handleCloseModal}>
                                                                    <Modal.Header className='pt-3'>
                                                                        <Modal.Title className='display-6'>Select User</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body className='p-3 pt-2 '>
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Search users..."
                                                                            value={searchQuery}
                                                                            onChange={handleSearchChange}
                                                                            className="form-control p-3"
                                                                        />
                                                                        <table className="table">
                                                                            <tbody>
                                                                                {currentUsers.map((v) => (
                                                                                    <tr key={v.id} onClick={() => handleUserSelect(v)}>
                                                                                        <td>{v.full_name}</td>
                                                                                        <td>{v.phone}</td>
                                                                                    </tr>
                                                                                ))}
                                                                                {searchQuery !== '' && currentUsers.length === 0 && (
                                                                                    <tr>
                                                                                        <td colSpan="2">No results found</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination>
                                                                            {Array.from({ length: Math.ceil(filteredUserList.length / usersPerPage) }, (_, index) => (
                                                                                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                                                                                    {index + 1}
                                                                                </Pagination.Item>
                                                                            ))}
                                                                        </Pagination>
                                                                    </Modal.Body>
                                                                </Modal>
                                                            )}
                                                        </div>
                                                    </Row>
                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label className="form-label" >Company Name :-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <input type="text" className="form-control " name='company_name' value={formData.company_name} onChange={handleInputChange} />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label">Mobile No:- </label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input className="form-control " name='contact1' value={formData.contact1}
                                                                        onInput={(e) => {
                                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                                        }} onChange={handleInputChange} />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label">Contact No:- </label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <input className="form-control " name='contact2' value={formData.contact2} onInput={(e) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                                    }} onChange={handleInputChange} />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label className="form-label" >Address :-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <textarea type="text" className="form-control " name='address' value={formData.address} onChange={handleInputChange} />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label ">State:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <select name="state_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} >
                                                                        <option value={formData.state_id ? "" : ""} >
                                                                            Select state
                                                                        </option>
                                                                        {stateData &&
                                                                            stateData.map((v, index) => {
                                                                                return (
                                                                                    <option value={v.state_id} key={index.id}>
                                                                                        {v.statename}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>

                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center'>
                                                                <div className='col-3'>
                                                                    <label className="form-label">City :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="city_id" id="templateId" class="form-control show-tick ms select2 " data-placeholder="Select" onChange={handleInputChange} >
                                                                        <option value={formData.city_id ? "" : ""} >
                                                                            Select city
                                                                        </option>
                                                                        {cityData &&
                                                                            cityData.map((v, index) => {
                                                                                return (
                                                                                    <option value={v.id} key={index.id}>
                                                                                        {v.cityname}
                                                                                    </option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label htmlFor="password ">Description:-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <textarea type="text" className="form-control" name='description' value={formData.description} onChange={handleInputChange} />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label ">Company Type:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <select name='clients_type' className='form-control show-tick ms select2 ' data-placeholder="Select" onChange={handleInputChange}  >
                                                                        <option value={formData.clients_type ? "" : ""} >
                                                                            Select clients type
                                                                        </option>
                                                                        <option value={1}>Service Provider</option>
                                                                        <option value={2}>Product Seller</option>
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label">Business Type:-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name='company_type' className='form-control show-tick ms select2 ' data-placeholder="Select" onChange={handleInputChange}  >
                                                                        <option value={formData.company_type ? "" : ""} >
                                                                            select company type
                                                                        </option>
                                                                        <option value={1}>Private Limited</option>
                                                                        <option value={2}>Public Limited</option>
                                                                    </select>
                                                                </div>

                                                            </Row>
                                                        </div>

                                                    </Row>
                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label text-nowrap ">Sub Category:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <select name="subcate_id" id="templateId" class="form-control show-tick ms select2" data-placeholder="Select" onChange={handleInputChange} >
                                                                        <option value={formData.subcate_id ? "" : ""} >
                                                                            --Select category--
                                                                        </option>
                                                                        {subcategoryData &&
                                                                            subcategoryData.map((v, index) => {
                                                                                return (
                                                                                    <option value={v.id} key={index.id}>
                                                                                        {v.subcategory_name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label">Membership:-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="membershipp_id" id="membershipp_id" class="form-control show-tick ms select2 " data-placeholder="Select" onChange={handleInputChange} >
                                                                        <option value={formData.membershipp_id ? "" : ""} >
                                                                            --  Select membership --
                                                                        </option>
                                                                        {membershipData &&
                                                                            membershipData.map((v, index) => {
                                                                                return (
                                                                                    <option value={v.id} key={index.id}>
                                                                                        {v.membership_name}
                                                                                    </option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>

                                                            </Row>
                                                        </div>
                                                    </Row>
                                                    <div className='d-flex justify-content-end'>
                                                        <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext} >Save & Next</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="image" title="Image">
                            <div className="container-fluid">
                                <div className="row clearfix ">
                                    <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >
                                        <div className="card">
                                            <div className="body clientbody w-lg-100 mt-4" >
                                                <div >
                                                    <div className="row clearfix">
                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className='d-flex'>
                                                                        <Col>
                                                                            <Row className='d-flex line  align-items-center mb-3 mt-5 ' xxl={5} xl={5} lg={8}  >
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Aadhar number:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <input type="text" className="form-control " onInput={(e) => {
                                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 12);
                                                                                    }} name='aadharcard_number' value={formData.aadharcard_number} onChange={handleInputChange} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='d-flex line  align-items-center mb-3 ' xxl={5} xl={5} lg={8}>
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Aadhar Image:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <div >
                                                                                        <input type="file" accept="image/*" name='aadharcardimage' onChange={handleFileInputChange} />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row className='d-flex line  align-items-center mb-3 mt-5 pt-5' xxl={5} xl={5} lg={8}>
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">GST number:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <input type="text" className="form-control" placeholder='GST Number' name='gst_number' maxLength={15} value={formData.gst_number} onChange={handleInputChange} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='d-flex line  align-items-center mb-3 ' xxl={5} xl={5} lg={8}>
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">GST Image:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <div >
                                                                                        <input type="file" accept="image/*" name='gst_image' onChange={handleFileInputChange} />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row className='d-flex line  align-items-center mb-3 mt-5 pt-5' xxl={5} xl={5} lg={8}>
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Pan number:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <input type="text" className="form-control" name='pancard_number' maxLength={10} value={formData.pancard_number} onChange={handleInputChange} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='d-flex line  align-items-center mb-3 ' xxl={5} xl={5} lg={8} >
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Pan Image:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <div >
                                                                                        <input type="file" accept="image/*" name="pancard_image" onChange={handleFileInputChange} />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex justify-content-end mt-5' >
                                                                    <div>
                                                                        <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                        <button className="  btn btn-primary" onClick={() => { handleSaveAndNext() }} >Save & Next</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="specification" title="Specification" >
                            <div className="container-fluid">
                                <div className="row clearfix ">
                                    <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >
                                        <div className="card">
                                            <div className="body clientbody w-lg-100 mt-4" >
                                                <div >
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className='d-flex'>
                                                                        <Col >
                                                                            <Row className='d-flex line  align-items-center mb-3 mt-5 ' xxl={5} xl={5} lg={8} >
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Business logo:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <input type="file" accept="image/*" name='logo' onChange={handleFileInputChange} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='d-flex line  align-items-center mb-3 mt-5 pt-5' xxl={5} xl={5} lg={8}>
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Selfi Image:-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <input type="file" accept="image/*" name='selfie_image' onChange={handleFileInputChange} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='d-flex line  align-items-center mb-3 mt-5 pt-5' xxl={5} xl={5} lg={8}>
                                                                                <Col xxl={1} xl={2} lg={2}>
                                                                                    <label className="form-label text-nowrap">Signature :-</label>
                                                                                </Col>
                                                                                <Col xxl={4} xl={4} lg={4} className='justify-content-start'>
                                                                                    <input type="file" accept="image/*" name='signature_url' onChange={handleFileInputChange} />
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <Col>
                                                                        <div className='d-flex me-5 mt-5 justify-content-evenly' style={{ marginLeft: "600px" }}>
                                                                            <button className="btn btn-primary   ps-5 pe-5 ms-5" onClick={handleBack} >Back</button>
                                                                            <form >
                                                                                <div className='d-flex  justify-content-center '>
                                                                                    <button type="submit" onClick={handleSubmit} className=' btn btn-primary   ps-4 pe-4 ms-2'>Submit</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section >
            <ToastContainer />
        </>
    )
}

export default Addclient