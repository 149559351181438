import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';

function Viewmenu() {
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/menu-list`);
            // setBanners(response.data.data);
            const ResponseData = response.data.ResponseData.map(item => {
                return {
                    ...item,
                    status: parseInt(item.status)
                }
            })
            setMenu(ResponseData);
        } catch (error) {
            console.error("Error fetching banners: ", error);
        }
    };
    const handleDelete = (id) => {
        axios
            .post(`${process.env.REACT_APP_API}/menu-delete/${id}`)
            .then((response) => {
                // console.log('Baneer deleted successfully:', response.data.ResponseData);
                setMenu((prevData) => prevData.filter((data) => data.id !== id));
                toast.success('menu deleted');
            })
            .catch((error) => {
                console.error('Error deleting Baneer: ', error);
                toast.error('Error deleting Baneer');
            });
    };
    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Menu List</h2>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/dashboard"><i className="zmdi zmdi-home" /> Dashboard</a></li>
                                </ul>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/addmenu">
                                    <button className="btn btn-primary float-right" type="button">Add Menu</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Basic Examples */}
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">NO</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {menu.map((menu, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{menu.menu_name}</td>


                                                            <td>
                                                                {menu.is_active === 1 ? (
                                                                    <span className='text-success fw-bolder'>Active</span>
                                                                ) : (
                                                                    <span className='text-danger fw-bolder'>Deactive</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {/* <button className="btn btn-primary">Edit</button> */}
                                                                <Link
                                                                    to={`/editmenu/${menu.id}`}
                                                                    className="btn btn-primary m-2"
                                                                >
                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                    Edit
                                                                </Link>
                                                                <button className="btn btn-danger ms-2 " onClick={() => handleDelete(menu.id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}

export default Viewmenu
