import React, { useEffect, useState } from 'react';
import axios from "axios";

function Viewuserlist() {
    const [banners, setBanners] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/userlist`);
            const ResponseData = response.data.ResponseData.map(item => {
                return {
                    ...item,
                    status: parseInt(item.status)
                }
            })
            setBanners(ResponseData);
        } catch (error) {
            console.error("Error fetching banners: ", error);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredBanners = banners.filter(user => {
        return (
            (user.full_name && user.full_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.phone && user.phone.includes(searchQuery))
        );
    });

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredBanners.slice(indexOfFirstUser, indexOfLastUser);


    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>User List</h2>
                                <ul className="breadcrumb">
                                </ul>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Basic Examples */}
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="body">
                                        <div className='w-100 d-flex align-items-center justify-content-end mb-4'>
                                            <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control w-25" />
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover  dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">NO</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Profile Pic</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope='col'>City</th>
                                                        <th scope='col'>UserType</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentUsers.map((user, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{user.full_name}</td>
                                                            <td>{user.email}</td>
                                                            <td >
                                                                <img src={user.profile_pic} alt="profilepic" style={{ width: "150px", height: "120px" }} />
                                                            </td>
                                                            <td>{user.phone}</td>
                                                            <td>{user.cityname}</td>
                                                            <td>{user.user_type == 1 ? "User" : "Client"}</td>
                                                            <td>
                                                                {user.is_active == 1 ? (
                                                                    <span className='text-success fw-bolder'>Active</span>
                                                                ) : (
                                                                    <span className='text-danger fw-bolder'>Deactive</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-end mt-3'>
                                        <ul className="pagination">
                                            {Array.from({ length: Math.ceil(filteredBanners.length / usersPerPage) }).map((_, index) => (
                                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                    <button onClick={() => handlePageChange(index + 1)} className="page-link">
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Viewuserlist;
