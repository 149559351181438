import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, ToastContainer, toast } from "react-toastify";
// import 'resize-observer-polyfill/dist/ResizeObserver.global';
import axios from 'axios';

function ViewTermsConditions() {
  const [editorData, setEditorData] = useState('');
  const [termscondition, settermscondition] = useState({ description: '' });

  useEffect(() => {
    fetchtermscondition();
  }, []);

  const fetchtermscondition = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/termsofuse`);
      settermscondition(response.data.ResponseData[0]);
      setEditorData(response.data.ResponseData[0].description);
    } catch (error) {
      console.error("Error fetching Privacy Policy:", error);
    }
  };

  const handleCKEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleUpdatetermscondition = (id) => {
    axios
      .post(`${process.env.REACT_APP_API}/termsofuseupdate/${id}`, {
        description: editorData,
      })
      .then((response) => {
        // alert('Privacy Policy updated successfully');
        toast.success('Terms & Conditions updated successfully');
      })
      .catch((error) => {
        console.error('Error updating Privacy Policy:', error);
      });
  };
  
  return (
    <>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Terms & Conditions</h2>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Basic Examples */}
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div>
                    <div className='col-sm-8 me-5 mt-4'>
                      <CKEditor
                        editor={ClassicEditor}
                        data={editorData}
                        onChange={handleCKEditorChange}
                      />
                    </div>
                    <div className='m-3'>
                      <button
                        type="button" className="btn btn-primary ps-4 pe-4" style={{ backgroundColor: "#014c8d", borderColor: "#014c8d", }}
                        onClick={() => handleUpdatetermscondition(termscondition.id)}
                      >
                        Update Terms & Conditions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  )
}

export default ViewTermsConditions