import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";


function Viewunit() {
    const [feed, setHomeFeed] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        fetchHomeCategory();
    }, []);

    const fetchHomeCategory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API}/unit-list`
            );
            setHomeFeed(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching Unit", error);
        }
    };

    const handleDelete = (id) => {
        axios
            .post(`${process.env.REACT_APP_API}/unit-delete/${id}`)
            .then((response) => {
                toast.success("unit deleted");
                setHomeFeed((prevData) =>
                    prevData.filter((data) => data.id !== id)
                );
            })
            .catch((error) => {
                console.error("Error deleting unit: ", error);
                toast.error("Error deleting unit");
            });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentFeed = feed.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Unit List</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/addunit">
                                    <button className="btn btn-primary float-right " type="button">Add Unit</button>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Basic Examples */}
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col " className="w-5">NO</th>
                                                        <th scope="col">Unit Type</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" className="w-250">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {currentFeed.map((feed, index) => (
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{feed.unit_type}</td>
                                                            <td>
                                                                {feed.is_active === 1 ? (
                                                                    <span className='text-success fw-bolder'>Active</span>
                                                                ) : (
                                                                    <span className='text-danger fw-bolder'>Deactive</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={`/editunit/${feed.unit_type_id}`}
                                                                    className="btn btn-primary m-2"
                                                                >
                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                    Edit
                                                                </Link>
                                                                <button className="btn btn-danger ms-2 " onClick={() => handleDelete(feed.unit_type_id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example ">
                    <ul className="pagination justify-content-end">
                        {Array.from({ length: Math.ceil(feed.length / itemsPerPage) }).map((_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </section>
            <ToastContainer />
        </>
    )
}

export default Viewunit