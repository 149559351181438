import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function Mettinglist() {
    const [postinquiryrequest, setpostinquiryrequest] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        fetchdata();
    }, [])
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/meeting-list`);
            setpostinquiryrequest(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    
    const handleDetailClick = (meeting_id) => {
        axios
            .post(`${process.env.REACT_APP_API}/meeting-delete/${meeting_id}`)
            .then((response) => {
                setpostinquiryrequest((prevData) => prevData.filter((data) => data.meeting_id !== meeting_id));
                toast.success('Baneer deleted successfully');
            })
            .catch((error) => {
                console.error('Error deleting Baneer: ', error);
                toast.error('Error deleting Baneer');
            });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentpostinquiryrequest = postinquiryrequest.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

 

    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    }

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Metting List</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/mettingadd">
                                    <button className="btn btn-primary float-right " type="button">Add Metting</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No</th>
                                                        <th scope="col"> Name</th>
                                                        <th scope="col"> Image</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Dis.Amount</th>
                                                        <th scope="col">gst</th>
                                                        <th scope="col">Start Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Metting Date</th>
                                                        <th scope="col" className='w-250'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentpostinquiryrequest.map((productdata, index) => (
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{productdata.meeting_name}</td>
                                                            <td><img src={productdata.image_url} alt="image" style={{ width: '70px', height: '70px' }} /></td>
                                                            <td>{productdata.register_amount}</td>
                                                            <td>{productdata.register_damount}</td>
                                                            <td>{productdata.gst}</td>
                                                            <td>{formatDate(productdata.sdate)}</td>
                                                            <td>{formatDate(productdata.edate)}</td>
                                                            <td>{formatDate(productdata.meeting_data)}</td>
                                                            <td >
                                                                <Link
                                                                    to={`/mettingedit/${productdata.meeting_id}`}
                                                                    className="btn btn-primary m-2"
                                                                >
                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                    Edit
                                                                </Link>
                                                                <button className="btn btn-danger ms-2 "  onClick={() => handleDetailClick(productdata.meeting_id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example ">
                    <ul className="pagination justify-content-end">
                        {Array.from({ length: Math.ceil(postinquiryrequest.length / itemsPerPage) }).map((_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </section >
        </>
    )
}

export default Mettinglist