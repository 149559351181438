import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Categorywise.module.css';

function Editcategorybanner() {
    const { categorybannerId } = useParams();
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState([]);
    const [formData, setFormData] = useState({
        category_id: "",
        name: "",
        bannder_linkid: "",
        imageurl: null,
        from_date: "",
        to_date: "",
        is_active: ""
    });

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/categorylist`)
            .then((response) => {
                setCategoryData(response.data.ResponseData);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                toast.error("Error fetching data");
            });
    }, setCategoryData);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/categorieswisebanneredit/${categorybannerId}`)
            .then((response) => {
                if (response.data.ResponseCode == 1) {
                    const { name, category_id, imageurl, bannder_linkid, is_active, from_date, to_date } = response.data.ResponseData;
                    setFormData({
                        category_id: category_id || '',
                        name: name || '',
                        bannder_linkid: bannder_linkid || '',
                        imageurl: imageurl || '',
                        is_active: is_active || '',
                        from_date: from_date || '',
                        to_date: to_date || '',
                    });
                } else {
                    toast.error(response.data.ResponseText);
                }
            })
            .catch((error) => {
                console.error('Error Fetching Categorywisebanner Data: ', error);
            });
    }, [categorybannerId]);

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            const file = e.target.files[0];
            setFormData({ ...formData, image: file, imagePath: file ? URL.createObjectURL(file) : '' });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataForSubmit = new FormData();
        formDataForSubmit.append("category_id", formData.category_id);
        formDataForSubmit.append("name", formData.name);
        formDataForSubmit.append("imageurl", formData.imageurl);
        formDataForSubmit.append("bannder_linkid", formData.bannder_linkid);
        formDataForSubmit.append("from_date", formData.from_date);
        formDataForSubmit.append("to_date", formData.to_date);
        formDataForSubmit.append("is_active", formData.is_active);
        axios
            .post(
                `${process.env.REACT_APP_API}/categorieswisebanner-update/${categorybannerId}`,
                formDataForSubmit,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((response) => {
                toast.success('Categorywisebanner Updated Successfully');
                navigate('/Viewcategorybanner');
            })
            .catch((error) => {
                console.error('Error updating Categorywisebanner:', error);
            });
    };

    return (
        <>

            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Category Banner</h2>

                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/Viewcategorybanner">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Vertical Layout */}
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                                <div className="card">
                                    <div className="body   py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Category Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select name="category_id" id="templateId" required value={formData.category_id} class="form-control show-tick ms select2" onChange={(e) => handleInputChange(e)}>
                                                                <option value="" >
                                                                    --select category--
                                                                </option>
                                                                {categoryData &&
                                                                    categoryData.map((v) => {
                                                                        return (
                                                                            <option value={v.id} key={v.id}>
                                                                                {v.category_name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center"> 
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Banner Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='name' id="name" value={formData.name} className="form-control " placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Banner Image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name="imageurl" id="image" accept="image/*" className="form-control " placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} />
                                                            {formData.imageurl &&
                                                                <img src={formData.imageurl} alt="Selected Image" style={{ width: '170px', height: '100px', marginTop: '10px' }} />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Bannder link:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='bannder_linkid' id="url" value={formData.bannder_linkid} className="form-control " placeholder="Enter your Product URL " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">From Date:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="date" name='from_date' id="from_date" value={formData.from_date} className="form-control " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">To Date:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="date" name='to_date' id="to_date" value={formData.to_date} className="form-control " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Status;-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div class="form-group">
                                                            <select class="form-control show-tick ms select2" required id='is_active' value={formData.is_active} name="is_active" onChange={handleInputChange} data-placeholder="Select" >
                                                                <option>-- Select Status --</option>
                                                                <option value={1}>
                                                                    Active
                                                                </option>
                                                                <option value={2}>
                                                                    Deactive
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Editcategorybanner