import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function EditHomebanner() {
    const { homebannerId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        bannder_link: "",
        image: null,
        from_date: "",
        to_date: "",
        is_active: ""
    });

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/homebanneredit/${homebannerId}`)
            .then((response) => {
                if (response.data.ResponseCode == 1) {
                    const { name, image, bannder_link, is_active, from_date, to_date } = response.data.ResponseData;
                    setFormData({
                        name: name || '',
                        bannder_link: bannder_link || '',
                        image: image || '',
                        is_active: is_active || '',
                        from_date: from_date || '',
                        to_date: to_date || '',
                    });
                } else {
                    toast.error(response.data.ResponseText);
                }
            })
            .catch((error) => {
                console.error('Error Fetching Homebanner Data: ', error);
            });
    }, [homebannerId]);

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            const file = e.target.files[0];
            setFormData({ ...formData, image: file, imagePath: file ? URL.createObjectURL(file) : '' });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataForSubmit = new FormData();
        formDataForSubmit.append("name", formData.name);
        formDataForSubmit.append("image", formData.image);
        formDataForSubmit.append("bannder_link", formData.bannder_link);
        formDataForSubmit.append("from_date", formData.from_date);
        formDataForSubmit.append("to_date", formData.to_date);
        formDataForSubmit.append("is_active", formData.is_active);
        axios
            .post(
                `${process.env.REACT_APP_API}/homebanner-update/${homebannerId}`,
                formDataForSubmit,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((response) => {
                toast.success('Homebanner Updated Successfully');
                navigate('/viewhomebanner');
            })
            .catch((error) => {
                console.error('Error updating Homebanner:', error);
            });
    };

    return (
        <>

            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Update Home Banner</h2>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/viewhomebanner"><i className="zmdi zmdi-home" /> Home</a></li>
                                </ul>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewHomebanner">
                                    <button className="btn btn-primary btn-icon float-right right_icon_toggle_btn" type="button"><i className="zmdi zmdi-arrow-right" /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Vertical Layout */}
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                                <div className="card">
                                    <div className="header">
                                        <h2><strong>Home Banner</strong></h2>

                                    </div>
                                    <div className="body w-50">
                                        <form method='post' onSubmit={(e) => handleSubmit(e)}>
                                            <label htmlFor="email_address">Banner Name</label>
                                            <div className="form-group">
                                                <input type="text" name='name' id="name" value={formData.name} className="form-control " placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                                            </div>
                                            <label htmlFor="password">Banner Image</label>
                                            <div className="form-group">
                                                <input type="file" name="image" id="image" className="form-control " placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} />
                                                {formData.image &&
                                                    <img src={formData.image} alt="Selected Image" style={{ width: '170px', height: '100px', marginTop: '10px' }} />
                                                }
                                            </div>
                                            <label htmlFor="email_address">Slider URL</label>
                                            <div className="form-group">
                                                <input type="text" name='bannder_link' id="url" value={formData.bannder_link} className="form-control " placeholder="Enter your Product URL " onChange={(e) => handleInputChange(e)} required />
                                            </div>
                                            <label htmlFor="email_address">From Date</label>
                                            <div className="form-group">
                                                <input type="date" name='from_date' id="from_date" value={formData.from_date} className="form-control " onChange={(e) => handleInputChange(e)} required />
                                            </div>
                                            <label htmlFor="email_address">To Date</label>
                                            <div className="form-group">
                                                <input type="date" name='to_date' id="to_date" value={formData.to_date} className="form-control " onChange={(e) => handleInputChange(e)} required />
                                            </div>
                                            <div class="form-group">
                                                <label htmlFor="password">Status</label>
                                                <select class="form-control show-tick ms select2" id='is_active' value={formData.is_active} name="is_active" onChange={handleInputChange} data-placeholder="Select" required>
                                                    <option>-- Select Status --</option>
                                                    <option value={1}>
                                                        Active
                                                    </option>
                                                    <option value={2}>
                                                        Deactive
                                                    </option>
                                                </select>
                                            </div>


                                            <button type="submit" className="btn btn-raised btn-primary btn-round waves-effect">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditHomebanner