import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

function Listvendore() {
    const [vendorrequest, setVendorrequest] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        fetchdata();
    }, [])
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/clients-list`);
            setVendorrequest(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentvendorrequest = vendorrequest.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleDetailClick = (productId) => {
        localStorage.setItem('vendorrequestId', productId);
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    }

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Vendore List</h2>
                                
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">

                            </div>

                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                   
                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Sub-Category</th>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Business Type</th>
                                                        <th scope="col">Company Type</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {currentvendorrequest.map((productdata, index) => (
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{productdata.full_name}</td>
                                                            <td>{productdata.company_name}</td>
                                                            <td>{productdata.subcategory_name}</td>
                                                            <td>{productdata.cityname}</td>
                                                            <td>{productdata.clients_type == 1 ? 'service provider' : 'product seller'}</td>
                                                            <td>{productdata.company_type == 1 ? "Private Limited" : "Public Limited"}</td>
                                                            <td>{formatDate(productdata.created_at)}</td>
                                                            <td>
                                                                {productdata.is_active == 1 ? (
                                                                    <span className='text-success fw-bolder'>Active</span>
                                                                ) : (
                                                                    <span className='text-danger fw-bolder'>Deactive</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Link to={`/updatevendorelist/${productdata.company_id}`}>
                                                                    <button className="btn btn-primary" onClick={() => handleDetailClick(productdata.company_id)}>
                                                                        Detail
                                                                    </button>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example ">
                    <ul className="pagination justify-content-end">
                        {Array.from({ length: Math.ceil(vendorrequest.length / itemsPerPage) }).map((_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </section >
        </>
    )
}

export default Listvendore