import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Category.module.css';

function Addcategory() {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    category_name: '',
    image: null,
    is_active: '',
  });

  const handleInputChange = (e) => {
    if (e.target.name === 'image') {
      setFormData({ ...formData, image: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API}/add-category`;
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('category_name', formData.category_name);
      formDataToSend.append('image', formData.image);
      formDataToSend.append('is_active', formData.is_active);
      formDataToSend.append("delete_at", formData.delete_at);
      const response = await axios.post(apiUrl, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.ResponseCode) {
        toast.success("Category added successfully");
        navigate("/viewcategory");
      }
    } catch (error) {
      console.error('Error adding category', error);
      toast.error('Error adding category');
    }
  };


  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Category Form</h2>
                <ul className="breadcrumb">

                </ul>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewcategory">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Vertical Layout */}
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="body  py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2} >
                            <label htmlFor="email_address" className="text-nowrap ">Category Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9} >
                            <div className="form-group">
                              <input type="text" name='category_name' id="category_name" className="form-control " placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="password" className="text-nowrap ">Image:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="file" name="image" id="image" accept="image/*" placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active" className="text-nowrap ">Status:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Addcategory
