import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './State.module.css';

function EditState() {
  const { stateId } = useParams();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    statename: '',
    countryid: '1',
    is_active: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const countryResponse = await axios.get(`${process.env.REACT_APP_API}/country-list`);
        // setCountries(countryResponse.data.ResponseData);

        const stateResponse = await axios.get(`${process.env.REACT_APP_API}/updatedata/${stateId}`);
        if (stateResponse.data.ResponseCode == 1) {
          const { statename, countryid, is_active } = stateResponse.data.ResponseData;
          setFormData({
            statename: statename || '',
            countryid: countryid || '',
            is_active: is_active || '',
          });
        } else {
          toast.error(stateResponse.data.msg);
        }
        console.log('State Data Fetched Successfully:', stateResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        navigate('/viewstate');
      }
    };

    fetchData();
  }, [stateId, navigate]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataForSubmit = new FormData();
    formDataForSubmit.append('statename', formData.statename);
    formDataForSubmit.append('countryid', formData.countryid);
    formDataForSubmit.append('is_active', formData.is_active);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/state-update/${stateId}`,
        formDataForSubmit,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.ResponseCode === 1) {
        console.log('State Updated Successfully:', response.data);
        toast.success('State Updated Successfully');
        navigate('/viewstate');
      } else {
        toast.error(response.data.ResponseText);
      }
    } catch (error) {
      console.error('Error updating State:', error);
    }
  };
  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> State Form</h2>

                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewstate">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Vertical Layout */}
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="body  py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">State Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" name="statename" className="form-control" id="order" value={formData.statename} placeholder="State Name" onChange={(e) => handleInputChange(e)}
                                required />
                            </div>
                          </Col>
                        </Row>


                        {/* <label htmlFor="email_address">Country</label>
                      <div className="form-group">
                        <select
                          name="countryid"
                          id="countryid"
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          value={formData.countryid}
                          required
                        >
                          <option value="">--select country--</option>
                          {countries.map((v) => (
                            <option key={v.id} value={v.id}>
                              {v.countryname}
                            </option>
                          ))}
                        </select>
                      </div> */}

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active">Status:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" value={formData.is_active} onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <button type="submit" className="btn btn-raised btn-primary  waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditState