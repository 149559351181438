import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Banner.module.css';

function Editbanner() {

    const { bannerId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        bannder_link: "",
        image: null,
        is_active: ""
    });

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/banner-showId/${bannerId}`)
            .then((response) => {
                if (response.data.ResponseCode === 1) {
                    const { name, bannder_link, is_active, image } = response.data.ResponseData;
                    setFormData({
                        name: name || '',
                        bannder_link: bannder_link || '',
                        is_active: is_active || '',
                        image: image || null
                    });
                } else {
                    toast.error(response.data.ResponseText);
                }
            })
            .catch((error) => {
                console.error('Error Fetching Data: ', error);
                toast.error('Error Fetching Data');
            });
    }, [bannerId]);

    const handleSubmit = async (e) => {
        e.preventDefault();


        const formDataForSubmit = new FormData();
        formDataForSubmit.append('name', formData.name);
        formDataForSubmit.append('bannder_link', formData.bannder_link);
        formDataForSubmit.append('image', formData.image);
        formDataForSubmit.append('is_active', formData.is_active);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/update-banner/${bannerId}`,
                formDataForSubmit,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.ResponseCode) {
                toast.success('Banner Updated Successfully');
                navigate('/viewbanner');
            } else {
                toast.error(response.data.ResponseText);
            }
        } catch (error) {
            console.error('Error updating Banner:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: name === 'image' ? files[0] : value });
    };

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Update Banner Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewbanner">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center align-items-center">
                                <div className="card">
                                    <div className="body  py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col  lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Banner Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='name' id="name" className="form-control" value={formData.name} placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col  lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Banner Image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name="image" id="image" accept="image/*" className="form-control" placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} />
                                                            {formData.image &&
                                                                <img src={formData.image} alt="Selected Image" style={{ width: '170px', height: '100px', marginTop: '10px' }} />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col  lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Slider URL:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='bannder_link' id="bannder_link" className="form-control" value={formData.bannder_link} placeholder="Enter your Product URL " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col  lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Status:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div class="form-group">
                                                            <select class="form-control show-tick ms select2" id='is_active' name="is_active" onChange={(e) => handleInputChange(e)} value={formData.is_active} data-placeholder="Select" required>
                                                                <option value="">-- Select Status --</option>
                                                                <option value={1}>
                                                                    Active
                                                                </option>
                                                                <option value={2}>
                                                                    Deactive
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary  waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Editbanner;
