import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

function Inquirylist() {
  const [postinquiryrequest, setpostinquiryrequest] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchdata();
  }, [])
  const fetchdata = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/Inquiry-list`);
      setpostinquiryrequest(response.data.ResponseData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentpostinquiryrequest = postinquiryrequest.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDetailClick = (productId) => {
    localStorage.setItem('postinquiryrequestId', productId);
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
    return formattedDate;
  }

  return (
    <>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Inquirye List</h2>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Product Image</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Date</th>
                            <th scope="col">Readable</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentpostinquiryrequest.map((productdata, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td>{productdata.name}</td>
                              <td><img src={productdata.product_image} alt="image" style={{ width: '70px', height: '70px' }} /></td>
                              <td>{productdata.full_name}</td>
                              <td>{productdata.phone}</td>
                              <td>{formatDate(productdata.created_at)}</td>
                              <td>
                                {productdata.is_read == 1 ? (
                                  <span className='text-success fw-bolder'>Yes</span>
                                ) : (
                                  <span className='text-danger fw-bolder'>No</span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav aria-label="Page navigation example ">
          <ul className="pagination justify-content-end">
            {Array.from({ length: Math.ceil(postinquiryrequest.length / itemsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section >
    </>
  )
}

export default Inquirylist