import React, { useEffect, useState } from 'react'
import { Col, Row, Modal, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function Editproduct() {
    const { productId } = useParams();
    const [key, setKey] = useState('details');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [keyword, setKeyword] = useState([]);
    const [image, setImage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [productdetails, setProductdetails] = useState([]);
    const [specification, setSpecification] = useState([]);
    const [activeTab, setActiveTab] = useState('details');
    const [showsubcategory, setShowsubcategory] = useState(false);
    const [showoffer, setshowOffer] = useState(false);
    const [homefeed, setHomefeed] = useState(false);
    const [showHome, setShowHome] = useState('');
    const [showSub, setShowSub] = useState('');
    const navigate = useNavigate();
    const [shopOffer, setShopOffer] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        category_id: "",
        subcategory_id: "",
        price: "",
        unit_type: "",
        discounted_price: '',
        product_code: '',
        discount: "",
        description: '',
        is_active: "",
        show_home: "",
        home_feed_id: '',
        home_sdate: '',
        Home_edate: '',
        product_image: null,
        show_subcat: '',
        subcat_feed_id: '',
        subcat_sdate: '',
        subcat_edate: '',
        offershow: '',
        offer_feed_id: '',
        offer_sdate: '',
        offer_edate: '',
        show_topproducts: '',
    });

    const handleShowHomeChange = (event) => {
        setShowHome(event.target.value);
    };

    const handleshowSub = (event) => {
        setShowSub(event.target.value);
    };

    const handleShowOfferChange = (event) => {
        setShopOffer(event.target.value);
    };


    const handleInputChange = (e) => {
        const { name, value, files } = e.target;

        // For regular input fields
        if (!files) {
            setFormData({
                ...formData,
                [name]: value
            });
        } else {
            // For file input fields (like images)
            setFormData({
                ...formData,
                [name]: files[0]
            });
        }
    };

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    const openModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage('');
        setShowModal(false);
    };

    const handleSaveAndNext = (event) => {
        event.preventDefault();

        let nextTab;

        switch (activeTab) {
            case 'details':
                nextTab = 'image';
                break;
            case 'image':
                nextTab = 'specification';
                break;
            case 'specification':
                nextTab = 'keyword';
                break;
            case 'keyword':
                nextTab = 'details';
                break;
            default:
                nextTab = 'details';
        }

        setActiveTab(nextTab);
    };


    const handleBack = (event) => {
        event.preventDefault(); // Prevent the default behavior of the button

        let previousTab;

        switch (activeTab) {
            case 'details':
                previousTab = 'keyword';
                break;
            case 'image':
                previousTab = 'details';
                break;
            case 'specification':
                previousTab = 'image';
                break;
            case 'keyword':
                previousTab = 'specification';
                break;
            default:
                previousTab = 'details';
        }

        setActiveTab(previousTab);
    };

    // Fetching data on component mount
    useEffect(() => {
        fetchKeyword();
        fetchImage();
        fetchProductDetails();
        fetchSpecification();
        fetchHomeFeed();
        fetchSubcategory();
        fetchOffer();

    }, [productId]);
    useEffect(() => {
        categorydata();
        subcategorydata();
        unitTypesdata();
    }, [])

    const categorydata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/categorylist`);
            setCategoryData(response.data.ResponseData);
        } catch (error) {
            console.error('Error fetching categories: ', error);
        }
    };

    const subcategorydata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/subcategory-list`);
            setSubcategoryData(response.data.ResponseData);
        } catch (error) {
            console.error('Error fetching subcategories: ', error);
        }
    };

    const unitTypesdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/unit-list`);
            setUnitTypes(response.data.ResponseData);
        } catch (error) {
            console.error('Error fetching subcategories: ', error);
        }
    };
    const fetchKeyword = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-keyword-list/${productId}`);
            setKeyword(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product keywords: ", error);
        }
    }

    // Fetch image data
    const fetchImage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-image-list/${productId}`);
            setImage(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product images: ", error);
        }
    }


    // Fetch specification data
    const fetchSpecification = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-variant-list/${productId}`);
            setSpecification(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product specifications: ", error);
        }
    }

    // Fetch home feed data
    const fetchHomeFeed = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/homefeed-list`);
            setHomefeed(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching home feed data: ", error);
        }
    }

    // Fetch subcategory data
    const fetchSubcategory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/subcategoriesfeed-list`);
            setShowsubcategory(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching subcategory data: ", error);
        }
    }

    // Fetch offer data
    const fetchOffer = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/offerzonefeed-list`);
            setshowOffer(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching offer data: ", error);
        }
    }

    // Fetch product details
    const fetchProductDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/productedit/${productId}`);
            if (response.data.ResponseCode === 1) {
                setFormData(response.data.ResponseData[0]);
                if (response.data.ResponseData[0].show_home === '1') {
                    setShowHome('1');
                }
                if (response.data.ResponseData[0].show_subcat === '1') {
                    setShowSub('1');
                }
                if (response.data.ResponseData[0].offershow === '1') {
                    setShopOffer('1');
                }
            } else {
                console.error("API Error: ", response.data.ResponseText);
            }
        } catch (error) {
            console.error("Error fetching product details: ", error);
        }
    }

    // const saveProduct = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_API}/product-update/${productId}`, formData);
    //         if (response.data.ResponseCode === 1) {
    //             setActiveTab('image');
    //         } else {
    //             console.error('Error:', response.data.ResponseText);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };
    const saveProduct = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            // const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('category_id', formData.category_id);
            formDataToSend.append('subcategory_id', formData.subcategory_id);
            formDataToSend.append('unit_type', formData.unit_type);
            formDataToSend.append('price', formData.price || '');
            formDataToSend.append('discounted_price', formData.discounted_price || '');
            formDataToSend.append('discount', formData.discount || 0); // Assuming discount should default to 0 if not provided
            formDataToSend.append('description', formData.description);
            formDataToSend.append('is_active', formData.is_active);
            formDataToSend.append('show_home', formData.show_home || ''); // Assuming show_home should default to an empty string if not provided
            formDataToSend.append('home_feed_id', formData.home_feed_id || '');
            formDataToSend.append('home_sdate', formData.home_sdate || '');
            formDataToSend.append('Home_edate', formData.Home_edate || '');
            formDataToSend.append('product_image', formData.product_image);
            formDataToSend.append('show_subcat', formData.show_subcat || '');
            formDataToSend.append('subcat_feed_id', formData.subcat_feed_id || '');
            formDataToSend.append('subcat_sdate', formData.subcat_sdate || '');
            formDataToSend.append('subcat_edate', formData.subcat_edate || '');
            formDataToSend.append('offershow', formData.offershow || '');
            formDataToSend.append('offer_feed_id', formData.offer_feed_id || '');
            formDataToSend.append('offer_sdate', formData.offer_sdate || '');
            formDataToSend.append('offer_edate', formData.offer_edate || '');
            formDataToSend.append('product_code', formData.product_code || '');
            formDataToSend.append('show_topproducts', formData.show_topproducts || '');
            const response = await axios.post(`${process.env.REACT_APP_API}/product-update/${productId}`, formDataToSend);
            if (response.data.ResponseCode === 1) {
                setActiveTab('image');
            } else {
                console.error('Error:', response.data.ResponseText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const updateStatus = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/productupdate-status/${productId}`, {
                status: formData.status,
                comment: formData.comment,
            });

            if (response.data.ResponseCode === 1) {
                // navigate("/viewproductlist");
            }
        } catch (error) {
            console.error("Error updating status: ", error);
        }
    };

    const [showImageTable, setShowImageTable] = useState(false);
    const handleShowImageTable = () => {
        setShowImageTable(true);
    };
    const handleCloseImageTable = () => setShowImageTable(false);
    const handleImageUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', productId);
            form.append('image_url', selectedImage);
            form.append('status', 1);
            form.append('comment', 'good');

            const response = await axios.post(
                `${process.env.REACT_APP_API}/add-image`,
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Product image added successfully!');
                fetchImage();

            } else {
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };


    const handledeleteImage = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/image-delete/${id}`
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Image deleted successfully');
                setImage((prevImages) => prevImages.filter((image) => image.id !== id));
                toast.success('Product image deleted successfully!');
            } else {
                toast.error('Error deleting image');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Specifications //
    const [showProductLinkTable, setShowProductLinkTable] = useState(false);
    const handleShowProductLinkTable = () => {
        setShowProductLinkTable(true);
    };
    const handleCloseProductLinkTable = () => setShowProductLinkTable(false);
    const handleSpecification = async () => {
        try {
            const form = new FormData();
            form.append('product_id', productId);
            form.append('variant_name', formData.variant_name);
            form.append('variant', formData.variant);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/add-validator`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                console.log('Product image added successfully:', response.data.ResponseData);
                toast.success('Product Specification Added successfully!');
                fetchSpecification();
            } else {
                console.error('Error adding product image:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };

    const handleDeleteVariant = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/validator-delete/${id}`,
            );

            if (response.data.ResponseCode === 1) {
                console.log('Variant deleted successfully:', response.data.ResponseText);
                toast.success('Product Specification deleted successfully!');
                setSpecification((prevVariants) => prevVariants.filter((variant) => variant.id !== id));
                setGeneralTabFilled(true);

            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };

    // Keyword //
    const [showKeywordTable, setShowKeywordTable] = useState(false);
    const handleShowKeywordTable = () => {
        setShowKeywordTable(true);
    };
    const handleCloseKeywordTable = () => setShowKeywordTable(false);
    const handleKeywordUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', productId);
            form.append('keyword', formData.keyword);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/add-keyworld`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Product Keyword added successfully!');
                fetchKeyword();
            } else {
                toast.error('Failed to add product. Please try again.');
                console.error('Error adding product image:', response.data.ResponseText);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        }
    };
    const handleDeleteKeyword = async (productskeyword_id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/delete-keyworld/${productskeyword_id}`,
            );

            if (response.data.ResponseCode === 1) {
                setKeyword((prevKeywords) => prevKeywords.filter((keyword) => keyword.productskeyword_id !== productskeyword_id));
                setGeneralTabFilled(true);
                toast.success('Product Keyword deleted successfully!');
            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };

    return (
        <>
            <section className="content ">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Product Details</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewproductlist">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <form action="" onSubmit={updateStatus}>
                                        <Tabs id="controlled-tab-example" activeKey={activeTab} onSelect={(k) => setKey(k)} className="mb-3"  >
                                            <Tab eventKey="details" title="Details" onSelect={() => setGeneralTabFilled(false)}>
                                                <div className="body clientbody w-lg-100 mt-4" >
                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label className="form-label text-nowrap " >Product Name :-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <input type="text" className="form-control " onChange={(e) => handleInputChange(e)} value={formData.name} name='name' />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label text-nowrap "> Category :- </label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <select name="category_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => handleInputChange(e)} data-placeholder="Select" value={formData.category_id} required>
                                                                        <option value="">--Select category --</option>
                                                                        {categoryData &&
                                                                            categoryData.map((v, index) => {
                                                                                return (
                                                                                    <option value={v.id} key={index.id}>
                                                                                        {v.category_name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap ">Sub Category :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="subcategory_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => handleInputChange(e)} data-placeholder="Select" value={formData.subcategory_id} required>
                                                                        <option value="">
                                                                            --select category--
                                                                        </option>
                                                                        {subcategoryData &&
                                                                            subcategoryData.map((v, index) => {
                                                                                return (
                                                                                    <option value={v.id} key={index.id}>
                                                                                        {v.subcategory_name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label  ">ProductCode:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="number" value={formData.product_code} onChange={handleInputChange} className="form-control " name='product_code' />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap me-2 ">Unit :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="unit_type" id="templateId" className="form-control show-tick ms select2"
                                                                        onChange={(e) => handleInputChange(e)} value={formData.unit_type} data-placeholder="Select" required >
                                                                        <option>--select unit--</option>
                                                                        {unitTypes.map((unit, index) => (
                                                                            <option key={index} value={unit.unit_type_id}>
                                                                                {unit.unit_type}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label ">Price:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="number" className="form-control" onChange={handleInputChange} value={formData.price} name='price' />
                                                                </div>
                                                            </Row>
                                                        </div>

                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center'>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap me-2">Discount Price :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <input type="" className="form-control" onChange={handleInputChange} value={formData.discounted_price} name='discounted_price' />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label" >Discount:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="number" value={formData.discount} onChange={handleInputChange} className="form-control" name='discount' />
                                                                </div>
                                                            </Row>
                                                        </div>

                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center'>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap me-2">status :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="is_active" id="templateId" className="form-control show-tick ms select2" data-placeholder="Select" onChange={(e) => handleInputChange(e)} value={formData.is_active} required >
                                                                        <option value="">select Status</option>
                                                                        <option value="1">Active</option>
                                                                        <option value="2">Deactive</option>
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label className="form-label "> Discription:-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <textarea type="text" className="form-control" value={formData.description} onChange={handleInputChange} name='description' />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label ">Show To Products:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <select name="show_topproducts" id="show_topproducts" className="form-control show-tick ms select2" data-placeholder="Select" onChange={(e) => handleInputChange(e)} value={formData.show_topproducts} required >
                                                                        <option value="">--select show to products--</option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="2">No</option>
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label">Select Image:- </label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="file" name='product_image' onChange={handleInputChange} style={{ width: '100%' }} />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>
                                                    {formData.product_image &&
                                                        <img src={formData.product_image} alt="Product Image" style={{ width: '120px', height: '70px', marginTop: '10px' }} />
                                                    }
                                                    <div className='ps-3'>
                                                        <a onClick={() => openModal(formData.product_image)}> View</a>
                                                    </div>
                                                    <Modal show={showModal} onHide={closeModal}>
                                                        <Modal.Body>
                                                            <img src={selectedImage} alt="Large View" />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={closeModal}>
                                                                Close
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    <input type="hidden" className="" value={formData.slug} name='slug' />

                                                    <div className='border rounded my-4'>
                                                        <div className='p-3'>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='row d-flex align-items-center'>
                                                                        <div className='col-4'>
                                                                            <label className="form-label">Show home:-</label>
                                                                        </div>
                                                                        <div className="form-group col-8">
                                                                            <select
                                                                                name="show_home"
                                                                                id="show_home"
                                                                                className="form-control show-tick ms select2"
                                                                                onChange={(event) => {
                                                                                    handleShowHomeChange(event);
                                                                                    handleInputChange(event);
                                                                                }}
                                                                                value={(showHome === '1' || formData.show_home === 1) ? '1' : formData.show_home || ''}
                                                                            >
                                                                                <option value="">-- select home --</option>
                                                                                <option value='1'>Yes</option>
                                                                                <option value='2'>No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {(showHome === '1' || formData.show_home == 1) && (
                                                                    <div className='col-6'>
                                                                        <div className='row d-flex align-items-center'>
                                                                            <div className='col-3'>
                                                                                <label className="form-label text-nowrap me-2">Select feed :-</label>
                                                                            </div>
                                                                            <div className="form-group col-9">
                                                                                <select name="home_feed_id" id="home_feed_id" onChange={handleInputChange} className="form-control show-tick ms select2" value={formData.home_feed_id}>
                                                                                    <option value="">--select feed--</option>
                                                                                    {homefeed &&
                                                                                        homefeed.map((v) => {
                                                                                            return (
                                                                                                <option value={v.id} key={v.id}>
                                                                                                    {v.feedname}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {(showHome === '1' || formData.show_home == 1) && (
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-4'>
                                                                                    <label >Start Date:-</label>
                                                                                </div>
                                                                                <div className="form-group col-8">
                                                                                    <input className="form-control" value={formData.home_sdate} onChange={handleInputChange} type="date" name='home_sdate' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <Row className='d-flex align-items-center'>
                                                                                <div className='col-3'>
                                                                                    <label className="form-label text-nowrap me-2"> End Date :-</label>
                                                                                </div>
                                                                                <div className="form-group col-9">
                                                                                    <input className="form-control" type="date" value={formData.Home_edate} onChange={handleInputChange} name='Home_edate' />
                                                                                </div>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className='border rounded my-4'>
                                                        <div className='p-3'>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='row d-flex align-items-center'>
                                                                        <div className='col-4'>
                                                                            <label className="form-label">Show Subcategory:-</label>
                                                                        </div>
                                                                        <div className="form-group col-8">
                                                                            <select
                                                                                name="show_subcat"
                                                                                id="show_subcat"
                                                                                className="form-control show-tick ms select2"
                                                                                onChange={(event) => {
                                                                                    handleshowSub(event);
                                                                                    handleInputChange(event);
                                                                                }}
                                                                                value={(showSub === '1' || formData.show_subcat === 1) ? '1' : formData.show_subcat || ''}

                                                                            >
                                                                                <option value="">-- select subcategory --</option>
                                                                                <option value='1'>Yes</option>
                                                                                <option value='2'>No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {(showSub === '1' || formData.show_subcat == 1) && (
                                                                    <div className='col-6'>
                                                                        <Row className='d-flex align-items-center'>
                                                                            <div className='col-3'>
                                                                                <label className="form-label text-nowrap me-2"> Select feed :-</label>
                                                                            </div>
                                                                            <div className="form-group col-9">
                                                                                <select name="subcat_feed_id" id="subcat_feed_id" onChange={handleInputChange} className="form-control show-tick ms select2" value={formData.subcat_feed_id}>
                                                                                    <option value="">--select feed--</option>
                                                                                    {showsubcategory &&
                                                                                        showsubcategory.map((v) => {
                                                                                            return (
                                                                                                <option value={v.id} key={v.id}>
                                                                                                    {v.feedname}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                </select>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {(showSub === '1' || formData.show_subcat == 1) && (
                                                                <Row>
                                                                    <div className='col-6'>
                                                                        <Row className='d-flex align-items-center '>
                                                                            <div className='col-4'>
                                                                                <label className="form-label ">Start Date:-</label>
                                                                            </div>
                                                                            <div className="form-group col-8">
                                                                                <input className="form-control" type="date" value={formData.subcat_sdate} onChange={handleInputChange} name='subcat_sdate' />
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <Row className='d-flex align-items-center'>
                                                                            <div className='col-3'>
                                                                                <label className="form-label text-nowrap me-2"> End Date :-</label>
                                                                            </div>
                                                                            <div className="form-group col-9">
                                                                                <input className="form-control" type="date" value={formData.subcat_edate} onChange={handleInputChange} name='subcat_edate' />
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className='border rounded my-4'>
                                                        <div className='p-3'>
                                                            <Row>
                                                                <div className='col-6'>
                                                                    <Row className='d-flex align-items-center '>
                                                                        <div className='col-4'>
                                                                            <label className="form-label ">Show offer:-</label>
                                                                        </div>
                                                                        <div className="form-group col-8">
                                                                            <select name="offershow" id="offershow" className="form-control show-tick ms select2"
                                                                                onChange={(event) => {
                                                                                    handleShowOfferChange(event); // Pass the event object here
                                                                                    handleInputChange(event); // Also pass the event object here if needed
                                                                                }}
                                                                                value={(shopOffer === '1' || formData.offershow === 1) ? '1' : formData.offershow || ''}
                                                                            >
                                                                                <option value="">-- select offer --</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="2">No</option>
                                                                            </select>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                                {(shopOffer === '1' || formData.offershow == 1) && (
                                                                    <div className='col-6'>
                                                                        <Row className='d-flex align-items-center'>
                                                                            <div className='col-3'>
                                                                                <label className="form-label text-nowrap me-2"> select feed :-</label>
                                                                            </div>
                                                                            <div className="form-group col-9">
                                                                                <select name="offer_feed_id" id="offer_feed_id" onChange={handleInputChange} className="form-control show-tick ms select2" value={formData.offer_feed_id}>
                                                                                    <option value="">
                                                                                        --select feed--
                                                                                    </option>
                                                                                    {showoffer &&
                                                                                        showoffer.map((v) => {
                                                                                            return (
                                                                                                <option value={v.id} key={v.id}>
                                                                                                    {v.feedname}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                </select>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                )}
                                                            </Row>
                                                            {(shopOffer === '1' || formData.offershow == 1) && (
                                                                <Row>
                                                                    <div className='col-6'>
                                                                        <Row className='d-flex align-items-center '>
                                                                            <div className='col-4'>
                                                                                <label className="form-label ">Start Date:-</label>
                                                                            </div>
                                                                            <div className="form-group col-8">
                                                                                <input className="form-control" onChange={handleInputChange} type="date" name='offer_sdate' value={formData.offer_sdate} />
                                                                            </div>
                                                                        </Row>
                                                                    </div>

                                                                    <div className='col-6'>
                                                                        <Row className='d-flex align-items-center'>
                                                                            <div className='col-3'>
                                                                                <label className="form-label text-nowrap me-2"> End Date :-</label>
                                                                            </div>
                                                                            <div className="form-group col-9">
                                                                                <input className="form-control" onChange={handleInputChange} type="date" name='offer_edate' value={formData.offer_edate} />
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>



                                                    <div className='d-flex justify-content-end'>
                                                        <button type='submit' className="  btn btn-primary   ps-4 pe-4 " onClick={saveProduct} >Save & Next</button>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="image" title="Image">
                                                <div >
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card body">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5 className="card-title"> Image</h5>
                                                                    <Button className="btngray1"
                                                                        onClick={handleShowImageTable}
                                                                    >Add Image</Button>
                                                                    <Modal
                                                                        size="md"
                                                                        show={showImageTable}
                                                                        onHide={handleCloseImageTable}
                                                                        aria-labelledby="example-modal-sizes-title-sm"
                                                                    >
                                                                        <Modal.Header >
                                                                            <Modal.Title id="example-modal-sizes-title-sm">
                                                                                Upload  Image
                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <img src="" alt="" style={{ maxWidth: '100%' }} />
                                                                            <input type="file" name="image_url" id="image_url"
                                                                                onChange={handleImageChange}
                                                                            />
                                                                            <Button onClick={() => { handleImageUpload(); handleCloseImageTable(); }}
                                                                                className="m-2 btngray px-3">  Upload
                                                                            </Button>
                                                                        </Modal.Body>
                                                                    </Modal>
                                                                </div>
                                                                <div className="m-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped table-hover  dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">NO</th>
                                                                                    <th scope="col">Image</th>
                                                                                    <th scope="col">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {image.map((image, index) => (
                                                                                    <tr key={index}>
                                                                                        <td scope="col">{index + 1}</td>
                                                                                        <td scope="col">
                                                                                            <img src={image.image_url} alt="" style={{ maxWidth: '100px' }} />
                                                                                        </td>
                                                                                        <td >
                                                                                            <button className="btn btn-danger ms-2 " onClick={() => handledeleteImage(image.id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                        <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                        <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext} >Save & Next</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="specification" title="Specification" >
                                                <div>
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card body">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5 className="card-title"> Specification</h5>
                                                                    <Button className="btngray1"
                                                                        onClick={handleShowProductLinkTable}
                                                                    >Add Specification</Button>
                                                                    <Modal show={showProductLinkTable} onHide={handleCloseProductLinkTable}>
                                                                        <Modal.Header >
                                                                            <Modal.Title className='text-center w-100' >Specification</Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <Form>
                                                                                {/* <Form.Group className="mb-3" controlId="attributeId">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <Form.Label className='me-3 '>Specification:-</Form.Label>
                                                                                        <input type="text" name='variant_name' className='form-control'
                                                                                            onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })} />
                                                                                    </div>
                                                                                </Form.Group>
                                                                                <Form.Group
                                                                                    controlId="attributeText"
                                                                                >
                                                                                    <div className="d-flex  justify-content-between">

                                                                                        <Form.Label className='me-5'>variant:-</Form.Label>
                                                                                        <Form.Control as="input"
                                                                                            type="text" name='variant'
                                                                                            onChange={(e) => setFormData({ ...formData, variant: e.target.value })}
                                                                                        />
                                                                                    </div>
                                                                                </Form.Group> */}
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap " >Specification :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-9">
                                                                                        <input type="text" name='variant_name' className='form-control'
                                                                                            onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })} />
                                                                                    </div>
                                                                                </Row>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap " >variant :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-9">
                                                                                        <input type="text" name='variant' className='form-control'
                                                                                            onChange={(e) => setFormData({ ...formData, variant: e.target.value })} />
                                                                                    </div>
                                                                                </Row>
                                                                            </Form>
                                                                        </Modal.Body>
                                                                        <Modal.Footer className='d-flex justify-content-center'>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    handleSpecification();
                                                                                    handleCloseProductLinkTable();
                                                                                }}
                                                                                className='btngray px-5'>
                                                                                Save
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </div>

                                                                <div className="m-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped table-hover  dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">NO</th>
                                                                                    <th scope="col">Specification</th>
                                                                                    <th scope="col">Text</th>
                                                                                    <th scope="col" style={{ marginLeft: "200px" }}>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {specification.map((variant, index) => (
                                                                                    <tr>
                                                                                        <td scope="col">{index + 1}</td>
                                                                                        <td scope="col">{variant.variant_name}</td>
                                                                                        <td scope="col">{variant.variant}</td>
                                                                                        <td scope="col" style={{ marginLeft: "200px" }}>

                                                                                            <button className="btn btn-danger ms-2 " onClick={() => handleDeleteVariant(variant.id)}
                                                                                            > <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                        <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                        <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext} >Save & Next</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="keyword" title="Keyword" >
                                                <div >
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card body">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h5 className="card-title">Keyword</h5>
                                                                    <Button className="btngray1"
                                                                        onClick={handleShowKeywordTable}
                                                                    >Add Keyword</Button>
                                                                    {/* modal */}
                                                                    <Modal
                                                                        size="md"
                                                                        show={showKeywordTable}
                                                                        onHide={handleCloseKeywordTable}
                                                                        aria-labelledby="example-modal-sizes-title-sm"
                                                                    >
                                                                        <Modal.Header >
                                                                            <Modal.Title id="example-modal-sizes-title-sm">
                                                                                Enter keyword
                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <input type="text" name='keyword' placeholder='Enter keyword ' className='form-control' onChange={(e) => setFormData({ ...formData, keyword: e.target.value })} />
                                                                            <Button
                                                                                onClick={() => {
                                                                                    handleKeywordUpload();
                                                                                    handleCloseKeywordTable();
                                                                                }}
                                                                                className="mt-3 px-5 btngray ">
                                                                                Save
                                                                            </Button>
                                                                        </Modal.Body>
                                                                    </Modal>
                                                                </div>
                                                                <div className="m-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped table-hover  dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">NO</th>
                                                                                    <th scope="col">Keyword</th>
                                                                                    <th scope="col">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {keyword.map((keyword, index) => (
                                                                                    <tr>
                                                                                        <td scope="col">{index + 1}</td>
                                                                                        <td scope="col">{keyword.keyword}</td>
                                                                                        <td scope="col">
                                                                                            <button className="btn btn-danger ms-2 " onClick={() => handleDeleteKeyword(keyword.productskeyword_id)}
                                                                                            > <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                                <Row className='d-flex align-items-center col-6 '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap ">Select status :-</label>
                                                                    </div>
                                                                    <div className="form-group col-6">
                                                                        <select name="status" id="" class="form-control show-tick ms select2" value={formData.status} onChange={(e) => handleInputChange(e)}>
                                                                            <option value="1">Publish</option>
                                                                            <option value="2">Inreview</option>
                                                                            <option value="3">Unpublish</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>

                                                                <Row className='d-flex align-items-center col-6 '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap ">Comment :-</label>
                                                                    </div>
                                                                    <div className="form-group col-6">
                                                                        <textarea className='form-control' name='comment' onChange={(e) => handleInputChange(e)}></textarea>
                                                                    </div>
                                                                </Row>
                                                                <div className='d-flex  justify-content-end mt-4 me-3'>
                                                                    <button className=" btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                    <button className=" btn btn-primary   ps-4 pe-4 " onClick={() => navigate('/viewproductlist')} >Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Editproduct