import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './subcat.module.css';

function Addsubcat() {
    const navigate = useNavigate();
    const [homecategory, setHomecategory] = useState({
        fieldname: "",
        orderby: "",
        is_active: "",
        feed_type: "",
        location: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setHomecategory({
            ...homecategory,
            [name]: value,
        });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/add-subcatfeed`;
        try {
            const response = await axios.post(apiUrl, homecategory);
            if (response.data.ResponseCode) {
                toast.success("Subcat Feed added successfully");
                navigate("/subcatfeedlist");
            }
        } catch (error) {
            console.error("Error adding Subcat Feed", error);
        }
    };

    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Subcategory Feed Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/subcatfeedlist">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Vertical Layout */}
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >
                                <div className="card">
                                    <div className="body  py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row lg={2} xl={2} xxl={2}>
                                                    <Col md={2} className="pt-2">
                                                        <label htmlFor="email_address" className="text-nowrap ">Feed name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="add your feed Name" id="Name" name="fieldname" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Feed type:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="add your feed type" id="Name" name="feed_type" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Order by:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="add your order" id="Name" name="orderby" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="location">Location</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select className="form-control show-tick ms select2" id="location" required name="location" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                <option value="">--select location--</option>
                                                                <option value="1">Home</option>
                                                                <option value="3">Offer Zone</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>

                                          

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="is_active">Status</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                <option value="">-- Select Status --</option>
                                                                <option value="1">Active</option>
                                                                <option value="2">Deactive</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary  waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Addsubcat