import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Banner.module.css';

function Addbanner() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        bannder_link: '',
        image: null,
        is_active: ''
    });

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            const imageFile = e.target.files[0];
            setFormData({ ...formData, image: imageFile || null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('image', formData.image);
        formDataToSend.append('bannder_link', formData.bannder_link);
        formDataToSend.append('is_active', formData.is_active);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/add-banner`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.ResponseCode) {
                toast.success('Banner added successfully');
                navigate('/viewbanner');
            }
        } catch (error) {
            console.error('Error adding banner', error);
            toast.error('Error adding banner');
        }
    };

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Banner Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewbanner">
                                    <button className="btn btn-primary float-right justify-content-end" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center align-items-center">
                                <div className="card">
                                    <div className="body py-5 mt-2">
                                        <div className="d-flex  justify-content-center ">
                                            <form method="post" className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="name" className="text-nowrap ">Banner Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name="name" id="name" className="form-control" placeholder="Enter your banner name" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}  >
                                                        <label htmlFor="password" className="text-nowrap">Banner image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group pt-2">
                                                            <input type="file" name="image" id="password" accept="image/*" placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="banner_link" className="text-nowrap ">Banner URL:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name="bannder_link" id="banner_link" className="form-control" placeholder="Enter your Product URL" onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="is_active" className="text-nowrap ">Status:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                <option value="">-- Select Status --</option>
                                                                <option value="1">Active</option>
                                                                <option value="2">Deactive</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Addbanner;
