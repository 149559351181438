import React, { useEffect, useState } from 'react'
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function Postinuiry() {
    const navigate = useNavigate();
    const { postinquiryid } = useParams();
    const [detail, setDetail] = useState([]);




    useEffect(() => {
        fetchdetail();
    }, [postinquiryid]);

    const fetchdetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/postinquiry-details/${postinquiryid}`);
            if (response.data.ResponseCode == 1) {
                setDetail(response.data.ResponseData[0]);
            } else {
                console.error("API Error: ", response.data.ResponseText);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }


    return (
        <>
            <section className="content ">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Postinquiry Detail</h2>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/postinquirylist">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="">
                                        <div className="body clientbody w-lg-100 mt-4" >
                                            <div className='productmargin d-flex flex-column m-3 pt-3'>

                                                <Row className='d-flex align-items-center '>
                                                    <div className='col-2'>
                                                        <label className="form-label text-nowrap">Company Name :-</label>
                                                    </div>
                                                    <div className="form-group col-10">
                                                        <input type="text" className="form-control " disabled value={detail.company_name} name='name' required />
                                                    </div>
                                                </Row>

                                                <Row className='d-flex align-items-center '>
                                                    <div className='col-2'>
                                                        <label className="form-label text-nowrap">Name :-</label>
                                                    </div>
                                                    <div className="form-group col-10">
                                                        <input type="text" className="form-control" disabled value={detail.full_name} name='name' required />
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label text-nowrap">Mobile Number:- </label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <input type="number" className="form-control " disabled value={detail.mobile_number} name='price' required />
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-3'>
                                                                <label className="form-label text-nowrap me-2">Email :-</label>
                                                            </div>
                                                            <div className="form-group col-9">
                                                                <input type="email" className="form-control" disabled value={detail.email} name='discounted_price' required />
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <Row className='d-flex align-items-center '>
                                                    <div className='col-2'>
                                                        <label className="form-label text-nowrap">Comments :-</label>
                                                    </div>
                                                    <div className="form-group col-10">
                                                        <textarea type="text" className="form-control" rows="5" disabled value={detail.comments} name='name' required />
                                                    </div>
                                                </Row>

                                                <Row>

                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label text-nowrap ">City :-</label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <select name="" id="" class="form-control show-tick ms select2" disabled>
                                                                    <option value={detail.city_name}>{detail.city_name}</option>
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-3'>
                                                                <label className="form-label text-nowrap ">User Type :-</label>
                                                            </div>
                                                            <div className="form-group col-9">
                                                                <select name="" id="" class="form-control show-tick ms select2" disabled>
                                                                    <option >{detail.business_type == 1 ? "User" : "Client"}</option>
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>



                                            </div>
                                            <div className='d-flex  justify-content-end mt-3 me-3'>
                                                <button className="  btn btn-primary   ps-4 pe-4 " onClick={() => navigate('/postinquirylist')}>Save </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Postinuiry