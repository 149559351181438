import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './feedimage.module.css';

function Editfeedimage() {
    const { feedimageId } = useParams();
    const navigate = useNavigate();
    const [feedimagedata, setFeedimagedata] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        feedbanner_image: null,
        link_id: "1",
        home_feed_id: "",
    })

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/feed-list`)
            .then((response) => {
                setFeedimagedata(response.data.ResponseData);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/feedimageedit/${feedimageId}`)
            .then((response) => {
                if (response.data.ResponseCode === 1) {
                    const { name, link_id, home_feed_id, feedbanner_image } = response.data.ResponseData;
                    setFormData({
                        name: name || '',
                        link_id: link_id || '',
                        home_feed_id: home_feed_id || '',
                        feedbanner_image: feedbanner_image || '',
                    });
                } else {
                    toast.error(response.data.ResponseText);
                }
            })
            .catch((error) => {
                console.error('Error Fetching Data: ', error);
            });
    }, [feedimageId]);

    const handleInputChange = (e) => {
        if (e.target.name === 'feedbanner_image') {
            setFormData({ ...formData, feedbanner_image: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/feedimage-update/${feedimageId}`;
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("name", formData.name);
            formDataToSend.append("home_feed_id", formData.home_feed_id);
            formDataToSend.append('link_id', formData.link_id);
            formDataToSend.append("feedbanner_image", formData.feedbanner_image);
            const response = await axios.post(apiUrl, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data.ResponseCode) {
                toast.success("feedimage added successfully");
                navigate("/viewfeedimage");
            }
        } catch (error) {
            console.error("Error adding feedimage", error);
        }
    }

    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>FeedImage Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewfeedimage">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Vertical Layout */}
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                                <div className="card">
                                    <div className="body  py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Image Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='name' id="subcategory_name" value={formData.name} className="form-control " placeholder="Enter name " onChange={(e) => handleInputChange(e)} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password">Image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name="feedbanner_image" id="image" accept="image/*" className="form-control " placeholder="Upload Image" onChange={(e) => handleInputChange(e)}  />
                                                            <img src={formData.feedbanner_image} alt="feedimage" className='mt-2' width={150} height={100} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Feed:- </label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select name="home_feed_id" id="templateId" required class="form-control show-tick ms select2" value={formData.home_feed_id} onChange={(e) => handleInputChange(e)}>
                                                                <option value="">--select Feedname--</option>
                                                                {feedimagedata &&
                                                                    feedimagedata.map((v) => {
                                                                        return (
                                                                            <option value={v.id} key={v.id}>
                                                                                {v.feedname}
                                                                            </option>
                                                                        );
                                                                    })}

                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <div class="form-group">
                                                <label htmlFor="password">Status</label>
                                                <select class="form-control show-tick ms select2" name="is_active" onChange={handleInputChange} data-placeholder="Select">
                                                    <option>-- Select Status --</option>
                                                    <option value={1}>
                                                        Active
                                                    </option>
                                                    <option value={2}>
                                                        Deactive
                                                    </option>
                                                </select>
                                            </div> */}
                                                <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Editfeedimage