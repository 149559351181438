import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function Navbar() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false); // After data is loaded, set loading to false
        }, 1000); // Simulating 1 second loading time, adjust as needed
    }, []);

    const [dropdownState, setDropdownState] = useState({
        banner: false,
        vendor: false,
        product: false,
        feed: false,
        location: false,
        settings: false
    });

    const toggleDropdown = (dropdownName) => {
        setDropdownState(prevState => ({
            ...prevState,
            [dropdownName]: !prevState[dropdownName]
        }));
    };
    const isactive1 = (path) => {
        return location.pathname === path;
    };

    return (
        <>

            {loading && (
                <div className="page-loader-wrapper">
                    <div className="loader"></div>
                </div>
            )}

            <aside id="leftsidebar" className="sidebar">
                <div className="navbar-brand">
                    <button className="btn-menu ls-toggle-btn" type="button"><i className="zmdi zmdi-menu" /></button>
                    <a onClick={() => navigate('/dashboard')}><img src="../assets/images/logo.svg" width={25} alt="Aero" /><span className="m-l-10">Admin</span></a>
                </div>
                <div className="menu">
                    <ul className="list pointer">
                        <li className={isactive1('/dashboard') ? 'active1' : ''}><a onClick={() => navigate('/dashboard')} className='p-2'><i className="zmdi zmdi-home mt-3" /><span>Dashboard</span></a></li>

                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('Inquiry')} >
                                <i className="zmdi zmdi-store " /><span >Inquiry </span>
                            </a>
                            {dropdownState.Inquiry && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/postinquirylist') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/postinquirylist')} className='p-1 '>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist  '>Post Inquiry</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/inquirylist') ? 'active1' : ''}><a onClick={() => navigate('/inquirylist')} className='p-1 '><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist '>Inquiry List</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>

                        <li className={isactive1('/mettinglist') ? 'active1' : ''}><a onClick={() => navigate('/mettinglist')} ><i className="zmdi zmdi-home" /><span>Metting</span></a></li>

                        <li className={isactive1('/addclient') ? 'active1' : ''}><a onClick={() => navigate('/addclient')}><i className="zmdi zmdi-account-add" /><span>New Client</span></a></li>

                        <ul className="list-unstyled">
                            <li className='pointer'>
                                <a className='dropdown-toggle ' onClick={() => toggleDropdown('banner')} >
                                    <i className="zmdi zmdi-collection-image  " /><span >Banner </span>
                                </a>
                                {dropdownState.banner && (
                                    <ul className='list-unstyled p-0 ' >
                                        <div className='vendor'>
                                            <li className={isactive1('/viewbanner') ? 'active1' : ''}><a onClick={() => navigate('/viewbanner')} className='p-1'> <i className="zmdi zmdi-plus-circle iconsmall " /> <span className='subtitlelist'>Home Banner</span></a></li>
                                            <li className={isactive1('/Viewcategorybanner') ? 'active1' : ''}><a onClick={() => navigate('/Viewcategorybanner')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>Category Banner</span></a></li>
                                            <li className={isactive1('/viewofferzone') ? 'active1' : ''}><a onClick={() => navigate('/viewofferzone')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>Offer Banner</span></a></li>
                                        </div>
                                    </ul>
                                )}
                            </li>
                        </ul>
                        <li className={isactive1('/viewcategory') ? 'active1' : ''}>
                            <a onClick={() => navigate('/viewcategory')}><i className="zmdi zmdi-archive" /><span>Category</span></a>
                        </li>

                        <li className={isactive1('/viewsubcategory') ? 'active1' : ''}>
                            <a onClick={() => navigate('/viewsubcategory')}><i className="zmdi zmdi-view-module" /><span>Subcategory</span></a>
                        </li>

                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('vendor')} >
                                <i className="zmdi zmdi-store " /><span >Vendor </span>
                            </a>
                            {/* <i className="zmdi zmdi-chevron-right "  /> */}
                            {dropdownState.vendor && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/vendorrequest') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/vendorrequest')} className='p-1 '>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist  '>New Vendor</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/vendorelist') ? 'active1' : ''}><a onClick={() => navigate('/vendorelist')} className='p-1 '><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist '>All Vendor</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>

                        <li className={isactive1('/unitlist') ? 'active1' : ''}>
                            <a onClick={() => navigate('/unitlist')}><i className="zmdi zmdi-view-module" /><span>Unit</span></a>
                        </li>

                        <li className={isactive1('/membershiplist') ? 'active1' : ''}>
                            <a onClick={() => navigate('/membershiplist')}><i className="zmdi zmdi-view-module" /><span>Membership</span></a>
                        </li>


                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('product')}>
                                <i className="zmdi zmdi-shopping-cart-plus" /><span >Product </span>
                            </a>
                            {/* <i className="zmdi zmdi-chevron-right "  /> */}
                            {dropdownState.product && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/newprodcutlist') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/newprodcutlist')} className='p-1'>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist'>New Product</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/viewproductlist') ? 'active1' : ''}><a onClick={() => navigate('/viewproductlist')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>All Product</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>

                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('feed')} >
                                <i className="zmdi zmdi-view-headline" /><span >Ads Feed</span>
                            </a>
                            {/* <i className="zmdi zmdi-chevron-right "  /> */}
                            {dropdownState.feed && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/viewhomefeed') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/viewhomefeed')} className='p-1'>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist'>All Feed</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/viewfeedimage') ? 'active1' : ''}><a onClick={() => navigate('/viewfeedimage')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>Feed Image</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>

                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('subfeed')} >
                                <i className="zmdi zmdi-view-headline" /><span >Subcat Feeds</span>
                            </a>
                            {/* <i className="zmdi zmdi-chevron-right "  /> */}
                            {dropdownState.subfeed && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/subcatfeedlist') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/subcatfeedlist')} className='p-1'>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist'>Subcategory Feed</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/subcatfeedimage') ? 'active1' : ''}><a onClick={() => navigate('/subcatfeedimage')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>Subcatfeed Image</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>

                        <li className={isactive1('/feedbackview') ? 'active1' : ''}>
                            <a onClick={() => navigate('/feedbackview')}><i className="zmdi zmdi-comment" /><span>Feedback</span></a>
                        </li>
                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('location')} >
                                <i className="zmdi zmdi-pin" /><span >Location</span>
                            </a>
                            {/* <i className="zmdi zmdi-chevron-right "  /> */}
                            {dropdownState.location && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/viewcity') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/viewcity')} className='p-1'>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist'>City</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/viewstate') ? 'active1' : ''}><a onClick={() => navigate('/viewstate')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>State</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>
                        <li className='pointer'>
                            <a className='dropdown-toggle ' onClick={() => toggleDropdown('settings')} >
                                <i className="zmdi zmdi-settings" /><span >settings</span>
                            </a>
                            {/* <i className="zmdi zmdi-chevron-right "  /> */}
                            {dropdownState.settings && (
                                <ul className='list-unstyled'>
                                    <div className='vendor'>
                                        <li className={isactive1('/aboutus') ? 'active1' : ''}>
                                            <a onClick={() => navigate('/aboutus')} className='p-1'>
                                                <i className="zmdi zmdi-plus-circle iconsmall" />
                                                <span className='subtitlelist'>Aboutus</span>
                                            </a>
                                        </li>
                                        <li className={isactive1('/termsconditions') ? 'active1' : ''}><a onClick={() => navigate('/termsconditions')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>Termsconditions</span></a></li>
                                        <li className={isactive1('/privacypolicy') ? 'active1' : ''}><a onClick={() => navigate('/privacypolicy')} className='p-1'><i className="zmdi zmdi-plus-circle iconsmall" /><span className='subtitlelist'>Privacypolicy</span></a></li>
                                    </div>

                                </ul>
                            )}
                        </li>

                        <li className={isactive1('/userlist') ? 'active1' : ''}>
                            <a onClick={() => navigate('/userlist')}> <i className="zmdi zmdi-account" /><span>User</span></a>
                        </li>

                        <li className={isactive1('/contactlist') ? 'active1' : ''}>
                            <a onClick={() => navigate('/contactlist')}> <i className="zmdi zmdi-account" /><span>Contact List</span></a>
                        </li>

                        <li >
                            <a onClick={() => navigate('/')}><i className="zmdi zmdi-lock" /><span>Logout</span></a>
                        </li>

                    </ul>

                </div>

            </aside>




        </>
    )

}


export default Navbar