import logo from './logo.svg';
import './App.css';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Navbar from './Components/Layout/Navbar';
import Dashboard from './Components/Pages/Dashbord/Dashboard';
import SignIn from './Components/Pages/Authentication';
import SignUp from './Components/Pages/Authentication/SignUp';
import { Addbanner } from './Components/Pages/Banner';
import Viewbanner from './Components/Pages/Banner/Viewbanner';
import Editbanner from './Components/Pages/Banner/Editbanner';
import { Addmenu } from './Components/Pages/Menu';
import Viewmenu from './Components/Pages/Menu/Viewmenu';
import Editmenu from './Components/Pages/Menu/Editmenu';
import "react-toastify/dist/ReactToastify.css";
import AddHomeBanner from './Components/Pages/Homebanner/AddHomeBanner';
import ViewHomeBanner from './Components/Pages/Homebanner/ViewHomeBanner';
import EditHomebanner from './Components/Pages/Homebanner/EditHomebanner';
import Addcategory from './Components/Pages/Category/Addcategory';
import Viewcategory from './Components/Pages/Category/Viewcategory';
import Editcategory from './Components/Pages/Category/Editcategory';
import Addsubcategory from './Components/Pages/Subcategory/Addsubcategory';
import Viewsubcategory from './Components/Pages/Subcategory/Viewsubcategory';
import Editsubcategory from './Components/Pages/Subcategory/Editsubcategory';
import Addhomefeed from './Components/Pages/Homefeed/Addhomefeed';
import Viewhomefeed from './Components/Pages/Homefeed/Viewhomefeed';
import Edithomefeed from './Components/Pages/Homefeed/Edithomefeed';
import Viewfeedimage from './Components/Pages/Feedimage/Viewfeedimage';
import Addfeedimage from './Components/Pages/Feedimage/Addfeedimage';
import Editfeedimage from './Components/Pages/Feedimage/Editfeedimage';
import Viewcity from './Components/Pages/City/Viewcity';
import Addcity from './Components/Pages/City/Addcity';
import Editcity from './Components/Pages/City/Editcity';
import Viewstate from './Components/Pages/State/Viewstate';
import Addstate from './Components/Pages/State/Addstate';
import EditState from './Components/Pages/State/EditState';
import ViewTermsConditions from './Components/Pages/TermsConditions/ViewTermsConditions';
import ViewPrivacyPolicy from './Components/Pages/PrivacyPolicy/ViewPrivacyPolicy';
import Productlist from './Components/Pages/Product';
import Productlistadd from './Components/Pages/Product/Productlistadd';
import { Newprodcutlist } from './Components/Pages/Newproductlist';
import Addnewproduct from './Components/Pages/Newproductlist/Addnewproduct';
import { Viewvendorrequest } from './Components/Pages/Vendorrequest';
import Addvendorrequest from './Components/Pages/Vendorrequest/Addvendorrequest';
import { Vendorepdf } from './Components/Pages/VendorePDF';
import Addcategorybanner from './Components/Pages/Categorywisebanner';
import Editcategorybanner from './Components/Pages/Categorywisebanner/Editcategorybanner';
import Viewcategorybanner from './Components/Pages/Categorywisebanner/Viewcategorybanner';
import ViewOfferzone from './Components/Pages/OfferZone/ViewOfferzone';
import AddOfferzone from './Components/Pages/OfferZone';
import EditOfferzone from './Components/Pages/OfferZone/EditOfferzone';
import Aboutus from './Components/Pages/Aboutus/Aboutus';
import Viewfeedback from './Components/Pages/Feedback/Viewfeedback';
import Addfeedback from './Components/Pages/Feedback/Addfeedback';
import Editfeedback from './Components/Pages/Feedback/Editfeedback';
import Listvendore from './Components/Pages/VendoreList/Listvendore';
import Updatevendorelist from './Components/Pages/VendoreList/Updatevendorelist';
import Addclient from './Components/Pages/NewClient/Addclient';
import Viewuserlist from './Components/Pages/Userlist/Viewuserlist';
import Viewcontact from './Components/Pages/Contactlist/Viewcontact';
import { Viewunit } from './Components/Pages/Unit';
import { Addunit } from './Components/Pages/Unit/Addunit';
import { Editunit } from './Components/Pages/Unit/Editunit';
import { Membershiplist } from './Components/Pages/Membership';
import Addmembership from './Components/Pages/Membership/Addmembership/Addmembership';
import { Editmembership } from './Components/Pages/Membership/Editmembership';
import Editproduct from './Components/Pages/Product/Editproduct';
import { Postinquirylist } from './Components/Pages/Postinquiry';
import { Inquirylist } from './Components/Pages/Inquirylist';
import { Postinuiry } from './Components/Pages/Postinquiry/Postinuiry';
import { Mettinglist } from './Components/Pages/Metting';
import { Addmetting } from './Components/Pages/Metting/Addmetting';
import { Editmetting } from './Components/Pages/Metting/Editmetting';
import { Subcatfeedlist } from './Components/Pages/Subcatfeed';
import { Addsubcat } from './Components/Pages/Subcatfeed/Addsubcat';
import { Editsubcat } from './Components/Pages/Subcatfeed/Editsubcat';
import { Subcatimage } from './Components/Pages/Subcatfeedimage';
import { Addsubcatimgae } from './Components/Pages/Subcatfeedimage/Addsubcatimage';
import { Editsubcatimage } from './Components/Pages/Subcatfeedimage/Editsubcatimage';



function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route path='/*' element={<>
          <Navbar />
          <Routes>
            <Route path='/' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/dashboard' element={<Dashboard />} />

            <Route path='/addbanner' element={<Addbanner />} />
            <Route path='/viewbanner' element={<Viewbanner />} />

            <Route path='/editbanner/:bannerId' element={<Editbanner />} />

            <Route path='/addhomebanner' element={<AddHomeBanner />} />
            <Route path='/viewhomebanner' element={<ViewHomeBanner />} />
            <Route path='/edithomebanner/:homebannerId' element={<EditHomebanner />} />

            <Route path='/addcategory' element={<Addcategory />} />
            <Route path='/viewcategory' element={<Viewcategory />} />
            <Route path='/editcategory/:categoryId' element={<Editcategory />} />

            <Route path='/addsubcategory' element={<Addsubcategory />} />
            <Route path='/viewsubcategory' element={<Viewsubcategory />} />
            <Route path='/editsubcategory/:subcategoryId' element={<Editsubcategory />} />

            <Route path='/addhomefeed' element={<Addhomefeed />} />
            <Route path='/viewhomefeed' element={<Viewhomefeed />} />
            <Route path='/edithomefeed/:homefeedId' element={<Edithomefeed />} />

            <Route path='/addmenu' element={<Addmenu />} />
            <Route path='/viewmenu' element={<Viewmenu />} />
            <Route path='/editmenu/:menuId' element={<Editmenu />} />

            <Route path='/viewfeedimage' element={<Viewfeedimage />} />
            <Route path='/addfeedimage' element={<Addfeedimage />} />
            <Route path='/editfeedimage/:feedimageId' element={<Editfeedimage />} />

            <Route path='/Viewcity' element={<Viewcity />} />
            <Route path='/Addcity' element={<Addcity />} />
            <Route path='/editcity/:cityId' element={<Editcity />} />

            <Route path='/viewstate' element={<Viewstate />} />
            <Route path='/Addstate' element={<Addstate />} />
            <Route path='/editstate/:stateId' element={<EditState />} />

            <Route path='/termsconditions' element={<ViewTermsConditions />} />
            <Route path='/privacypolicy' element={<ViewPrivacyPolicy />} />

            <Route path='/viewproductlist' element={<Productlist />} />
            <Route path='/updateproduct/:productId' element={<Editproduct />} />
            <Route path='/addproduct' element={<Productlistadd />} />

            <Route path='/newprodcutlist' element={<Newprodcutlist />} />
            <Route path='/newproductadd/:newproductId' element={<Addnewproduct />} />

            <Route path='/vendorrequest' element={<Viewvendorrequest />} />
            <Route path='/addvendorrequest/:vendorrequestId' element={<Addvendorrequest />} />

            <Route path='/vendorpdf/:vendorrequestId' element={<Vendorepdf />} />

            <Route path='/Addcategorybanner' element={<Addcategorybanner />} />
            <Route path='/Editcategorybanner/:categorybannerId' element={<Editcategorybanner />} />
            <Route path='/Viewcategorybanner' element={<Viewcategorybanner />} />

            <Route path='/viewofferzone' element={<ViewOfferzone />} />
            <Route path='/offerzoneadd/' element={<AddOfferzone />} />
            <Route path='/offerzoneedit/:offerzoneId' element={<EditOfferzone />} />

            <Route path='/aboutus' element={<Aboutus />} />

            <Route path='/feedbackview' element={<Viewfeedback />} />
            <Route path='/feedbackadd' element={<Addfeedback />} />
            <Route path='/feedbackedit/:feedbackId' element={<Editfeedback />} />

            <Route path='/vendorelist' element={<Listvendore />} />
            <Route path='/updatevendorelist/:vendorrequestId' element={<Updatevendorelist />} />

            <Route path='/unitlist' element={<Viewunit />} />
            <Route path='/addunit' element={<Addunit />} />
            <Route path='/editunit/:unitId' element={<Editunit />} />

            <Route path='/membershiplist' element={<Membershiplist />} />
            <Route path='/addmembership' element={<Addmembership />} />
            <Route path='/editmembership/:membershipId' element={<Editmembership />} />

            <Route path='/addclient' element={<Addclient />} />
            <Route path='/userlist' element={<Viewuserlist />} />
            <Route path='/contactlist' element={<Viewcontact />} />

            <Route path='/postinquirylist' element={<Postinquirylist />} />
            <Route path='/postinquirydetail/:postinquiryid' element={<Postinuiry />} />
            <Route path='/inquirylist' element={<Inquirylist />} />

            <Route path='/mettinglist' element={<Mettinglist />} />
            <Route path='/mettingadd' element={<Addmetting />} />
            <Route path='/mettingedit/:mettingid' element={<Editmetting />} />

            <Route path='/subcatfeedlist' element={<Subcatfeedlist />} />
            <Route path='/subcatfeedadd' element={<Addsubcat />} />
            <Route path='/subcatfeededit/:subcatfeedid' element={<Editsubcat />} />

            <Route path='/subcatfeedimage' element={<Subcatimage />} />
            <Route path='/addsubcatimage' element={<Addsubcatimgae />} />
            <Route path='/editsubcatimage/:subcatimageId' element={<Editsubcatimage />} />

          </Routes>
        </>}
        />
      </Routes>
    </>
  );
}

export default App;
