import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Homefeed.module.css';

function Edithomefeed() {
  const { homefeedId } = useParams();
  const [subcategoryData, setSubcategoryData] = useState([]);
  const navigate = useNavigate();
  const [homefeed, setHomeFeed] = useState({
    feedname: "",
    orderby: "",
    is_active: "",
    feed_type: "",
    location: "",
    subcate_id: ""
  });
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "location") {
      if (value === "2") {
        // If the selected location is "Sub category" and not empty, show the subcategory dropdown
        setShowSubcategoryDropdown(true);
      } else {
        // If the selected location is not "Sub category", hide the subcategory dropdown
        setShowSubcategoryDropdown(false);
        // Reset the subcategory value
        setHomeFeed({
          ...homefeed,
          subcate_id: "",
        });
      }
    }

    // Always update the homefeed state for other inputs
    setHomeFeed({
      ...homefeed,
      [name]: value,
    });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/feeddata/${homefeedId}`)
      .then(response => {
        const data = response.data.ResponseData;
        setHomeFeed({
          feedname: data.feedname,
          orderby: data.orderby,
          is_active: data.is_active,
          feed_type: data.feed_type,
          location: data.location,
          subcate_id: data.subcate_id
        });
        // Set showSubcategoryDropdown based on the initial value of location
        setShowSubcategoryDropdown(data.location == "2");
      })
      .catch((error) => {
        console.error("Error fetching homefeed: ", error);
      });

    // Fetch subcategory data
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/subcategory-list`);
        setSubcategoryData(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching subcategories: ', error);
      }
    };
    fetchSubcategories();
  }, [homefeedId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API}/feed-update/${homefeedId}`;
    try {
      const response = await axios.post(apiUrl, homefeed);
      if (response.data.ResponseCode === 1) {
        toast.success("Homefeed Updated successfully");
        navigate("/viewhomefeed");
      }
    } catch (error) {
      console.error("Error updating homefeed", error);
    }
  };

  return (
    <>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Update Home Feed</h2>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewhomefeed">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center align-items-center">
                <div className="card">
                  <div className="body py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap">Feed name:</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Add your feed Name" id="Name" value={homefeed.feedname} name="feedname" onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap">Feed type:</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Add your feed type" id="Name" value={homefeed.feed_type} name="feed_type" onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address">Order by:</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Add your order" id="Name" value={homefeed.orderby} name="orderby" onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap">Location:</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select name="location" id="location" required value={homefeed.location} className="form-control" onChange={(e) => handleInputChange(e)}>
                                <option value="">-- Select location --</option>
                                <option value="1">Home</option>
                                <option value="2">Sub category</option>
                                <option value="3">Offer Zone</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                        {showSubcategoryDropdown && (
                          <Row className="d-flex align-items-center">
                            <Col lg={2} xl={2} xxl={2}>
                              <label htmlFor="subcate_id">Subcategory:</label>
                            </Col>
                            <Col lg={9} xl={9} xxl={9}>
                              <div className="form-group">
                                <select name="subcate_id" id="subcate_id" className="form-control show-tick ms select2" data-placeholder="Select" onChange={(e) => handleInputChange(e)} value={homefeed.subcate_id} required>
                                  <option value="">-- Select category --</option>
                                  {subcategoryData &&
                                    subcategoryData.map((v) => (
                                      <option value={v.id} key={v.id}>
                                        {v.subcategory_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active">Status:</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" value={homefeed.is_active} onChange={(e) => handleInputChange(e)} data-placeholder="Select">
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Edithomefeed;
