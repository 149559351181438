import React, { useEffect, useState } from 'react'
import { Col, Row, Modal, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function Productlistadd() {
    const navigate = useNavigate();
    const { newproductId } = useParams();
    const [key, setKey] = useState('details');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [keyword, setKeyword] = useState([]);
    const [image, setImage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [productdetails, setProductdetails] = useState([]);
    const [specification, setSpecification] = useState([]);
    const [comment, setComment] = useState('');
    const [activeTab, setActiveTab] = useState('details');
    const [unit, setUnit] = useState('');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    const openModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage('');
        setShowModal(false);
    };

    const handleSaveAndNext = () => {
        let nextTab;

        switch (activeTab) {
            case 'details':
                nextTab = 'image';
                break;
            case 'image':
                nextTab = 'specification';
                break;
            case 'specification':
                nextTab = 'keyword';
                break;
            case 'keyword':
                nextTab = 'details';
                break;
            default:
                nextTab = 'details';
        }

        setActiveTab(nextTab);
    };

    const handleBack = () => {
        let previousTab;

        switch (activeTab) {
            case 'image':
                previousTab = 'details';
                break;
            case 'specification':
                previousTab = 'image';
                break;
            case 'keyword':
                previousTab = 'specification';
                break;
            default:
                previousTab = 'details';
        }

        setActiveTab(previousTab);
    };


    useEffect(() => {
        fetchkeyword();
        fetchimage();
        fetchproductdetails();
        fetchspecification();
        fetchunit();
    }, [newproductId])
    //keyworddata-fetch
    const fetchkeyword = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-keyword-list/${newproductId}`);
            setKeyword(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product: ", error);
        }
    }

    const fetchunit = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/unitlist`);
            setUnit(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product: ", error);
        }
    }
    //imagedata-fetch
    const fetchimage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-image-list/${newproductId}`);
            setImage(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product: ", error);
        }
    }
    //productdata-fetch
    const fetchproductdetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/productedit/${newproductId}`);
            if (response.data.ResponseCode === 1) {
                setProductdetails(response.data.ResponseData[0]);
            } else {
                console.error("API Error: ", response.data.ResponseText);
            }
        } catch (error) {
            console.error("Error fetching product: ", error);
        }
    }
    //specificationdata-fetch
    const fetchspecification = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-variant-list/${newproductId}`);
            setSpecification(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product: ", error);
        }
    }

    const status = async () => {
        const newProductId = localStorage.getItem('newproductId');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/productupdate-status/${newProductId}`, {
                status: productdetails.status,
                comment: productdetails.comment,
            });

            if (response.data.ResponseCode === 1) {
                // toast.success("Status updated successfully");
                navigate("/viewproductlist");
            }
        } catch (error) {
            console.error("Error updating status: ", error);
        }
    }


    return (
        <>
            <section className="content ">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Product Detail</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/newprodcutlist">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="">
                                        <Tabs id="controlled-tab-example" activeKey={activeTab} onSelect={(k) => setKey(k)} className="mb-3" >
                                            <Tab eventKey="details" title="Details" onSelect={() => setGeneralTabFilled(false)}>
                                                <div className="body clientbody w-lg-100 mt-4" >
                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label className="form-label text-nowrap " >Product Name :-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <input type="text" className="form-control " defaultValue={productdetails.name} name='name' />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label text-nowrap "> Category :- </label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <select name="subcategory" id="subcategory" className="form-control show-tick ms select2 " >
                                                                        <option value={productdetails.category_name}>{productdetails.category_name}</option>
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap ">Sub Category :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="subcategory" id="subcategory" className="form-control show-tick ms select2" >
                                                                        <option value={productdetails.subcategory_name}>{productdetails.subcategory_name}</option>
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label  ">ProductCode:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="number" value={productdetails.product_code} className="form-control " name='price' required />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap me-2 ">Unit :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <select name="subcategory" id="subcategory" className="form-control show-tick ms select2" value={productdetails.unit_type} >
                                                                        <option value=''>--select Unit --</option>
                                                                        <option value='1'>PC</option>
                                                                        <option value='2'>Meter</option>
                                                                        <option value='3'>Unit </option>
                                                                    </select>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label ">Price:-</label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="number" className="form-control" value={productdetails.price} name='price' required />
                                                                </div>
                                                            </Row>
                                                        </div>

                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center'>
                                                                <div className='col-3'>
                                                                    <label className="form-label text-nowrap me-2">Discount Price :-</label>
                                                                </div>
                                                                <div className="form-group col-9">
                                                                    <input type="" className="form-control" value={productdetails.discounted_price} name='discounted_price' required />
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Row>

                                                    <Row className='d-flex align-items-center '>
                                                        <div className='col-2'>
                                                            <label className="form-label "> Discription:-</label>
                                                        </div>
                                                        <div className="form-group col-10">
                                                            <textarea type="text" className="form-control" value={productdetails.description} name='description' required />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className='col-6'>
                                                            <Row className='d-flex align-items-center '>
                                                                <div className='col-4'>
                                                                    <label className="form-label">Select Image:- </label>
                                                                </div>
                                                                <div className="form-group col-8">
                                                                    <input type="file" name='image' style={{ width: '100%' }} required />
                                                                </div>
                                                            </Row>
                                                        </div>

                                                    </Row>
                                                    {productdetails.product_image &&
                                                        <img src={productdetails.product_image} alt="Product Image" style={{ width: '120px', height: '70px', marginTop: '10px' }} />
                                                    }
                                                    <div className='p-2'>
                                                        <a onClick={() => openModal(productdetails.product_image)}> View</a>
                                                    </div>
                                                    <Modal show={showModal} onHide={closeModal}>
                                                        <Modal.Body>
                                                            <img src={selectedImage} alt="Large View" />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={closeModal}>
                                                                Close
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    <div className='d-flex justify-content-end'>
                                                        <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext} >Save & Next</button>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="image" title="Image">
                                                <div className='body'>
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped table-hover  dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">NO</th>
                                                                                    <th scope="col">Image</th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {image.map((image, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td><img src={image.image_url} alt="product-image" width="100px" height="100px" /></td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                    <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                    <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext} >Save & Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="specification" title="Specification" >
                                                <div className='body'>
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped table-hover  dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">NO</th>
                                                                                    <th scope="col">Speification</th>
                                                                                    <th scope="col">Text</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {specification.map((specification, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{specification.variant_name}</td>
                                                                                        <td>{specification.variant}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex  justify-content-end mt-3 me-3'>
                                                                    <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                    <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext}  >Save & Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="keyword" title="Keyword" >
                                                <div className='body'>
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-striped table-hover dataTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">NO</th>
                                                                                    <th scope="col">Keyword</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {keyword.map((keyword, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{keyword.keyword}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="d-flex align-items-center mt-5">
                                                                    <Col md="1">
                                                                        <label className="form-label text-nowrap ">Product status :-</label>
                                                                    </Col>
                                                                    <Col md="3">
                                                                        <select name="" id=""class="form-control show-tick ms select2">
                                                                            <option value="1">Active</option>
                                                                            <option value="2">Deactive</option>
                                                                        </select>
                                                                    </Col>
                                                                </div> */}


                                                                <Row className='d-flex align-items-center col-6 '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap ">Select status :-</label>
                                                                    </div>
                                                                    <div className="form-group col-6">
                                                                        <select name="" id="" class="form-control show-tick ms select2" value={productdetails.status} onChange={(e) => setProductdetails({ ...productdetails, status: e.target.value })}>
                                                                            <option value="1">Publish</option>
                                                                            <option value="2">Inreview</option>
                                                                            <option value="3">Unpublish</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>

                                                                <Row className='d-flex align-items-center col-6 '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap ">Comment :-</label>
                                                                    </div>
                                                                    <div className="form-group col-6">
                                                                        <textarea className='form-control' onChange={(e) => setProductdetails({ ...productdetails, comment: e.target.value })} ></textarea>
                                                                    </div>
                                                                </Row>

                                                                <div className='d-flex  justify-content-end mt-4 me-3'>
                                                                    <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                    <button className="btn btn-primary ps-4 pe-4" onClick={status} >Submit </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Productlistadd