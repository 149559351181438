import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
function Productlistadd() {
    const [activeTab, setActiveTab] = useState('details');
    const [productVariants, setProductVariants] = useState([]);
    const [productKeywords, setProductKeywords] = useState([]);
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [categoryData, setCategoryData] = useState([]);
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    // const userId = localStorage.getItem('userId');
    // const clientId = localStorage.getItem('clientId');
    const [formData, setFormData] = useState({
        status: '1',
        name: '',
        category_id: '',
        subcategory_id: '',
        user_id: '',
        clients_id: '',
        price: '',
        discounted_price: '',
        unit_type: '',
        description: '',
        discount: ' ',
        is_active: '',
        product_image: null,
        keyword: '',
        variant_name: '',
        variant: '',
        product_code: '',
    });
    const [id, setId] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/categorylist`);
                setCategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching categories: ', error);
            }
        };

        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/subcategory-list`);
                setSubcategoryData(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching subcategories: ', error);
            }
        };


        fetchCategories();
        fetchSubcategories();
    }, []);
    useEffect(() => {
        // Fetch data from the API
        fetch(`${process.env.REACT_APP_API}/unit-list`) // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
            .then(response => response.json())
            .then(data => {
                // Update the state with the fetched data
                setUnitTypes(data.ResponseData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    // Details //
    const handleSave = async () => {
        try {
            const missingFields = [];

            // Validation logic to check if required fields are filled
            if (!formData.name) missingFields.push('Product Name');
            if (!formData.user_id) missingFields.push('User ID');
            if (!formData.clients_id) missingFields.push('Clients ID');
            if (!formData.category_id) missingFields.push('Category ID');
            if (!formData.subcategory_id) missingFields.push('Subcategory ID');
            if (!formData.unit_type) missingFields.push('Unit Type');
            if (!formData.price) missingFields.push('Price');
            if (!formData.product_code) missingFields.push('Product Code');
            if (!formData.discounted_price) missingFields.push('Discounted Price');
            if (!formData.description) missingFields.push('Description');
            if (!selectedImage) missingFields.push('Product Image');
            if (!formData.is_active) missingFields.push('Is Active');
            if (!formData.discount) missingFields.push('Discount');

            if (missingFields.length > 0) {
                const missingFieldsMessage = `Please fill in the following fields: ${missingFields.join(', ')}.`;
                console.error(missingFieldsMessage);
                toast.error(missingFieldsMessage);
                return;
            }

            const form = new FormData();
            form.append('status', formData.status);
            form.append('name', formData.name);
            form.append('user_id', formData.user_id);
            form.append('clients_id', formData.clients_id);
            form.append('category_id', formData.category_id);
            form.append('subcategory_id', formData.subcategory_id);
            form.append('unit_type', formData.unit_type);
            form.append('price', formData.price);
            form.append('product_code', formData.product_code);
            form.append('discounted_price', formData.discounted_price);
            form.append('description', formData.description);
            form.append('product_image', selectedImage);
            form.append('is_active', formData.is_active);
            form.append('discount', formData.discount);

            const response = await axios.post(`${process.env.REACT_APP_API}/add-product`, form);

            if (response.data.ResponseCode === 1) {
                console.log('Product added successfully:', response.data.ResponseData);
                // Store the id in localStorage
                const productId = response.data.ResponseData.id;
                localStorage.setItem('product_id', productId);
                toast.success('Product added successfully!');
                setActiveTab('specification');
            } else {
                console.error('Error adding product:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };
    console.log(formData);



    // Specifications //
    const [showProductLinkTable, setShowProductLinkTable] = useState(false);
    const handleShowProductLinkTable = () => {
        setShowProductLinkTable(true);
    };
    const handleCloseProductLinkTable = () => setShowProductLinkTable(false);
    const handleSpecification = async () => {
        try {
            const form = new FormData();
            form.append('product_id', localStorage.getItem('product_id'));
            form.append('variant_name', formData.variant_name);
            form.append('variant', formData.variant);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/add-validator`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                console.log('Product image added successfully:', response.data.ResponseData);
                toast.success('Product Specification Added successfully!');
                fetchProductVariants();
            } else {
                console.error('Error adding product image:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };
    // Get Specification //
    const fetchProductVariants = async () => {
        const product_id = localStorage.getItem('product_id');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-variant-list/${product_id}`);
            setProductVariants(response.data.ResponseData);
        } catch (error) {
            console.error('Error fetching variants:', error.message);
        }
    };
    useEffect(() => {
        fetchProductVariants();
    }, []);
    // Delete Specification //
    const handleDeleteVariant = async (id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/validator-delete/${id}`,
            );

            if (response.data.ResponseCode === 1) {
                console.log('Variant deleted successfully:', response.data.ResponseText);
                toast.success('Product Specification deleted successfully!');
                setProductVariants((prevVariants) => prevVariants.filter((variant) => variant.id !== id));
                setGeneralTabFilled(true);

            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    // Update Specification //
    const [ShowEditProductTable, setShowEditProductTable] = useState(false);
    const handleshowEditProductTable = (dynamicId) => {
        setId(dynamicId); // Set the dynamic id when the modal is opened
        setShowEditProductTable(true);
    };
    const handleCloseEditProductTable = () => setShowEditProductTable(false);


    // Image upload  //
    const [showImageTable, setShowImageTable] = useState(false);
    const handleShowImageTable = () => {
        setShowImageTable(true);
    };
    const handleCloseImageTable = () => setShowImageTable(false);
    const handleImageUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', localStorage.getItem('product_id'));
            form.append('image_url', selectedImage);
            form.append('status', 1);
            form.append('comment', 'asdadad');

            const response = await axios.post(
                `${process.env.REACT_APP_API}/add-image`,
                form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                console.log('Product image added successfully:', response.data.ResponseData);
                toast.success('Product image added successfully!');
                // After successfully adding the image, update the image list
                fetchData();
            } else {
                console.error('Error adding product image:', response.data.ResponseText);
                toast.error('Failed to add product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
            // toast.error('An error occurred. Please try again.');
        }
    };
    // Get Image //
    const [images, setImages] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/product-image-list/${localStorage.getItem('product_id')}`, {

            });

            if (response.data.ResponseCode === 1) {
                // Set the retrieved images in the state
                setImages(response.data.ResponseData);
            } else {
                console.error('Error fetching images:', response.data.ResponseText);
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };
    // Delete Image //
    const handleDeleteImage = async (imageId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/image-delete/${imageId}`
            );

            if (response.data.ResponseCode === 1) {
                console.log('Image deleted successfully:', response.data.ResponseText);
                setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
                toast.success('Product image deleted successfully!');
                setGeneralTabFilled(true);
            } else {
                console.error('Error deleting image:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);

        }
    };


    // Keyword //
    const [showKeywordTable, setShowKeywordTable] = useState(false);
    const handleShowKeywordTable = () => {
        setShowKeywordTable(true);
    };
    const handleCloseKeywordTable = () => setShowKeywordTable(false);
    const handleKeywordUpload = async () => {
        try {
            const form = new FormData();
            form.append('product_id', localStorage.getItem('product_id'));
            form.append('keyword', formData.keyword);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/add-keyworld`,
                form,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.ResponseCode === 1) {
                toast.success('Product Keyword added successfully!');
                fetchProductKeywords();
            } else {
                toast.error('Failed to add product. Please try again.');
                console.error('Error adding product image:', response.data.ResponseText);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
            // console.error('API request failed:', error.message);
        }
    };
    // Get Keyword //
    useEffect(() => {
        fetchProductKeywords();
    }, []);
    const fetchProductKeywords = async () => {
        const product_id = localStorage.getItem('product_id');

        if (product_id) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/product-keyword-list/${product_id}`);
                const data = await response.json();

                if (data.ResponseCode === 1) {
                    setProductKeywords(data.ResponseData);
                } else {
                    console.error('Error fetching product keywords:', data.ResponseText);
                }
            } catch (error) {
                console.error('Error during API call:', error);
            }
        }
    };
    // Delete Keyword //
    const handleDeleteKeyword = async (productskeyword_id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/delete-keyworld/${productskeyword_id}`,
            );

            if (response.data.ResponseCode === 1) {
                setProductKeywords((prevKeywords) => prevKeywords.filter((keyword) => keyword.productskeyword_id !== productskeyword_id));
                setGeneralTabFilled(true);
                toast.success('Product Keyword deleted successfully!');
            } else {
                console.error('Error deleting variant:', response.data.ResponseText);
                toast.error('Failed to delete product. Please try again.');
            }
        } catch (error) {
            console.error('API request failed:', error.message);
        }
    };

    //user & client list

    const [selectedUser, setSelectedUser] = useState({ full_name: '', company_name: '' });
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [comany, setCompany] = useState([]);
    // const [client, setClient] = useState([]);
    useEffect(() => {
        const fetchUserCompany = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/clients-list`);
                setCompany(response.data.ResponseData);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchUserCompany();
    }, []);

    const handleAddUserClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        filterUsers(e.target.value);
    };

    const filterUsers = (query) => {
        const filtered = comany.filter((company) =>
            company.full_name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredResults(filtered);
    };

    const handleUserSelect = (selectedUser) => {
        setSelectedUser(selectedUser);
        setShowModal(false);
        setFormData({ ...formData, user_id: selectedUser.user_id, clients_id: selectedUser.company_id });
    };

    return (
        <>
            <section className="content ">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Product Details</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <Tabs id="controlled-tab-example"
                                        activeKey={activeTab}
                                        onSelect={(k) => setActiveTab(k)}
                                        className="mb-3"  >
                                        <Tab eventKey="details" title={<span> Details</span>} onSelect={() => setGeneralTabFilled(false)}>
                                            <div className="body clientbody w-lg-100 mt-4" >
                                                <Row className='d-flex align-items-center'>
                                                    <div className='col-2'>
                                                        <label className="form-label">User Name :-</label>
                                                    </div>
                                                    <div className="form-group input-group col-10">
                                                        <InputGroup>
                                                            <Form.Control
                                                                value={`${selectedUser.full_name} - ${selectedUser.company_name}`}

                                                            />
                                                            <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#e4e4e4', color: 'black' }} className='pointer' onClick={handleAddUserClick}>
                                                                Find Company
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                        {showModal && (
                                                            <Modal show={showModal} onHide={handleCloseModal}>
                                                                <Modal.Header className='pt-3'>
                                                                    <h5 className='m-0'>Select Company</h5>
                                                                </Modal.Header>
                                                                <Modal.Body className='p-3 pt-2 pointer'>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search users..."
                                                                        value={searchQuery}
                                                                        onChange={handleSearchChange}
                                                                        className="form-control p-3"
                                                                    />
                                                                    <table className="table ">
                                                                        <tbody>
                                                                            {filteredResults.map((company) => (
                                                                                <tr key={company.user_id} onClick={() => handleUserSelect(company)}>
                                                                                    <td>{company.full_name}</td>
                                                                                    <td>{company.company_name}</td>
                                                                                </tr>
                                                                            ))}
                                                                            {searchQuery !== '' && filteredResults.length === 0 && (
                                                                                <tr>
                                                                                    <td colSpan="2">No results found</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </Modal.Body>
                                                            </Modal>
                                                        )}
                                                    </div>
                                                </Row>
                                                {/* <Row className='d-flex align-items-center '>
                                                    <div className='col-2'>
                                                        <label className="form-label text-nowrap " >Client Name :-</label>
                                                    </div>
                                                    <div className="form-group col-10">
                                                        <select name="clients_id" id="clients_id" class="form-control show-tick ms select2" onChange={(e) => setFormData({ ...formData, clients_id: e.target.value })} data-placeholder="Select" required>
                                                            <option value="">--Select Client--</option>
                                                            {client.map((client) => (
                                                                <option key={client.clients_id} value={client.company_id}>{client.company_name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </Row> */}
                                                <Row className='d-flex align-items-center '>
                                                    <div className='col-2'>
                                                        <label className="form-label text-nowrap " >Product Name :-</label>
                                                    </div>
                                                    <div className="form-group col-10">
                                                        <input type="text" className="form-control " value={formData.name}
                                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })} name='name' required />
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label text-nowrap "> Category :- </label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <select name="category_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => setFormData({ ...formData, category_id: e.target.value })} data-placeholder="Select" required>
                                                                    <option value={formData.category_id ? "" : ""}>
                                                                        --select category--
                                                                    </option>
                                                                    {categoryData &&
                                                                        categoryData.map((v, index) => {
                                                                            return (
                                                                                <option value={v.id} key={index.id}>
                                                                                    {v.category_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-3'>
                                                                <label className="form-label text-nowrap ">Sub Category :-</label>
                                                            </div>
                                                            <div className="form-group col-9">
                                                                <select name="subcategory_id" id="templateId" class="form-control show-tick ms select2" onChange={(e) => setFormData({ ...formData, subcategory_id: e.target.value })} data-placeholder="Select" required>
                                                                    <option value={formData.subcategory_id ? "" : ""}>
                                                                        --select category--
                                                                    </option>
                                                                    {subcategoryData &&
                                                                        subcategoryData.map((v, index) => {
                                                                            return (
                                                                                <option value={v.id} key={index.id}>
                                                                                    {v.subcategory_name}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label  ">ProductCode:-</label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <input type="number" className="form-control" name='product_code' onChange={(e) => setFormData({ ...formData, product_code: e.target.value })} required />
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-3'>
                                                                <label className="form-label text-nowrap me-2 ">Unit :-</label>
                                                            </div>
                                                            <div className="form-group col-9">
                                                                <select
                                                                    name="unit_type"
                                                                    id="templateId"
                                                                    className="form-control show-tick ms select2"
                                                                    onChange={(e) => setFormData({ ...formData, unit_type: e.target.value })}
                                                                    value={formData.unit_type}
                                                                    data-placeholder="Select"
                                                                    required >
                                                                    <option>--select unit--</option>
                                                                    {unitTypes.map((unit, index) => (
                                                                        <option key={index} value={unit.unit_type_id}>
                                                                            {unit.unit_type}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label ">Price:-</label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <input type="number" className="form-control" value={formData.price}
                                                                    onChange={(e) => setFormData({ ...formData, price: e.target.value })} name='price' required />
                                                            </div>
                                                        </Row>
                                                    </div>

                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center'>
                                                            <div className='col-3'>
                                                                <label className="form-label text-nowrap me-2">Discount Price :-</label>
                                                            </div>
                                                            <div className="form-group col-9">
                                                                <input type="" className="form-control" value={formData.discounted_price}
                                                                    onChange={(e) => setFormData({ ...formData, discounted_price: e.target.value })} name='discounted_price' required />
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label" >Discount:-</label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <input type="number" className="form-control" name='discount' value={formData.discount} onChange={(e) => setFormData({ ...formData, discount: e.target.value })} required />
                                                            </div>
                                                        </Row>
                                                    </div>

                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center'>
                                                            <div className='col-3'>
                                                                <label className="form-label text-nowrap me-2">status :-</label>
                                                            </div>
                                                            <div className="form-group col-9">
                                                                <select
                                                                    name="is_active"
                                                                    id="templateId"
                                                                    className="form-control show-tick ms select2"
                                                                    data-placeholder="Select"
                                                                    onChange={(e) => setFormData({ ...formData, is_active: e.target.value })}
                                                                    required >
                                                                    <option value={formData.is_active ? "" : ""}>select Status</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="2">Deactive</option>
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <Row className='d-flex align-items-center '>
                                                    <div className='col-2'>
                                                        <label className="form-label "> Discription:-</label>
                                                    </div>
                                                    <div className="form-group col-10">
                                                        <textarea type="text" className="form-control" value={formData.description}
                                                            onChange={(e) => setFormData({ ...formData, description: e.target.value })} name='description' required />
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div className='col-6'>
                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-4'>
                                                                <label className="form-label">Select Image:- </label>
                                                            </div>
                                                            <div className="form-group col-8">
                                                                <input type="file" className="form-control" value={formData.product_image}
                                                                    onChange={(e) => handleImageChange(e)} name='product_image' style={{ width: '100%' }} required />
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>


                                                <div className='d-flex justify-content-end'>
                                                    <button className="  btn btn-primary   ps-4 pe-4 " onClick={() => {
                                                        handleSave();
                                                        setGeneralTabFilled(true);
                                                    }}  >Save & Next</button>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="specification" title="Specification" disabled={!generalTabFilled}
                                            onSelect={(k) => !generalTabFilled && setActiveTab('details')}>
                                            <div>
                                                <div className="row clearfix">
                                                    <div className="col-lg-12">
                                                        <div className="card body">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h5 className="card-title"> Specification</h5>
                                                                <Button className="btngray1"
                                                                    onClick={handleShowProductLinkTable}
                                                                >Add Specification</Button>
                                                                {/* modal */}
                                                                <Modal show={showProductLinkTable} onHide={handleCloseProductLinkTable}>
                                                                    <Modal.Header >
                                                                        <Modal.Title className='text-center w-100' >Specification</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <Form>
                                                                            {/* <Form.Group className="mb-3" controlId="attributeId">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <Form.Label className='me-3 '>Specification:-</Form.Label>
                                                                                    <input type="text" name='variant_name' className='form-control'
                                                                                        onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })} />
                                                                                </div>
                                                                            </Form.Group> */}
                                                                            <Row className='d-flex align-items-center '>
                                                                                <div className='col-3'>
                                                                                    <label className="form-label text-nowrap " >Specification :-</label>
                                                                                </div>
                                                                                <div className="form-group col-9">
                                                                                    <input type="text" name='variant_name' className='form-control'
                                                                                        onChange={(e) => setFormData({ ...formData, variant_name: e.target.value })} />
                                                                                </div>
                                                                            </Row>
                                                                            {/* <Form.Group
                                                                                controlId="attributeText"
                                                                            >
                                                                                <div className="d-flex  justify-content-between">
                                                                                    <Form.Label className='me-5 pe-5'>variant:-   </Form.Label>
                                                                                    <Form.Control as="input"

                                                                                        type="text" name='variant'
                                                                                        onChange={(e) => setFormData({ ...formData, variant: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                            </Form.Group> */}
                                                                            <Row className='d-flex align-items-center '>
                                                                                <div className='col-3'>
                                                                                    <label className="form-label text-nowrap " >variant :-</label>
                                                                                </div>
                                                                                <div className="form-group col-9">
                                                                                    <input type="text" name='variant' className='form-control'
                                                                                        onChange={(e) => setFormData({ ...formData, variant: e.target.value })} />
                                                                                </div>
                                                                            </Row>
                                                                        </Form>
                                                                    </Modal.Body>
                                                                    <Modal.Footer className='d-flex justify-content-center'>
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleSpecification();
                                                                                handleCloseProductLinkTable();
                                                                            }}
                                                                            className='btngray px-5'>
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </div>

                                                            <div className="m-2">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-striped table-hover  dataTable">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">NO</th>
                                                                                <th scope="col">Specification</th>
                                                                                <th scope="col">Text</th>
                                                                                <th scope="col" style={{ marginLeft: "200px" }}>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {productVariants.map((variant, index) => (
                                                                                <tr>
                                                                                    <td scope="col">{index + 1}</td>
                                                                                    <td scope="col">{variant.variant_name}</td>
                                                                                    <td scope="col">{variant.variant}</td>
                                                                                    <td scope="col" style={{ marginLeft: "200px" }}>

                                                                                        <button className="btn btn-danger ms-2 " onClick={() => handleDeleteVariant(variant.id)}
                                                                                        > <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="image" title="Image"
                                            disabled={!generalTabFilled}
                                            onSelect={(k) => !generalTabFilled && setActiveTab('details')}  >
                                            <div >
                                                <div className="row clearfix">
                                                    <div className="col-lg-12">
                                                        <div className="card body">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h5 className="card-title"> Image</h5>
                                                                <Button className="btngray1"
                                                                    onClick={handleShowImageTable}
                                                                >Add Image</Button>
                                                                <Modal
                                                                    size="md"
                                                                    show={showImageTable}
                                                                    onHide={handleCloseImageTable}
                                                                    aria-labelledby="example-modal-sizes-title-sm"
                                                                >
                                                                    <Modal.Header >
                                                                        <Modal.Title id="example-modal-sizes-title-sm">
                                                                            Upload  Image
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <img src="" alt="" style={{ maxWidth: '100%' }} />
                                                                        {/* <input type="file" /> */}
                                                                        <input type="file" name="image_url" id="image_url"
                                                                            onChange={handleImageChange}
                                                                        />
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleImageUpload();
                                                                                handleCloseImageTable();
                                                                            }}
                                                                            className="m-2 btngray px-3">
                                                                            Upload
                                                                        </Button>
                                                                    </Modal.Body>
                                                                </Modal>
                                                            </div>
                                                            <div className="m-2">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-striped table-hover  dataTable">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">NO</th>
                                                                                <th scope="col">Image</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {images.map((image, index) => (
                                                                                <tr key={index}>
                                                                                    <td scope="col">{index + 1}</td>
                                                                                    <td scope="col">
                                                                                        <img src={image.image_url} alt="" style={{ maxWidth: '100px' }} />
                                                                                    </td>
                                                                                    <td scope="col" style={{ marginLeft: "200px" }}>
                                                                                        <button className="btn btn-danger ms-2 "
                                                                                            onClick={() => handleDeleteImage(image.id)}
                                                                                        > <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="keyword" title="Keyword"
                                            disabled={!generalTabFilled}
                                            onSelect={(k) => !generalTabFilled && setActiveTab('details')} >
                                            <div >
                                                <div className="row clearfix">
                                                    <div className="col-lg-12">
                                                        <div className="card body">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h5 className="card-title">Keyword</h5>
                                                                <Button className="btngray1"
                                                                    onClick={handleShowKeywordTable}
                                                                >Add Keyword</Button>
                                                                {/* modal */}
                                                                <Modal
                                                                    size="md"
                                                                    show={showKeywordTable}
                                                                    onHide={handleCloseKeywordTable}
                                                                    aria-labelledby="example-modal-sizes-title-sm"
                                                                >
                                                                    <Modal.Header >
                                                                        <Modal.Title id="example-modal-sizes-title-sm">
                                                                            Enter keyword
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <input type="text" name='keyword' placeholder='Enter keyword ' className='form-control' onChange={(e) => setFormData({ ...formData, keyword: e.target.value })} />
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleKeywordUpload();
                                                                                handleCloseKeywordTable();
                                                                            }}
                                                                            className="mt-3 px-5 btngray ">
                                                                            Save
                                                                        </Button>
                                                                    </Modal.Body>
                                                                </Modal>
                                                            </div>
                                                            <div className="m-2">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-striped table-hover  dataTable">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">NO</th>
                                                                                <th scope="col">Keyword</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {productKeywords.map((keyword, index) => (
                                                                                <tr>
                                                                                    <td scope="col">{index + 1}</td>
                                                                                    <td scope="col">{keyword.keyword}</td>
                                                                                    <td scope="col">
                                                                                        <button className="btn btn-danger ms-2 " onClick={() => handleDeleteKeyword(keyword.productskeyword_id)}
                                                                                        > <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" d-flex justify-content-end ">
                                                <Button
                                                    type="submit"
                                                    data-form="form-product"
                                                    data-bs-toggle="tooltip"
                                                    title class="btn btn-primary"
                                                    data-bs-original-title="Save"
                                                    onClick={() => {
                                                        localStorage.removeItem('product_id');
                                                        window.location.href = "/viewproductlist";
                                                    }}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Tab>

                                    </Tabs>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <ToastContainer />
        </>
    )
}

export default Productlistadd