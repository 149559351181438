import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';

function Viewsubcategory() {

    const [subcategory, setSubcategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        fetchSubcategory();
    }, []);
    const fetchSubcategory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/subcategory-list`);
            const ResponseData = response.data.ResponseData.map((item) => {
                return {
                    ...item,
                    status: parseInt(item.status),
                };
            })
            setSubcategory(ResponseData);
        } catch (error) {
            // Handle error here
            console.error("Error fetching subcategories", error);
        }
    };



    const handleDelete = async (id) => {
        axios
            .post(`${process.env.REACT_APP_API}/deletesubcategory/${id}`)
            .then((response) => {
                toast.success("Subcategory deleted");
                setSubcategory((prevData) => prevData.filter((data) => data.id !== id));
            })
            .catch((error) => {
                console.error("Error deleting subcategory: ", error);
                toast.error("Error deleting subcategory");
            })
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentSubcategory = subcategory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Sub-Category</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/addsubcategory">
                                    <button className="btn btn-primary float-right " type="button">Add Subcategory</button>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Basic Examples */}
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="header">
                                    </div>

                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped table-hover dataTable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">NO</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">status</th>
                                                        <th scope="col" className='w-250'>Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {currentSubcategory.map((subcategory, index) => (
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{subcategory.subcategory_name}</td>
                                                            <td>
                                                                <img src={subcategory.image} alt="Banner" style={{ width: "150px", height: "110px" }} />
                                                            </td>
                                                            <td>{subcategory.category_name}</td>
                                                            <td>
                                                                {subcategory.is_active == 1 ? (
                                                                    <span className='text-success fw-bolder'>Active</span>
                                                                ) : (
                                                                    <span className='text-danger fw-bolder'>Deactive</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {/* <button className="btn btn-primary">Edit</button> */}
                                                                <Link
                                                                    to={`/editsubcategory/${subcategory.id}`}
                                                                    className="btn btn-primary m-2"
                                                                >
                                                                    <i class="bi bi-pencil-square me-1"></i>
                                                                    Edit
                                                                </Link>
                                                                <button className="btn btn-danger ms-2 " onClick={() => handleDelete(subcategory.id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example ">
                    <ul className="pagination justify-content-end">
                        {Array.from({ length: Math.ceil(subcategory.length / itemsPerPage) }).map((_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </section>
            <ToastContainer />
        </>
    )
}

export default Viewsubcategory
