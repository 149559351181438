import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';

function Viewcity() {
  const [city, setCity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchHomeCategory()
  }, []);

  const fetchHomeCategory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/city-list`)
      setCity(response.data.ResponseData)
    } catch (error) {
      console.error("Error fetching city :", error)
    }
  }

  const handleDelete = (id) => {
    axios
      .post(`${process.env.REACT_APP_API}/city-delete`, { id: id })
      .then((response) => {
        toast.success("City deleted");
        setCity((prevData) => prevData.filter((data) => data.id !== id));

        // Fetch updated data after deletion
        fetchHomeCategory();
      })
      .catch((error) => {
        console.error("Error deleting city: ", error);
        toast.error("Error deleting city");
      });
  }


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCity = city.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>City</h2>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/addcity">
                  <button className="btn btn-primary float-right " type="button">Add City</button>
                </Link>
              </div>

            </div>
          </div>
          <div className="container-fluid">
            {/* Basic Examples */}
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">

                  <div className="body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <th scope="col">NO</th>
                            <th scope="col">City Name</th>
                            <th scope="col">State Name</th>
                            <th scope="col">Status</th>
                            <th scope="col" className='w-250'>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentCity.map((city, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td>{city.cityname}</td>
                              <td>{city.statename}</td>
                              <td>
                                {city.is_active == 1 ? (
                                  <span className='text-success fw-bolder'>Active</span>
                                ) : (
                                  <span className='text-danger fw-bolder'>Deactive</span>
                                )}
                              </td>
                              <td>
                                {/* <button className="btn btn-primary">Edit</button> */}
                                <Link
                                  to={`/editcity/${city.id}`}
                                  className="btn btn-primary m-2"
                                >
                                  <i class="bi bi-pencil-square me-1"></i>
                                  Edit
                                </Link>
                                <button className="btn btn-danger ms-2 " onClick={() => handleDelete(city.id)}> <i class="bi bi-trash-fill me-1"></i>Delete</button>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav aria-label="Page navigation example ">
          <ul className="pagination justify-content-end m-3">
            {Array.from({ length: Math.ceil(city.length / itemsPerPage) }).map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
      <ToastContainer />
    </>
  )
}

export default Viewcity
