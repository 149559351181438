import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './State.module.css';

function Addstate() {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    statename: '',
    countryid: '1',
    is_active: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/country-list`);
        setCountries(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchData();
  }, []); // Add an empty dependency array to avoid infinite loop

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API}/add-state`;
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('statename', formData.statename);
      formDataToSend.append('countryid', formData.countryid);
      formDataToSend.append('is_active', formData.is_active);

      const response = await axios.post(apiUrl, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.ResponseCode) {
        toast.success('State added successfully');
        navigate('/viewstate');
      }
    } catch (error) {
      console.error('Error adding State', error);
      toast.error('Error adding State');
    }
  };
  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>State Form</h2>

                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewstate">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="body py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">State Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" name="statename" className="form-control" id="order" placeholder="Enter Name" onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        {/* <label htmlFor="email_address">Country</label>
<div className="form-group">
<select name="countryid" id="countryid" className="form-control" onChange={(e) => handleInputChange(e)} required>
  <option value="">--select country--</option>
  {countries.map((v) => (
    <option value={v.id} key={v.id}>{v.countryname}</option>
  ))}
</select>
</div> */}

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active" className="text-nowrap ">Status:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Addstate
