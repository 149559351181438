import React, { useState } from 'react'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
function Addmenu() {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        menu_name: "",
        is_active: ""
    })

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            const imageFile = e.target.files[0];
            setFormData({ ...formData, image: imageFile || null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/add-menu`;
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("menu_name", formData.menu_name);
            formDataToSend.append("is_active", formData.is_active);
            const response = await axios.post(apiUrl, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data.ResponseCode) {
                toast.success("Menu added successfully");
                navigate("/viewmenu");
            }
        } catch (error) {
            console.error("Error adding menu", error);

        }

    }

    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Menu Form</h2>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/viewmenu"><i className="zmdi zmdi-home" />Menulist</a></li>
                                </ul>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewmenu">
                                    <button className="btn btn-primary btn-icon float-right right_icon_toggle_btn" type="button"><i className="zmdi zmdi-arrow-right" /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">

                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                                <div className="card">
                                    <div className="header">
                                        <h2><strong>Add</strong> New Menu</h2>

                                    </div>
                                    <div className="body w-50">
                                        <form method='post' onSubmit={(e) => handleSubmit(e)}>
                                            <label htmlFor="email_address">Menu Name</label>
                                            <div className="form-group">
                                                <input type="text" name='menu_name' id="email_address" className="form-control " placeholder="Enter your mennu name " onChange={(e) => handleInputChange(e)} required />
                                            </div>
                                            <div class="form-group">
                                                <label htmlFor="password">Status</label>
                                                <select class="form-control show-tick ms select2" name="is_active" onChange={handleInputChange} data-placeholder="Select">
                                                    <option>-- Select Status --</option>
                                                    <option value={1}>
                                                        Active
                                                    </option>
                                                    <option value={2}>
                                                        Deactive
                                                    </option>
                                                </select>
                                            </div>
                                            <button type="submit" value="Submit" className="btn btn-raised btn-primary btn-round waves-effect">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Addmenu
