import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

function Productlist() {
    const [productdata, setProductdata] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/productlist`);
            setProductdata(response.data.ResponseData);
        } catch (error) {
            console.error("Error fetching product: ", error);
        }
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Filter products based on search query
    const filteredProducts = productdata.filter(product => {
        return (
            product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.subcategory_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const currentproduct = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    }

    const handleDetailClick = (productId) => {
        localStorage.setItem('productId', productId);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset current page when search query changes
    };

    return (
        <>
            <section className="content">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Product</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/addproduct">
                                    <button className="btn btn-primary float-right " type="button">Add Product</button>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Basic Examples */}
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">


                                    <div className="body">
                                        <div className="table-responsive">
                                            {/* Add search input */}
                                            <div className='w-100 d-flex align-items-center justify-content-end mb-4'>
                                                <input type="text"  placeholder="Search..." value={searchQuery} onChange={handleSearchChange} className="form-control w-25" />
                                            </div>
                                            <table className="table table-bordered table-striped table-hover dataTable ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No</th>
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Sub-Category</th>
                                                        <th scope="col">Unit</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {currentproduct.map((productdata, index) => (
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{productdata.name}</td>
                                                            <td><img src={productdata.product_image} alt="image" style={{ height: "70px", width: "70px" }} /></td>
                                                            <td>{productdata.company_name}</td>
                                                            <td>{productdata.subcategory_name}</td>
                                                            <td>{productdata.unit_type}</td>
                                                            <td>{formatDate(productdata.created_at)}</td>
                                                            <td>
                                                                {productdata.is_active == 1 ? (
                                                                    <span className='text-success fw-bolder'>Active</span>
                                                                ) : (
                                                                    <span className='text-danger fw-bolder'>Deactive</span>
                                                                )}
                                                            </td>
                                                            <td>

                                                                <Link to={`/updateproduct/${productdata.id}`}>
                                                                    <button className="btn btn-primary" onClick={() => handleDetailClick(productdata.id)}>
                                                                        Edit
                                                                    </button>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example ">
                    <ul className="pagination justify-content-end">
                        {Array.from({ length: Math.ceil(filteredProducts.length / itemsPerPage) }).map((_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button onClick={() => paginate(index + 1)} className="page-link rounded-pill" style={{ backgroundColor: '#fffffa', color: 'black' }}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </section>
        </>
    )
}

export default Productlist;
