import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Feedback.module.css';

function Editfeedback() {
    const { feedbackId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        clients_name: "",
        designation: "",
        image: null,
        feedback: "",
        clients_id: "",
        home_show: ""
    })

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            const imageFile = e.target.files[0];
            setFormData({ ...formData, image: imageFile || null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/clientsfeedback-update/${feedbackId}`;
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("clients_name", formData.clients_name);
            formDataToSend.append("image", formData.image);
            formDataToSend.append("designation", formData.designation);
            formDataToSend.append("feedback", formData.feedback);
            formDataToSend.append("clients_id", formData.clients_id);
            formDataToSend.append("home_show", formData.home_show);
            const response = await axios.post(apiUrl, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data.ResponseCode) {
                toast.success("Feedback updated successfully");
                navigate("/feedbackview");
            }
        } catch (error) {
            console.error("Error updating Feedback", error);

        }
    }

    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/clientsfeedbackedit/${feedbackId}`);
                setFormData(response.data.ResponseData);
            } catch (error) {
                console.error("Error fetching feedback: ", error);
            }
        };
        fetchFeedback();
    }, [])

    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Feedback Form</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/feedbackview">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                                <div className="card">
                                    <div className="body  py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Client Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='clients_name' id="clients_name" value={formData.clients_name} className="form-control " placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Client Image:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name="image" id="image" accept="image/*" className="form-control " placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)}  />
                                                            {formData.image &&
                                                                <img src={formData.image} alt="Selected Image" style={{ width: '130px', height: '110px', marginTop: '10px' }} />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address">Designation:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='designation' id="designation" value={formData.designation} className="form-control " placeholder="Enter your Banner URL " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Clients id:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='clients_id' id="clients_id" value={formData.clients_id} className="form-control " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address">FeedBack:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <textarea type="text" name="feedback" id="feedback" className='form-control' value={formData.feedback} onChange={(e) => handleInputChange(e)} />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Home Show:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div class="form-group">
                                                            <div className="form-group">
                                                                <select className="form-control show-tick ms select2" id="home_show" required name="home_show" value={formData.home_show} onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                    <option value="">-- Select Status --</option>
                                                                    <option value="1">yes</option>
                                                                    <option value="2">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>


                                                <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <ToastContainer /> */}
        </>
    )
}

export default Editfeedback