import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './City.module.css';

function Editcity() {

  const { cityId } = useParams();
  const navigate = useNavigate();
  const [subcategory, setSubcategory] = useState([]);

  const [formData, setFormData] = useState({
    cityname: "",
    state_id: "",
    is_active: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/state-list`);
        setSubcategory(response.data.ResponseData);
      } catch (error) {
        console.error("Error fetching State :", error);
      }
    }
    fetchData();

    axios.get(`${process.env.REACT_APP_API}/citydata/${cityId}`)
      .then(response => {
        const data = response.data.ResponseData;
        setFormData({
          cityname: data.cityname,
          state_id: data.state_id,
          is_active: data.is_active,
        });

      })
      .catch(error => {
        console.error("Error fetching city data: ", error);
        // toast.error("Error fetching city data");
      })
  }, [cityId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API}/city-update/${cityId}`;
    try {
      const response = await axios.post(apiUrl, formData);
      if (response.data.ResponseCode) {
        toast.success("City updated successfully");
        navigate("/viewcity");
      }
    } catch (error) {
      console.error("Error Editing city", error);
      toast.error("Error Editing city");
    }
  };

  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> City Form</h2>

                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewcity">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {/* Vertical Layout */}
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="body  py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap "> City Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" className="form-control" id="Name" placeholder='Name' name='cityname' onChange={handleInputChange} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">State Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select name="state_id" id="state_id" className="form-control" value={formData.state_id} onChange={handleInputChange} required>
                                <option value="">--select state--</option>
                                {subcategory.map((v) => (
                                  <option value={v.id} key={v.id}>{v.statename}</option>
                                ))}
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active">Status</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" value={formData.is_active} onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <button type="submit" className="btn btn-raised btn-primary  waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Editcity