import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Subcategory.module.css';

function Addsubcategory() {

    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState([]);

    const [formData, setFormData] = useState({
        subcategory_name: "",
        image: null,
        is_active: "",
        category_id: "",
    })

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/categorylist`)
            .then((response) => {
                setCategoryData(response.data.ResponseData);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                toast.error("Error fetching data");
            });
    }, setCategoryData);

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            setFormData({ ...formData, image: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = `${process.env.REACT_APP_API}/add-subcategory`;
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("subcategory_name", formData.subcategory_name);
            formDataToSend.append("category_id", formData.category_id);
            formDataToSend.append('is_active', formData.is_active);
            formDataToSend.append("image", formData.image);
            const response = await axios.post(apiUrl, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data.ResponseCode) {
                toast.success("Subcategory added successfully");
                navigate("/viewsubcategory");
            }
        } catch (error) {
            console.error("Error adding subcategory", error);

        }
    }



    return (
        <>
            <section className="content" >
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Sub-Category </h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/viewsubcategory">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        {/* Vertical Layout */}
                        <div className="row clearfix ">
                            <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >
                                <div className="card">
                                    <div className="body  py-5 mt-2">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Subcategory Name:- </label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="text" name='subcategory_name' id="subcategory_name" className="form-control " placeholder="Enter Sub-Category name " onChange={(e) => handleInputChange(e)} required />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="password" className="text-nowrap ">Image :-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <input type="file" name='image' id="image" accept="image/*" placeholder='Home-Banner Image' onChange={(e) => handleInputChange(e)} required />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="email_address" className="text-nowrap ">Category Name:-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select name="category_id" required id="templateId" class="form-control show-tick ms select2" onChange={(e) => handleInputChange(e)}>
                                                                <option value="">
                                                                    --select category--
                                                                </option>
                                                                {categoryData &&
                                                                    categoryData.map((v) => {
                                                                        return (
                                                                            <option value={v.id} key={v.id}>
                                                                                {v.category_name}
                                                                            </option>
                                                                        );
                                                                    })}

                                                            </select>
                                                        </div>

                                                        
                                                    </Col>
                                                </Row>

                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} xl={2} xxl={2}>
                                                        <label htmlFor="is_active" className="text-nowrap ">Status :-</label>
                                                    </Col>
                                                    <Col lg={9} xl={9} xxl={9}>
                                                        <div className="form-group">
                                                            <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                                                <option value="">-- Select Status --</option>
                                                                <option value="1">Active</option>
                                                                <option value="2">Deactive</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <button type="submit" className="btn btn-raised btn-primary  waves-effect">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Addsubcategory
