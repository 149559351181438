import React, { useState } from 'react'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from 'bootstrap-4-react/lib/components/layout';
import style from './Offer.module.css';

function AddOfferzone() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    bannder_linkid: "",
    imageurl: null,
    from_date: "",
    to_date: "",
    is_active: ""
  })

  const handleInputChange = (e) => {
    if (e.target.name === 'imageurl') {
      const imageFile = e.target.files[0];
      setFormData({ ...formData, imageurl: imageFile || null });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API}/add-offerzonebenners`;
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("imageurl", formData.imageurl);
      formDataToSend.append("bannder_linkid", formData.bannder_linkid);
      formDataToSend.append("from_date", formData.from_date);
      formDataToSend.append("to_date", formData.to_date);
      formDataToSend.append("is_active", formData.is_active);
      const response = await axios.post(apiUrl, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.data.ResponseCode) {
        toast.success("Offerbanner added successfully");
        navigate("/viewofferzone");
      }
    } catch (error) {
      console.error("Error adding Offerbanner", error);

    }

  }

  return (
    <>
      <section className="content" >
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>OfferZone Banner Form</h2>
                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <Link to="/viewofferzone">
                  <button className="btn btn-primary float-right" type="button">Back</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix ">
              <div className="col-lg-12 col-md-12 col-sm-12  justify-content-center align-items-center" >

                <div className="card">
                  <div className="body  py-5 mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <form method='post' className={style.fromSize} onSubmit={(e) => handleSubmit(e)}>
                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">Banner Name:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" name='name' id="name" className="form-control " placeholder="Enter your banner name " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="password" className="text-nowrap ">Banner Image:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="file" name="imageurl" id="imageurl" accept="image/*" placeholder="Enter your banner image" onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">Banner Link:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="text" name='bannder_linkid' id="url" className="form-control " placeholder="Enter your Banner URL " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">From Date:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="date" name='from_date' id="from_date" className="form-control " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="email_address" className="text-nowrap ">To Date:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <input type="date" name='to_date' id="to_date" className="form-control " onChange={(e) => handleInputChange(e)} required />
                            </div>
                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col lg={2} xl={2} xxl={2}>
                            <label htmlFor="is_active" className="text-nowrap ">Status:-</label>
                          </Col>
                          <Col lg={9} xl={9} xxl={9}>
                            <div className="form-group">
                              <select className="form-control show-tick ms select2" id="is_active" required name="is_active" onChange={(e) => handleInputChange(e)} data-placeholder="Select" >
                                <option value="">-- Select Status --</option>
                                <option value="1">Active</option>
                                <option value="2">Deactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <button type="submit" className="btn btn-raised btn-primary waves-effect">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ToastContainer /> */}
    </>
  )
}

export default AddOfferzone