import React, { useEffect, useState } from 'react';
import pglogo from '../../../Image/hd logo.jpg';
import html2pdf from 'html2pdf.js';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';


function Vendorepdf() {
    const { vendorrequestId } = useParams();
    const [detail, setDetail] = useState([])
    useEffect(() => {
        fetchdetail();
    }, [vendorrequestId]);

    const fetchdetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/clients-printout/${vendorrequestId}`);
            if (response.data.ResponseCode === 1) {
                setDetail(response.data.ResponseData);
            } else {
                console.error("API Error: ", response.data.ResponseText);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }
    const handlePrint = () => {
        const contentSection1 = document.getElementById('print-content-section1');
        const contentSection2 = document.getElementById('print-content-section2');
        const contentSection3 = document.getElementById('print-content-section3');

        const pdfOptions = {
            margin: 8,
            filename: 'Client_Registration.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },               
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        const htmlContentSection1 = contentSection1.outerHTML;
        const htmlContentSection2 = contentSection2.outerHTML;
        const htmlContentSection3 = contentSection3.outerHTML;

        const combinedContent = document.createElement('div');
        combinedContent.innerHTML = htmlContentSection1 + htmlContentSection2 + htmlContentSection3;

        html2pdf().from(combinedContent).set(pdfOptions).save();
    };

    return (
        <>
            <section className="content">
                <div className="">
                    <div className="block-header">
                    </div>
                    <div className="box-Image print-content" id="print-content-section1">
                        <div className='mt-5 d-flex justify-content-between'>
                            <div className='subimage'>
                                <img src={pglogo} alt="" height={120} width={120} />
                            </div>
                            <div >
                                <h2 className='mb-0'>Client Registration</h2>
                                <hr />
                            </div>
                            <div className='subimage'>
                                <img src={pglogo} alt="" height={120} width={120} />
                            </div>
                        </div>
                        <div className="registration-form justify-content-start mt-5 p-5">  
                            <div className='print-page'>
                                <h2>Business information</h2>
                                <form>
                                    <Row>
                                        <Col md={1.5}>
                                            <label htmlFor="firstName">First Name:</label>

                                        </Col>
                                        <Col md={10}>
                                            <input type="text" id="firstName" name="firstName" value={detail.full_name} className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={4}>
                                            <Row>
                                                <Col md={1.5}>
                                                    <label htmlFor="firstName">Gender:</label>

                                                </Col>
                                                <Col md={10}>
                                                    <input type="text" id="firstName" name="firstName" style={{ backgroundColor: 'transparent', border: 'none' }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={2}>
                                                    <label htmlFor="firstName">Birth Date:</label>

                                                </Col>
                                                <Col md={10}>
                                                    <input type="text" id="firstName" name="firstName" className="underlined" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={1.5}>
                                            <label htmlFor="firstName">Business Name:</label>

                                        </Col>
                                        <Col md={10}>
                                            <input type="text" id="firstName" name="firstName" value={detail.company_name} className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={1.5}>
                                            <label htmlFor="firstName">Business Address:</label>

                                        </Col>
                                        <Col md={10}>
                                            <input type="text" id="firstName" name="firstName" value={detail.address} className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={1.5}>
                                            <label htmlFor="firstName">Business Description:</label>

                                        </Col>
                                        <Col md={10}>
                                            <input type="text" id="firstName" name="firstName" value={detail.description} className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={6}>
                                            <Row>
                                                <Col md={1.5}>
                                                    <label htmlFor="firstName">Work phone:</label>

                                                </Col>
                                                <Col md={5}>
                                                    <input type="text" id="firstName" name="firstName" value={detail.contact1} className="underlined" />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={1.5}>
                                                    <label htmlFor="firstName">Mobile Phone:</label>

                                                </Col>
                                                <Col md={5}>
                                                    <input type="text" id="firstName" name="firstName" value={detail.contact2} className="underlined" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={1.5}>
                                            <label htmlFor="firstName">Email Address:</label>

                                        </Col>
                                        <Col md={10}>
                                            <input type="text" id="firstName" name="firstName" className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2.5}>
                                            <label htmlFor="firstName">Business Category & Subcategory:</label>

                                        </Col>
                                        <Col md={9}>
                                            <input type="text" id="firstName" name="firstName" className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={6}>
                                            <Row>
                                                <Col md={1.5}>
                                                    <label htmlFor="firstName">Id Proof:</label>

                                                </Col>
                                                <Col md={5}>
                                                    <input type="text" id="firstName" name="firstName" className="underlined" />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={1.5}>
                                                    <label htmlFor="firstName">Cash/Check/Online:</label>

                                                </Col>
                                                <Col md={5}>
                                                    <input type="text" id="firstName" name="firstName" className="underlined" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={1.5}>
                                            <label htmlFor="firstName">Remarks:</label>

                                        </Col>
                                        <Col md={10}>
                                            <input type="text" id="firstName" name="firstName" className="underlined" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} >
                                            <ol start="1" className=' px-0'>
                                                <li className='pt-2'>Number save (98259 99079)</li>
                                                <li className='pt-2'>PG Click Business Group Join request</li>
                                                <li className='pt-2'>Google page review</li>
                                                <li className='pt-2'>PG Click YouTube Channel</li>
                                                <li className='pt-2'>App Download</li>
                                            </ol>
                                        </Col>
                                        <Col md={6}>
                                            <ol start="1" className=' px-0'>
                                                <li className='pt-2'>Number save (98259 99079)</li>
                                                <li className='pt-2'>PG Click Business Group Join request</li>
                                                <li className='pt-2'>Google page review</li>
                                                <li className='pt-2'>PG Click YouTube Channel</li>
                                                <li className='pt-2'>App Download</li>
                                            </ol>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content  pt-3">
                <div className="">
                    <div className="block-header">
                    </div>
                    <div className="box-Image print-content" id="print-content-section2">
                        <div>
                            <h6>Terms and  Condition:</h6>
                        </div>
                        <div>
                            <h6>Policy:</h6>
                            <p>PG Click is works as a Business Development Agency for Local Vendors and PG Click is not only depends on mobile application Service but also works with concept of marketing includes Business meeting arrangement (with Terms and Conditions), Social media marketing, Digital marketing, free Business webinar. PG Click charged to its vendor for whole concept of marketing not for only mobile application
                                Following are the detail concept of PG Click Along with Mobile Application with registration process</p>
                        </div>
                        <div>
                            <p>Following are the detail concept of PG Click Along with Mobile Application with registration process</p>
                        </div>
                        <div>
                            <p><strong>a) Business meeting:</strong> Business meeting arranged by PG Click for Local Vendors to grow their business by sharing their information in meeting. This meeting is purely business meeting. Vendor can share only business related information in this meeting. In this meeting vendor have a presentation facility, Introduction Speech by all vendor. Business meeting arrange in the hotel and all vendor have informed by message or phone call prior to or 10-15 days before the meeting. We are charge of food and hotel from our vendors who give confirmation to arrive in business meeting and we charge this amount from our vendor before some days of meeting. Business meeting charges is different from our membership fees. Membership fee is compulsory during the registration of our vendors. If business meeting cancel due to unfortunate condition we inform to our vendor in prior. Business meeting has compulsory rules and regulations and members should obey the rules which are in favor of members</p>
                        </div>
                        <div>
                            <p><strong>b) Social Media and Digital marketing:</strong>All vendors highlighted to our social media page and group, which is highlighted as per Admin rights. Page has an only admin rights to viral post and group on social media have rights to all those vendors who has join our group.</p>
                        </div>
                        <div>
                            <p><strong>c) Mobile Application:</strong></p>
                            <ul>
                                <li>PG Click App can upload 5 images at one time; Vendor can change their images once in a two month (Max. 2 times change in a year).</li>
                                <li>Any Vendor should give clear images in jpeg format and information on our email id we are not responsible for any misinformation and unclear images given by vendor</li>
                            </ul>
                        </div>
                        <div>
                            <p><strong>General:</strong></p>
                            <ul>
                                <li>If Vendor wants to add offer/Discount in app, he/she have to inform us before 1 week on our email id.
                                </li>
                                <li>PG Click is not responsible for any refund amount, if in any case vendor create misunderstanding.</li>
                                <li>PG Click make maximum efforts to promote your business but, if in any case Vendor cannot get benefits from app at that time, PG Click not Responsible to refund amount.</li>
                                <li>PG Click takes 5 to 6 working days for update vendor’s registration.</li>
                                <li>PG Click reserves the right for change policy, discontinue any vendor for mis-information, illegal photos given, and not allowed any vendor to interfere in this platform.</li>
                                <li>There will one registration for one business; same vendor cannot register two businesses in one form.</li>
                                <li>If any unethical work done by member, PG Click has right to remove him or her.</li>
                                <li>Non Refundable Amount (In case of any dispute)</li>
                                <li>In case of any query you have to mail on our Email Id : <a href="mailto:pgclickindia@gmail.com">pgclickindia@gmail.com</a></li>
                            </ul>
                        </div>
                        <div className='d-flex justify-content-end m-0 p-0'>
                            <Col md={12}>
                                <Row>
                                    <Col md={6}>
                                        <label htmlFor="firstName">Vendore sign :</label>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="firstName">Vendore sign :</label>
                                    </Col>
                                </Row>

                            </Col>
                        </div>
                    </div>

                </div>
            </section>

            <section className="content my-5 px-5 py-5">
                <div className="">
                    <div className="block-header">
                    </div>
                    <div className="box-Image print-content" id="print-content-section3">
                        <div>
                            <Row>
                                <Col md={6}>
                                    <div className='mt-5'>
                                        <img src="https://akm-img-a-in.tosshub.com/businesstoday/images/story/202304/untitled_design_90-sixteen_nine.jpg?size=948:533" height={300} width={450} alt="" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mt-5'>
                                        <img src="https://akm-img-a-in.tosshub.com/businesstoday/images/story/202304/untitled_design_90-sixteen_nine.jpg?size=948:533" height={300} width={450} alt="" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mt-5'>
                                        <img src="https://akm-img-a-in.tosshub.com/businesstoday/images/story/202304/untitled_design_90-sixteen_nine.jpg?size=948:533" height={300} width={450} alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div className='d-flex justify-content-end'>
                        <button onClick={handlePrint}>Print</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Vendorepdf