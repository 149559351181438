import React from 'react'

function SignUp() {
    return (
        <>

            <div className="authentication">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <form className="card auth_form">
                                <div className="header">
                                    <img className="logo" src="assets/images/logo.svg" alt />
                                    <h5>Sign Up</h5>
                                    <span>Register a new membership</span>
                                </div>
                                <div className="body">
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Username" />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="zmdi zmdi-account-circle" /></span>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Enter Email" />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="zmdi zmdi-email" /></span>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Password" />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="zmdi zmdi-lock" /></span>
                                        </div>
                                    </div>
                                    <div className="checkbox">
                                        <input id="remember_me" type="checkbox" />
                                        <label htmlFor="remember_me">I read and agree to the <a href="javascript:void(0);">terms of usage</a></label>
                                    </div>
                                    <a href="index.html" className="btn btn-primary btn-block waves-effect waves-light">SIGN UP</a>
                                    <div className="signin_with mt-3">
                                        <a className="link" href="sign-in.html">You already have a membership?</a>
                                    </div>
                                </div>
                            </form>
                            <div className="copyright text-center">
                                ©
                                ,
                                <span><a href="templatespoint.net">Templates Point</a></span>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className="card">
                                <img src="assets/images/signup.svg" alt="Sign Up" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SignUp