import React, { useEffect, useState } from 'react'
import { Col, Row, Modal, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


function Addvendorrequest() {
    const navigate = useNavigate();
    const { vendorrequestId } = useParams();
    const [key, setKey] = useState('details');
    const [generalTabFilled, setGeneralTabFilled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [detail, setDetail] = useState([]);
    const [productdetails, setProductdetails] = useState([]);
    const [activeTab, setActiveTab] = useState('details');
    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    const handleSaveAndNext = () => {
        let nextTab;

        switch (activeTab) {
            case 'details':
                nextTab = 'image';
                break;
            case 'image':
                nextTab = 'specification';
                break;
            default:
                nextTab = 'details';
        }
        setActiveTab(nextTab);
    };

    const handleBack = () => {
        let previousTab;

        switch (activeTab) {
            case 'image':
                previousTab = 'details';
                break;
            case 'specification':
                previousTab = 'image';
                break;
            case 'keyword':
                previousTab = 'specification';
                break;
            default:
                previousTab = 'details';
        }

        setActiveTab(previousTab);
    };


    const openModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage('');
        setShowModal(false);
    };

    useEffect(() => {
        fetchdetail();
    }, [vendorrequestId]);

    const fetchdetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/clients-request-edit/${vendorrequestId}`);
            if (response.data.ResponseCode === 1) {
                setDetail(response.data.ResponseData);
            } else {
                console.error("API Error: ", response.data.ResponseText);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    const handlesubmit = async (e) => {

        const apiUrl = `${process.env.REACT_APP_API}/clients-update/${vendorrequestId}`;
        try {
            const response = await axios.post(apiUrl, detail);
            if (response.data.ResponseCode) {
                // toast.success("Vendor request updated successfully");
                navigate('/vendorelist');
            }
        } catch (error) {
            console.error("Error Editing vendor request", error);
            toast.error("Error Editing vendor request");
        }
    }


    return (
        <>
            <section className="content ">
                <div className="body_scroll">
                    <div className="block-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Vendore Request</h2>
                                <button className="btn btn-primary btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></button>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <Link to="/vendorrequest">
                                    <button className="btn btn-primary float-right" type="button">Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div >
                                        <Tabs id="controlled-tab-example" activeKey={activeTab} onSelect={(k) => setKey(k)} className="mb-3" >
                                            <Tab eventKey="details" title="Details" onSelect={() => setGeneralTabFilled(false)}>
                                                <div className="body clientbody w-lg-100 mt-4" >
                                                    <div className='productmargin d-flex flex-column m-3 pt-3'>

                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-2'>
                                                                <label className="form-label text-nowrap">Business Name :-</label>
                                                            </div>
                                                            <div className="form-group col-10">
                                                                <input type="text" className="form-control " value={detail.company_name} name='name' required />
                                                            </div>
                                                        </Row>

                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-2'>
                                                                <label className="form-label text-nowrap">Name :-</label>
                                                            </div>
                                                            <div className="form-group col-10">
                                                                <input type="text" className="form-control " value={detail.full_name} name='name' required />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-4'>
                                                                        <label className="form-label text-nowrap">Mobile Number:- </label>
                                                                    </div>
                                                                    <div className="form-group col-8">
                                                                        <input type="number" className="form-control " value={detail.contact1} name='price' required />
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap me-2">Email :-</label>
                                                                    </div>
                                                                    <div className="form-group col-9">
                                                                        <input type="email" className="form-control" value={detail.email} name='discounted_price' required />
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Row>

                                                        <Row className='d-flex align-items-center '>
                                                            <div className='col-2'>
                                                                <label className="form-label text-nowrap">Address :-</label>
                                                            </div>
                                                            <div className="form-group col-10">
                                                                <textarea type="text" className="form-control" value={detail.address} name='name' required />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-4'>
                                                                        <label className="form-label">State:-</label>
                                                                    </div>
                                                                    <div className="form-group col-8">
                                                                        <select name="" id="" class="form-control show-tick ms-4 select2">
                                                                            <option value={detail.statename}>{detail.statename}</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap ">City :-</label>
                                                                    </div>
                                                                    <div className="form-group col-9">
                                                                        <select name="" id="" class="form-control show-tick ms select2">
                                                                            <option value={detail.cityname}>{detail.cityname}</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-4'>
                                                                        <label className="form-label">Company Type:-</label>
                                                                    </div>
                                                                    <div className="form-group col-8">
                                                                        <select name="" id="" class="form-control show-tick ms select2">
                                                                            <option >{detail.company_type == 1 ? "Private Limited" : "Public Limited"}</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-3'>
                                                                        <label className="form-label text-nowrap ">Business Type :-</label>
                                                                    </div>
                                                                    <div className="form-group col-9">
                                                                        <select name="" id="" class="form-control show-tick ms select2">
                                                                            <option >{detail.business_type == 1 ? "Private Limited" : "Public Limited"}</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Row>


                                                        <Row>
                                                            <div className='col-6'>
                                                                <Row className='d-flex align-items-center '>
                                                                    <div className='col-4'>
                                                                        <label className="form-label text-nowrap ">Sub Category:-</label>
                                                                    </div>
                                                                    <div className="form-group col-8">
                                                                        <select name="subcategory_name" id="" class="form-control show-tick ms select2" >
                                                                            <option value={detail.subcategory_name}>{detail.subcategory_name}</option>
                                                                        </select>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Row>


                                                        <Row className='d-flex line  align-items-center Justify-content-between mb-3'>
                                                            <Col>
                                                                <input type="hidden" className="form-control" value={detail.country_id} name='discounted_price' />
                                                                <input type="hidden" className="form-control" value={detail.subcate_id} name='discounted_price' />

                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    <div className='d-flex  justify-content-end mt-3 me-3'>
                                                        <button className="  btn btn-primary   ps-4 pe-4 " onClick={handleSaveAndNext} >Save & Next</button>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="image" title="Image">
                                                <div className='body  '>
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className='d-flex'>
                                                                        <Col className='col-8'>
                                                                            <div className='col-9 pt-3'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label">Aadhar No:-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <input type="text" className="form-control " value={detail.aadharcard_number} name='name' required />
                                                                                    </div>
                                                                                </Row>
                                                                            </div>
                                                                            <div className='col-9'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Status :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <select name="" id="" class="form-control show-tick ms select2" value={detail.aadharcard_status} onChange={(e) => setDetail({ ...detail, aadharcard_status: e.target.value })}>
                                                                                            <option value="1">Approved </option>
                                                                                            <option value="2">disapproved </option>
                                                                                            <option value="3">Witting for checking</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </Row>
                                                                            </div>
                                                                            <div className='col-9 mb-3 mt-5 pt-5'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">GST number:-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <input type="text" className="form-control" value={detail.gst_number} name='name' required />
                                                                                    </div>
                                                                                </Row>
                                                                            </div>
                                                                            <div className='col-9'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Status :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <select name="" id="" class="form-control show-tick ms select2" value={detail.gst_status} onChange={(e) => setDetail({ ...detail, gst_status: e.target.value })}>
                                                                                            <option value="1">Approved </option>
                                                                                            <option value="2">disapproved </option>
                                                                                            <option value="3">Witting for checking</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </Row>
                                                                            </div>

                                                                            <div className='col-9 mb-3 mt-5 pt-5'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Pan number:-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <input type="text" className="form-control" value={detail.pancard_number} name='name' required />
                                                                                    </div>
                                                                                </Row>
                                                                            </div>
                                                                            <div className='col-9'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Status :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <select name="" id="" class="form-control show-tick ms select2" value={detail.gst_status} onChange={(e) => setDetail({ ...detail, gst_status: e.target.value })}>
                                                                                            <option value="1">Approved </option>
                                                                                            <option value="2">disapproved </option>
                                                                                            <option value="3">Witting for checking</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>

                                                                        <Col className='col-4'  >
                                                                            <div>
                                                                                <img
                                                                                    src={detail.aadharcardimage}
                                                                                    alt=""
                                                                                    height={150}
                                                                                    width={250}
                                                                                />
                                                                            </div>
                                                                            <a className='ps-3' onClick={() => openModal(detail.aadharcardimage)}>
                                                                                View
                                                                            </a>
                                                                            <div className='mt-4'>
                                                                                <img
                                                                                    src={detail.gst_image}
                                                                                    alt=""
                                                                                    height={150}
                                                                                    width={250}
                                                                                />
                                                                            </div>
                                                                            <a className='ps-3' onClick={() => openModal(detail.gst_image)}> View</a>
                                                                            <div className='mt-4'>
                                                                                <img
                                                                                    src={detail.pancard_image}
                                                                                    alt=""
                                                                                    height={150}
                                                                                    width={250}
                                                                                />
                                                                            </div>
                                                                            <a className='ps-3' onClick={() => openModal(detail.pancard_image)}>
                                                                                View
                                                                            </a>

                                                                            <Modal show={showModal} onHide={closeModal}>
                                                                                <Modal.Body>
                                                                                    <img src={selectedImage} alt="Large View" />
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="secondary" onClick={closeModal}>
                                                                                        Close
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        </Col>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex  justify-content-end mt-5'>
                                                                    <button className="  btn btn-primary   ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                    <button className="  btn btn-primary" onClick={() => { handleSaveAndNext() }} >Save & Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="specification" title="Specification" >
                                                <div className='body'>
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <div className="m-2">
                                                                    <div className='d-flex'>
                                                                        <Col md="8">
                                                                            <div className='col-9 pt-3'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Business logo:-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <input type="text" className="form-control " placeholder='company logo' disabled name='name' required />
                                                                                    </div>
                                                                                </Row>
                                                                            </div>

                                                                            <div className='col-9 mb-5'>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Status :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <select name="" id="" class="form-control show-tick ms select2" value={detail.aadharcard_status}>
                                                                                            <option value="1">Approved </option>
                                                                                            <option value="2">disapproved </option>
                                                                                            <option value="3">Witting for checking</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </Row>
                                                                            </div>

                                                                            <div className='col-9 pt-4 mt-3 '>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Photo:-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <input type="text" className="form-control" placeholder='photo' disabled name='name' required />
                                                                                    </div>
                                                                                </Row>
                                                                            </div>

                                                                            <div className='col-9 '>
                                                                                <Row className='d-flex align-items-center '>
                                                                                    <div className='col-3'>
                                                                                        <label className="form-label text-nowrap">Status :-</label>
                                                                                    </div>
                                                                                    <div className="form-group col-8">
                                                                                        <select name="" id="" class="form-control show-tick ms select2" value={detail.aadharcard_status}>
                                                                                            <option value="1">Approved </option>
                                                                                            <option value="2">disapproved </option>
                                                                                            <option value="3">Witting for checking</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>

                                                                        <Col md="4">
                                                                            <div className='mt-4'>
                                                                                <img
                                                                                    src={detail.logo}
                                                                                    alt=""
                                                                                    height={150}
                                                                                    width={250}
                                                                                />
                                                                            </div>
                                                                            <a className='ps-3' onClick={() => openModal(detail.logo)}> View</a>
                                                                            <div className='mt-4'>
                                                                                <img
                                                                                    src={detail.selfie_image}
                                                                                    alt=""
                                                                                    height={150}
                                                                                    width={250}
                                                                                />
                                                                            </div>
                                                                            <a className='ps-3' onClick={() => openModal(detail.selfie_image)}>
                                                                                View
                                                                            </a>

                                                                            <Modal show={showModal} onHide={closeModal}>
                                                                                <Modal.Body>
                                                                                    <img src={selectedImage} alt="Large View" />
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="secondary" onClick={closeModal}>
                                                                                        Close
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        </Col>

                                                                    </div>
                                                                </div>
                                                                <Col>
                                                                    <Row className='d-flex align-items-center col-6 '>
                                                                        <div className='col-3'>
                                                                            <label className="form-label text-nowrap ">Select status :-</label>
                                                                        </div>
                                                                        <div className="form-group col-6">
                                                                            <select name="" id="" class="form-control show-tick ms select2" value={detail.status} onChange={(e) => setDetail({ ...detail, status: e.target.value })}>
                                                                                <option value="1">Waiting for checking</option>
                                                                                <option value="2">Inquery</option>
                                                                                <option value="3">Approved</option>
                                                                            </select>
                                                                        </div>
                                                                    </Row>

                                                                    <Row className='d-flex align-items-center col-6 '>
                                                                        <div className='col-3'>
                                                                            <label className="form-label text-nowrap ">Comment :-</label>
                                                                        </div>
                                                                        <div className="form-group col-6">
                                                                            <textarea name=" comment" className='form-control' value={detail.comment} onChange={(e) => setDetail({ ...detail, comment: e.target.value })}></textarea>
                                                                        </div>
                                                                    </Row>
                                                                </Col>

                                                                <div className='d-flex  justify-content-center mt-4 me-3'>
                                                                    <button className="btn btn-primary  ps-4 pe-4 ms-2" onClick={handleBack} >Back</button>
                                                                    <button className="btn btn-primary ps-4 pe-4 ms-2 " onClick={() => { handlesubmit() }} > Next</button>
                                                                </div>
                                                                <div className='d-flex justify-content-end m-3 pt-4'>
                                                                    <Link to={`/vendorpdf/${detail.company_id}`}>
                                                                        <div className='d-flex me-3'>
                                                                            <button className="btn btn-primary px-5"  > Print</button>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Addvendorrequest